/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import config from '@/config/config'

const logLevel = config.logLevel

const logLevelList: LogLevel[] = ['DEBUG', 'INFO', 'WARN', 'ERROR', 'NONE']

export default class LoggerHelper {
  public static debug(message?: any, ...optionalParams: any[]) {
    if (logLevelList.indexOf('DEBUG') >= logLevelList.indexOf(logLevel)) {
      console.debug(message, ...optionalParams)
    }
  }
  public static info(message?: any, ...optionalParams: any[]) {
    if (logLevelList.indexOf('INFO') >= logLevelList.indexOf(logLevel)) {
      console.info(message, ...optionalParams)
    }
  }
  public static warn(message?: any, ...optionalParams: any[]) {
    if (logLevelList.indexOf('WARN') >= logLevelList.indexOf(logLevel)) {
      console.warn(message, ...optionalParams)
    }
  }
  public static error(message?: any, ...optionalParams: any[]) {
    if (logLevelList.indexOf('ERROR') >= logLevelList.indexOf(logLevel)) {
      console.error(message, ...optionalParams)
    }
  }
}

export type LogLevel = 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'NONE'
