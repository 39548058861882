import { defineStore } from 'pinia'

export type LoadingStore = {
  loading: boolean
  type: 'inner' | 'over'
  name?: string
}
export const loadingReset: LoadingStore = {
  loading: false,
  type: 'over',
}

export const useLoadingStore = defineStore('loading', {
  state: (): LoadingStore => ({
    loading: false,
    type: 'over',
    name: '',
  }),
  getters: {
    getLoading: (state) => state.loading,
    getLoadingState: (state) => state,
  },
  actions: {
    async setLoading(value: boolean, name?: string) {
      // NOTE: デバッグ用
      // if (process.env.NODE_ENV === 'development')
      //   console.log('ローディング', value ? '開始' : '終了')

      this.type = name ? 'inner' : 'over'
      this.name = name
      this.loading = value
    },
  },
})
