const Item = (key: string, value: number) => ({ key, value })

export type ItemType = ReturnType<typeof Item>
export type ItemsType = Readonly<{
  [key: string]: Readonly<ItemType>
}>

// 公開範囲設定
export const DISCLOSURE_RANGE: ItemsType = {
  none: Item('公開なし', 0),
  // 全国
  national: Item('全国', 1),
  // 自治体
  local: Item('自治体', 2),
  // 学校
  school: Item('学校', 3),
}

// キャンセルステータス
export const CANCEL_STATUS: ItemsType = {
  notCanceled: Item('未キャンセル', 0),
  displayCoverOnly: Item('表紙のみ表示', 1),
  canceled: Item('表紙表示無し', 2),
}

// グレードコード
export const GRADE_CODE: ItemsType = {
  elementaryY1Y2: Item('1・2年生向け', 1),
  elementaryY3Y4: Item('3・4年生向け', 2),
  elementaryY5Y6: Item('5・6年生向け', 3),
  juniorHighAbove: Item('中・高校生以上', 4),
  elementary: Item('小学生向け', 5),
  elementaryLowerMiddle: Item('低・中学年向け', 6),
  elementaryMiddleUpper: Item('中・高学年向け', 7),
  elementaryUpper: Item('高学年以上', 8),
}

// ジャンル
const FirstLayer = (
  name: string,
  code: string,
  second: Readonly<SecondaryLayerType>[]
) => ({ name, code, second })

const SecondaryLayer = (name: string, code: string) => ({ name, code })

export type FirstLayerType = ReturnType<typeof FirstLayer>
export type SecondaryLayerType = ReturnType<typeof SecondaryLayer>
export type CategoriesType = Readonly<{
  [key: string]: Readonly<FirstLayerType>
}>

export const CATEGORIES: CategoriesType = {
  pictureBook: FirstLayer('絵本', '001', [
    SecondaryLayer('日本の絵本', '01'),
    SecondaryLayer('世界の絵本', '02'),
    SecondaryLayer('写真絵本', '03'),
    SecondaryLayer('昔話', '04'),
    SecondaryLayer('その他', '05'),
  ]),
  readingMatter: FirstLayer('読み物', '002', [
    SecondaryLayer('日本の読み物', '01'),
    SecondaryLayer('世界の読み物', '02'),
    SecondaryLayer('YA', '03'),
    SecondaryLayer('ノンフィクション', '04'),
    SecondaryLayer('その他', '05'),
  ]),
  learning: FirstLayer('学習', '003', [
    SecondaryLayer('伝記', '01'),
    SecondaryLayer('仕事', '02'),
    SecondaryLayer('古典', '03'),
    SecondaryLayer('戦争・平和', '04'),
    SecondaryLayer('その他', '05'),
  ]),
  learningComic: FirstLayer('学習コミック', '004', [
    SecondaryLayer('歴史', '01'),
    SecondaryLayer('伝記', '02'),
    SecondaryLayer('その他', '03'),
  ]),
  sportsPlay: FirstLayer('スポーツ・あそび', '005', [
    SecondaryLayer('スポーツ', '01'),
    SecondaryLayer('なぞなぞ・あそび', '02'),
    SecondaryLayer('その他', '03'),
  ]),
  booksInTextBooks: FirstLayer('教科に関連する本', '006', [
    SecondaryLayer('国語', '01'),
    SecondaryLayer('総合', '02'),
    SecondaryLayer('その他', '03'),
  ]),
}

// 学年
export type Grade = {
  code: number | null
  name: string
}

export const GRADE_LIST: Grade[] = [
  { code: null, name: 'すべて' },
  { code: 1, name: '1・2ねんせい' },
  { code: 2, name: '3・4年生' },
  { code: 3, name: '5・6年生' },
  { code: 4, name: '中・高校生' },
]

// 音声読み上げ
export type ContentsStyle = {
  code: number | null
  name: string
  rubyName?: string
  icon?: string
}

export const CONTENTS_STYLE_LIST: ContentsStyle[] = [
  {
    code: null,
    name: 'すべて',
  },
  {
    code: 0,
    name: '対応している本のみ',
    rubyName:
      '<ruby><rb>対応</rb><rp>(</rp><rt>たいおう</rt><rp>)</rp></ruby>している本のみ',
    icon: require('@/assets/image/icon/icon_speaker.svg'),
  },
]

// ==================
// 本だな
// ==================

// 読書ステータス
export type ReadingStatus = {
  code: number
  name: string
  rubyName: string
}
export const READING_STATUS: ReadingStatus[] = [
  { code: 0, name: 'すべて', rubyName: 'すべて' },
  {
    code: 1,
    name: 'まだ読んでいない',
    rubyName:
      'まだ<ruby><rb>読</rb><rp>(</rp><rt>よ</rt><rp>)</rp></ruby>んでいない',
  },
  {
    code: 2,
    name: '読みかけ',
    rubyName: '<ruby><rb>読</rb><rp>(</rp><rt>よ</rt><rp>)</rp></ruby>みかけ',
  },
  {
    code: 3,
    name: '読んだ',
    rubyName: '<ruby><rb>読</rb><rp>(</rp><rt>よ</rt><rp>)</rp></ruby>んだ',
  },
]

// 感想ステータス
export type ReviewStatus = {
  code: number
  name: string
  rubyName: string
}
export const REVIEW_STATUS: ReviewStatus[] = [
  {
    code: 0,
    name: 'すべて',
    rubyName: 'すべて',
  },
  {
    code: 1,
    name: 'まだ書いていない',
    rubyName:
      'まだ<ruby><rb>書</rb><rp>(</rp><rt>か</rt><rp>)</rp></ruby>いていない',
  },
  {
    code: 2,
    name: '書いた',
    rubyName: '<ruby><rb>書</rb><rp>(</rp><rt>か</rt><rp>)</rp></ruby>いた',
  },
]
