import { EnvConfigType, AmplifyConfigType } from './ConfigType'
// !!!注意：こちらのファイルを変更すると、本番環境に影響があります。
// prd環境の設定をこちらに追加してください。
// EnvConfigTypeの変更も必要です。

const amplifyConfig: AmplifyConfigType = {
  aws_project_region: 'ap-northeast-1',
  aws_cloud_logic_custom: [
    {
      name: 'PortalAPI',
      endpoint:
        'https://ll2mbpge9k.execute-api.ap-northeast-1.amazonaws.com/prd',
      region: 'ap-northeast-1',
    },
    {
      name: 'BaseAPI',
      endpoint:
        'https://k86tt0x7ig.execute-api.ap-northeast-1.amazonaws.com/prd',
      region: 'ap-northeast-1',
    },
  ],
}

const config: EnvConfigType = {
  env: 'prd',
  amplifyConfig: amplifyConfig,
  logLevel: 'DEBUG', // TODO: エラーログをサーバに送る仕組みができたら、NONEに変更する
  analyticsTrackingId: 'G-8TFY1G30ST',
  viewerEndpoint: '',
  siteDomain: 'mottosokka.jp',
  adminUrl: 'https://admin.mottosokka.jp',
  yomokkaUrl: 'https://yomokka.mottosokka.jp',
  sagasokkaUrl: 'https://sagasokka.mottosokka.jp',
  mottosokkaUrl: 'https://mottosokka.jp',
}

export default config
