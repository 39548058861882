import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalizedLoaded,
  RouterScrollBehavior,
} from 'vue-router'

import BasePage from '@/views/_Layouts/BasePage/BasePage.vue'
import Top from '../views/Top.vue'
import { useHandleError } from '@/composables/useHandleError'
import AuthHelper from '@/helpers/AuthHelper'
import StoreHelper from '@/helpers/StoreHelper'
import config from '@/config/config'

enum ROUTE_TYPE {
  PUBLIC, // いつでも見れる画面
  NEED_AUTH, // (デフォルト) ログイン済みのユーザーしか見れない画面
  NEED_UNAUTH, // 未ログインのユーザーしか見れない画面
}

enum SCHEMA_TYPE {
  STRING,
  NUMBER,
  BOOLEAN,
}

interface ParamsSchema {
  [key: string]: { type: SCHEMA_TYPE; length?: number }
}

type PropType = string | number | boolean | null | undefined

function castParamsToProps(
  route: RouteLocationNormalizedLoaded,
  schema: ParamsSchema
) {
  const props: { [x: string]: PropType | PropType[] } = {
    ...route.params,
    ...route.query,
  }
  const keys = Object.keys(props)
  keys.forEach((key) => {
    if (schema[key]) {
      if (props[key] === '') {
        props[key] = undefined
      } else if (schema[key].type === SCHEMA_TYPE.NUMBER) {
        props[key] = Number(props[key])
      } else if (schema[key].type === SCHEMA_TYPE.BOOLEAN) {
        props[key] = props[key] === 'true' ? true : false
      }
    }
  })

  if (Object.values(props).includes(NaN)) {
    // routerを使った場合、Promise(Promise<NavigationFailure | void | undefined>)になるので以下を使用
    window.location.href = config.yomokkaUrl + '/404'
  } else {
    return props
  }
}
function checkLength(
  route: RouteLocationNormalizedLoaded,
  schema: ParamsSchema
) {
  const props: { [x: string]: PropType | PropType[] } = {
    ...route.params,
    ...route.query,
  }
  const keys = Object.keys(props)
  keys.forEach((key) => {
    if (
      schema[key]?.length &&
      props[key]?.toString().length !== schema[key].length
    ) {
      window.location.href = config.yomokkaUrl + '/404'
    }
  })
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'base',
    component: BasePage,
    children: [
      {
        path: '',
        name: 'Top',
        component: Top,
        meta: {
          routeType: ROUTE_TYPE.NEED_AUTH,
          isWhitelistAccessible: false,
        },
      },
      {
        path: '/bookshelf',
        name: 'Bookshelf',
        component: () => import('../views/Bookshelf/Bookshelf.vue'),
        meta: {
          routeType: ROUTE_TYPE.NEED_AUTH,
          isWhitelistAccessible: true,
        },
      },
      {
        path: '/book_search',
        name: 'BookSearch',
        component: () => import('../views/BookSearch/BookSearch.vue'),
        meta: {
          routeType: ROUTE_TYPE.NEED_AUTH,
          isWhitelistAccessible: false,
        },
      },
      {
        path: '/book_detail/:bookId',
        name: 'BookDetail',
        component: () => import('../views/BookDetail/BookDetail.vue'),
        props(route) {
          const schema = {
            bookId: { type: SCHEMA_TYPE.STRING },
          }
          return castParamsToProps(route, schema)
        },
        meta: {
          routeType: ROUTE_TYPE.NEED_AUTH,
          isWhitelistAccessible: true,
        },
      },
      {
        path: '/book_review/:bookId/:readingCompleteHistoryId/:bookReviewId?',
        name: 'BookReview',
        component: () => import('../views/BookReview/BookReview.vue'),
        props(route) {
          const schema = {
            bookId: { type: SCHEMA_TYPE.STRING, length: 25 },
            readingCompleteHistoryId: { type: SCHEMA_TYPE.NUMBER },
            bookReviewId: { type: SCHEMA_TYPE.NUMBER },
          }

          checkLength(route, schema)
          return castParamsToProps(route, schema)
        },
        meta: {
          routeType: ROUTE_TYPE.NEED_AUTH,
          isWhitelistAccessible: true,
        },
      },
      {
        path: '/feature/:id',
        name: 'Feature',
        component: () => import('../views/Feature/Feature.vue'),
        props(route) {
          const schema = {
            id: { type: SCHEMA_TYPE.NUMBER },
          }
          return castParamsToProps(route, schema)
        },
        meta: {
          routeType: ROUTE_TYPE.NEED_AUTH,
          isWhitelistAccessible: false,
        },
      },
      {
        path: '/feature_list',
        name: 'FeatureList',
        component: () => import('../views/FeatureList/FeatureList.vue'),
        meta: {
          routeType: ROUTE_TYPE.NEED_AUTH,
          isWhitelistAccessible: false,
        },
      },
      {
        path: '/book_ranking',
        name: 'BookRanking',
        component: () => import('../views/BookRanking/BookRanking.vue'),
        meta: {
          routeType: ROUTE_TYPE.NEED_AUTH,
          isWhitelistAccessible: false,
        },
      },
      {
        path: '/help',
        name: 'Help',
        component: () => import('../views/Help/Help.vue'),
        meta: {
          routeType: ROUTE_TYPE.NEED_AUTH,
          isWhitelistAccessible: true,
        },
      },
      {
        path: '/500',
        name: 'InternalServerError',
        component: () => import('../views/Error/500.vue'),
        meta: {
          routeType: ROUTE_TYPE.PUBLIC,
          isWhitelistAccessible: true,
        },
      },
      {
        path: '/503',
        name: 'ServiceUnavailable',
        component: () => import('../views/Error/503.vue'),
        meta: {
          routeType: ROUTE_TYPE.PUBLIC,
          isWhitelistAccessible: true,
        },
      },
      {
        path: '/session_error/:errorType',
        name: 'SessionError',
        component: () => import('../views/Error/SessionError.vue'),
        meta: {
          routeType: ROUTE_TYPE.PUBLIC,
          isWhitelistAccessible: true,
        },
      },
      {
        path: '/404',
        name: 'NotFound',
        component: () => import('../views/Error/404.vue'),
        meta: {
          routeType: ROUTE_TYPE.PUBLIC,
          isWhitelistAccessible: true,
        },
      },
      {
        path: '/external_link/:href',
        name: 'ExternalLink',
        component: () => import('../views/ExternalLink/ExternalLink.vue'),
        props(route) {
          const schema = {
            href: { type: SCHEMA_TYPE.STRING },
          }
          return castParamsToProps(route, schema)
        },
        meta: {
          routeType: ROUTE_TYPE.NEED_AUTH,
          isWhitelistAccessible: true,
        },
      },
      {
        path: '/logout',
        name: 'Logout',
        component: () => import('../views/Logout/Logout.vue'),
        meta: {
          routeType: ROUTE_TYPE.NEED_AUTH,
          isWhitelistAccessible: true,
        },
      },
      {
        path: '/:pathMatch(.*)*',
        redirect: '/404',
      },
    ],
  },
]

export const errorPageNames = [
  'InternalServerError',
  'ServiceUnavailable',
  'NotFound',
]

// 基本は表示時に上に戻す
const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
  if (to.hash) {
    return {
      el: to.hash,
      behavior: 'smooth',
      offset: { top: 45 },
    }
  }
  if (savedPosition) {
    document.body.scrollTo({ top: savedPosition.top })
    return savedPosition
  } else {
    document.body.scrollTo({ top: 0 })
    return { top: 0 }
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
})

function isIdleForTooLong() {
  if (StoreHelper.getIdleTime() > config.allowedIdleMinutes) {
    return true
  }
  return false
}
const { handleErrorsOther } = useHandleError()

// eslint-disable-next-line max-lines-per-function
router.beforeEach(async (to, from, next) => {
  const routeType =
    to.meta && to.meta.routeType !== undefined
      ? to.meta.routeType
      : ROUTE_TYPE.NEED_AUTH
  const isWhitelistAccessible =
    to.meta && to.meta.isWhitelistAccessible !== undefined
      ? to.meta.isWhitelistAccessible
      : false

  if (routeType === ROUTE_TYPE.PUBLIC) {
    // PUBLIC画面の場合、直接移動します。
    next()
    return
  }
  let user
  try {
    // ログイン中の場合、ジャンル、出版社情報、ユーザー情報をストアに設定する
    StoreHelper.getAndSetFirstLayerCode()
    StoreHelper.getAndSetPublisherList()
    user = await StoreHelper.getAndSetUser()
  } catch (err) {
    const location = handleErrorsOther(err as Error)
    next(location)
    return
  }
  if (isIdleForTooLong()) {
    AuthHelper.logout()
    StoreHelper.updateLastUseTime()
    goToLogin()
    return
  }
  //ログインしている状態で、制約オプションがホワイトリストの場合
  if (user && AuthHelper.isWhitelist()) {
    if (to.name === 'Top') {
      // 遷移先がYomokka!トップの場合、自分の本だな画面に遷移する
      next({ name: 'Bookshelf' })
      return
    } else if (!isWhitelistAccessible) {
      // ホワイトリストアクセス可能画面でない場合、404画面に遷移する
      next({ name: 'NotFound' })
      return
    }
  }

  if (user && routeType === ROUTE_TYPE.NEED_UNAUTH) {
    // ログインしている状態でログイン画面に入ろうとしたら、Yomokka!トップにリダイレクト
    next({ name: 'Top' })
  } else if (
    !user &&
    routeType === ROUTE_TYPE.NEED_AUTH &&
    to.name !== 'Login'
  ) {
    // ログインしていない場合、ログイン画面にリダイレクト
    goToLogin()
  } else {
    // 申込管理者かつ、パスワード変更フラグがfalse、かつdevelop環境では無かった場合
    if (
      user?.contractApplicationManagerFlg === 1 &&
      user.passwordConfigured === 0 &&
      process.env.NODE_ENV !== 'development'
    ) {
      // mottosokkaに遷移させる（遷移先でPW強制変更）
      window.location.href = config.mottosokkaUrl
      return
    }
    next()
  }
})

function goToLogin() {
  window.location.href = config.mottosokkaUrl
}

export default router
