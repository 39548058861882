import DbEnum from '@/enums/DbEnum'
import ColorThemeEnum from '@/enums/ColorThemeEnum'

export default class EnumHelper {
  private static instance: EnumHelper

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance(): EnumHelper {
    if (!EnumHelper.instance) {
      EnumHelper.instance = new EnumHelper()
    }

    return EnumHelper.instance
  }

  private serviceCodes = Object.values(DbEnum.serviceCode)
  private usagePatterns = Object.values(DbEnum.usagePattern)
  private usageUnits = Object.values(DbEnum.usageUnit)
  private contractPatterns = Object.values(DbEnum.contractPattern)
  private idTypes = Object.values(DbEnum.idType)
  private contractStatuses = Object.values(DbEnum.contractStatus)
  private schoolTypes = Object.values(DbEnum.schoolType)
  private schoolFundingTypes = Object.values(DbEnum.schoolFundingType)
  private userTypes = Object.values(DbEnum.userType)
  private prefectures = Object.values(DbEnum.prefecture)
  private sex = Object.values(DbEnum.sex)
  private discloserRanges = Object.values(DbEnum.disclosureRange)
  private gradeCodes = Object.values(DbEnum.gradeCode)
  private colorThemes = Object.values(DbEnum.colorTheme)
  private profileImages = Object.values(DbEnum.profileImage)
  private stamps = Object.values(DbEnum.stamp)
  private achievements = Object.values(DbEnum.achievement)
  private dusts = Object.values(DbEnum.dust)
  private itemTypes = Object.values(DbEnum.itemType)
  private pointTypes = Object.values(DbEnum.pointType)
  private actions = Object.values(DbEnum.action)
  private formats = Object.values(DbEnum.format)
  private pageBindingTypes = Object.values(DbEnum.pageBindingType)
  private contentsStyles = Object.values(DbEnum.contentsStyle)
  private authorizedAreas = Object.values(DbEnum.authorizedArea)
  private contractSearchTypes = Object.values(DbEnum.contractSearchType)
  private decorations = Object.values(DbEnum.decoration)

  private colorThemeCodes = Object.values(ColorThemeEnum.colorTheme)

  public getServiceCode(val: number) {
    return this.serviceCodes.find((code) => code.value === val)?.key
  }

  public getUsagePattern(val: number) {
    return this.usagePatterns.find((pattern) => pattern.value === val)?.key
  }

  public getUsageUnit(val: number) {
    return this.usageUnits.find((unit) => unit.value === val)?.key
  }

  public getContractPattern(val: number) {
    return this.contractPatterns.find((pattern) => pattern.value === val)?.key
  }

  public getIdType(val: number) {
    return this.idTypes.find((type) => type.value === val)?.key
  }

  public getContractStatus(val: number) {
    return this.contractStatuses.find((status) => status.value === val)?.key
  }

  public getSchoolType(val: number) {
    return this.schoolTypes.find((type) => type.value === val)?.key
  }

  public getSchoolFundingType(val: number) {
    return this.schoolFundingTypes.find((type) => type.value === val)?.key
  }

  public getUserType(val: number) {
    return this.userTypes.find((type) => type.value === val)?.key
  }

  public getPrefecture(val: number) {
    return this.prefectures.find((prefecture) => prefecture.value === val)?.key
  }

  public getSex(val: number) {
    return this.sex.find((type) => type.value === val)?.key
  }

  public getDisclosureRange(val: number) {
    return this.discloserRanges.find((range) => range.value === val)?.key
  }

  public getGradeCode(val: number) {
    return this.gradeCodes.find((code) => code.value === val)?.key
  }

  public getContractSearchTyper(val: number) {
    return this.colorThemes.find((theme) => theme.value === val)?.key
  }

  public getProfileImage(val: number) {
    return this.profileImages.find((image) => image.value === val)?.key
  }

  public getStamp(val: number) {
    return this.stamps.find((stamp) => stamp.value === val)?.key
  }

  public getAchievement(val: number) {
    return this.achievements.find((achievement) => achievement.value === val)
      ?.key
  }

  public getDust(val: number) {
    return this.dusts.find((dust) => dust.value === val)?.key
  }

  public getItemType(val: number) {
    return this.itemTypes.find((type) => type.value === val)?.key
  }

  public getPointType(val: number) {
    return this.pointTypes.find((type) => type.value === val)?.key
  }

  public getAction(val: number) {
    return this.actions.find((action) => action.value === val)?.key
  }

  public getFormats(val: number) {
    return this.formats.find((format) => format.value === val)?.key
  }

  public getPageBindingType(val: number) {
    return this.pageBindingTypes.find((type) => type.value === val)?.key
  }

  public getContentsStyle(val: number) {
    return this.contentsStyles.find((style) => style.value === val)?.key
  }

  public getAuthorizedArea(val: number) {
    return this.authorizedAreas.find((area) => area.value === val)?.key
  }

  public getContractSearchType(val: number) {
    return this.contractSearchTypes.find((type) => type.value === val)?.key
  }

  public getDecoration(val: number) {
    return this.decorations.find((decoration) => decoration.value === val)?.key
  }

  public getColorThemeCode(val: number) {
    return this.colorThemeCodes.find((theme) => theme.themeCode === val)
  }

  public getColorTheme(val: number) {
    return this.colorThemes.find((theme) => theme.value === val)?.key
  }
}
