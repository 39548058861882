import { ref } from 'vue'

/**
 * useConfirm.
 *
 * window.confirmのような使い方が可能で、確認〜実行までの流れを一箇所に記述できる
 * isConfirmingを確認UIの表示/非表示に使用してください
 *
 * @example
 * const handleClick = async () => {
 *   if (await confirm()) {
 *     // do something.
 *   }
 * }
 */
export const useConfirm = () => {
  const isConfirming = ref(false)
  const resolveRef = ref<(agreed: boolean) => void>()

  const confirm = () => {
    return new Promise((resolve) => {
      isConfirming.value = true
      resolveRef.value = resolve
    })
  }

  const disagree = () => {
    isConfirming.value = false
    if (resolveRef.value) resolveRef.value(false)
  }

  const agree = () => {
    isConfirming.value = false
    if (resolveRef.value) resolveRef.value(true)
  }

  return {
    isConfirming,
    confirm,
    disagree,
    agree,
  }
}
