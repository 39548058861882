import StoreUser from '@/models/StoreUser'
import { usePortalStore } from '@/store/index'
import { storeToRefs } from 'pinia'
import ApiHelper from './ApiHelper'
import CookieHelper from './CookieHelper'

export default class StoreHelper {
  /**
   * ①ストアからセッションIDを取得して、返す
   * ②Cookieからを取得してみて、ストアに保存し、返す
   * ③両方にない場合、nullを返す
   */
  static sessionId() {
    const portalStore = usePortalStore()
    // ストアにセットされている場合、ストアのセッションIDを返します。
    if (portalStore.getSessionId) return portalStore.getSessionId
    // ストアになくて、local storageにある場合、それを返します。
    const lsSessionId = CookieHelper.getSession()
    if (lsSessionId) {
      portalStore.$patch({
        sessionId: lsSessionId,
      })
      return lsSessionId
    }
    return null
  }

  /**
   * ログイン済みのユーザーをストアから取得します。
   * ストアにセットしていない場合、Exceptionを返します。
   */
  static getUser() {
    const portalStore = usePortalStore()
    if (portalStore.getUser) return portalStore.getUser
    else throw new Error('ユーザーはストアにセットされていません。')
  }

  /**
   * ①ユーザーがストアに入っている場合、それを返す
   * ②セッションIDがある場合、APIからユーザーを取得し、ストアに保存する
   * ③セッションがない場合、nullを返す
   */
  static async getAndSetUser() {
    const portalStore = usePortalStore()

    if (portalStore.getUser) return portalStore.getUser

    const sessionId = this.sessionId()
    if (!sessionId) return null

    const getUserRes = await ApiHelper.getUser({})
    const themeSettingId = getUserRes.user.themeSettingId
    const user = StoreUser.fromGetUserResponse(getUserRes)
    portalStore.setUser(user)
    if (themeSettingId)
      portalStore.$patch({
        backgroundTheme: themeSettingId,
      })

    return user
  }

  /**
   * ①ジャンルがストアに入っている場合、それを返す
   * ②セッションIDがある場合、APIからジャンルを取得し、ストアに保存する
   * ③セッションがない場合、nullを返す
   */
  static async getAndSetFirstLayerCode() {
    const portalStore = usePortalStore()

    if (portalStore.getFirstLayers.length) {
      return portalStore.getFirstLayers
    }

    const sessionId = this.sessionId()
    if (!sessionId) return null

    const getFirstLayerRes = await ApiHelper.getFirstLayer({})

    getFirstLayerRes.firstLayers.unshift({
      code: null,
      name: 'すべて',
      rubyName: '',
    })

    portalStore.$patch({
      firstLayers: getFirstLayerRes.firstLayers,
    })

    return getFirstLayerRes.firstLayers
  }

  /**
   * ①出版社情報がストアに入っている場合、それを返す
   * ②セッションIDがある場合、APIから出版社情報を取得し、ストアに保存する
   * ③セッションがない場合、nullを返す
   */
  static async getAndSetPublisherList() {
    const portalStore = usePortalStore()

    if (portalStore.publisherList.length) {
      return portalStore.publisherList
    }

    const sessionId = this.sessionId()
    if (!sessionId) return null

    const req = {
      yomokkaFlag: 1,
    }
    const getPublisherListRes = await ApiHelper.getPublisherList(req)

    getPublisherListRes.publisherList.unshift({
      publisherId: null,
      publisherName: 'すべて',
    })

    portalStore.$patch({
      publisherList: getPublisherListRes.publisherList,
    })

    return getPublisherListRes.publisherList
  }

  /**
   * ログイン中のユーザが、自分の情報を更新後に呼び出すことを想定
   * ①セッションIDがある場合、APIからユーザーを取得し、ストアに保存する
   * ②セッションがない場合、nullを返す
   */
  static async setUser() {
    const sessionId = this.sessionId()
    if (!sessionId) return null

    const portalStore = usePortalStore()

    const getUserRes = await ApiHelper.getUser({})
    const user = StoreUser.fromGetUserResponse(getUserRes)

    portalStore.setUser(user)

    return user
  }

  /**
   * ユーザがアプリを使っていない時間を「分」で返します。
   */
  static getIdleTime() {
    const portalStore = usePortalStore()

    const now = new Date()
    const lastUseTime = portalStore.getLastUseTime
    const idleMilliseconds = now.getTime() - lastUseTime.getTime()
    const idleMinutes = idleMilliseconds / (1000 * 60)
    return idleMinutes
  }

  /**
   * ユーザが最後にアプリを使った時間を更新する
   */
  static updateLastUseTime() {
    const portalStore = usePortalStore()
    portalStore.$patch({
      lastUseTime: new Date(),
    })
  }

  static set maintenance(isMaintenance: boolean) {
    usePortalStore().$patch({
      maintenance: isMaintenance,
    })
  }

  static get isMaintenance() {
    return usePortalStore().getMaintenance
  }

  /**
   * ユーザのポイントを更新する
   */
  static updatePoint(point: number) {
    const portalStore = usePortalStore()
    const user = storeToRefs(portalStore)

    const newUserSettings = {
      point: point,
    }
    portalStore.$patch({
      user: Object.assign(user, newUserSettings),
    })
  }
}
