import { ref } from 'vue'

export const useScrollStickyPos = () => {
  // スクロールしているか
  const scrollY = ref(0)
  const getScrollY = () => {
    scrollY.value = window.scrollY || document.body.scrollTop
  }

  // キーボードがあるかないかでstickyの位置を変える
  const isFootFix = ref(false)
  const options = {
    rootMargin: '0px 0px -50px 0px',
    threshold: 1.0,
  }
  const intersectionCallback = (entries: { isIntersecting: unknown }[]) => {
    isFootFix.value = !entries[0].isIntersecting
  }

  return {
    scrollY,
    getScrollY,
    isFootFix,
    options,
    intersectionCallback,
  }
}
