<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="172"
    height="52"
    viewBox="0 0 172 52"
  >
    <g transform="translate(-3154.982 -1427.101)">
      <path
        d="M3182.25,1427.1h117.463c15.061,0,27.269,11.64,27.269,26h0c0,14.359-12.208,26-27.269,26H3182.25c-15.059,0-27.268-11.641-27.268-26h0C3154.982,1438.741,3167.191,1427.1,3182.25,1427.1Z"
        transform="translate(0)"
        fill="#60bcf5"
      />
      <path
        d="M3182.2,1427.1h117.565a28.151,28.151,0,0,1,10.571,2.034,27.233,27.233,0,0,1,8.649,5.556,25.881,25.881,0,0,1,5.848,8.265,25.012,25.012,0,0,1,0,20.29,25.881,25.881,0,0,1-5.848,8.265,27.233,27.233,0,0,1-8.649,5.556,28.151,28.151,0,0,1-10.571,2.034H3182.2a28.148,28.148,0,0,1-10.571-2.034,27.229,27.229,0,0,1-8.648-5.556,25.881,25.881,0,0,1-5.848-8.265,25.012,25.012,0,0,1,0-20.29,25.881,25.881,0,0,1,5.848-8.265,27.229,27.229,0,0,1,8.648-5.556A28.148,28.148,0,0,1,3182.2,1427.1Zm117.565,49a25.164,25.164,0,0,0,9.45-1.817,24.244,24.244,0,0,0,7.7-4.945,22.9,22.9,0,0,0,5.175-7.31,22.012,22.012,0,0,0,0-17.856,22.9,22.9,0,0,0-5.175-7.31,24.244,24.244,0,0,0-7.7-4.945,25.164,25.164,0,0,0-9.45-1.817H3182.2a25.164,25.164,0,0,0-9.45,1.817,24.246,24.246,0,0,0-7.7,4.945,22.9,22.9,0,0,0-5.175,7.31,22.012,22.012,0,0,0,0,17.856,22.9,22.9,0,0,0,5.175,7.31,24.246,24.246,0,0,0,7.7,4.945,25.164,25.164,0,0,0,9.45,1.817Z"
        transform="translate(0)"
        fill="#303336"
      />
    </g>
  </svg>
</template>
