import { defineStore } from 'pinia'

export type KeyboardInputStore = {
  input: string
  inputId: string
}

export const useKeyboardInputStore = defineStore('keyboardInput', {
  state: (): KeyboardInputStore => ({
    input: '',
    inputId: '',
  }),
  getters: {
    getInput: (state) => state.input,
    getInputId: (state) => state.inputId,
  },
  actions: {
    setInput(value: string) {
      this.input = value
    },
    setInputId(value: string) {
      this.inputId = value
    },
  },
})
