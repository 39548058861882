import { defineStore } from 'pinia'

export const BOOKSHELF_REGISTRATION = 'BOOKSHELF_REGISTRATION'

export type ErrorCode = string | null
export type ErrorStore = {
  [BOOKSHELF_REGISTRATION]: {
    code: ErrorCode
  }
}

export const useErrorStore = defineStore('error', {
  state: (): ErrorStore => ({
    [BOOKSHELF_REGISTRATION]: {
      code: null,
    },
  }),
  getters: {
    getErrorCode: (state) => (name: keyof ErrorStore) => {
      return state[name].code
    },
  },
  actions: {
    setErrorCode(name: keyof ErrorStore, code: ErrorCode) {
      this[name].code = code
    },
    clearErrorCode(name: keyof ErrorStore) {
      this[name].code = null
    },
  },
})
