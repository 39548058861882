import { defineStore } from 'pinia'

import StoreUser from '@/models/StoreUser'
import * as ApiIF from '@/models/ApiIF'

export type PortalStore = {
  sessionId: string | null
  user: StoreUser | null
  lastUseTime: Date
  maintenance: boolean
  backgroundTheme: number | null
  firstLayers: ApiIF.GetFirstLayerResponseItem[]
  publisherList: ApiIF.GetPublisherItem[]
}

export const usePortalStore = defineStore('portal', {
  state: (): PortalStore => ({
    sessionId: null,
    user: null,
    lastUseTime: new Date(),
    maintenance: false,
    backgroundTheme: null,
    firstLayers: [],
    publisherList: [],
  }),
  getters: {
    getBackgroundTheme: (state) => state.backgroundTheme,
    getSessionId: (state) => state.sessionId,
    getUser: (state) => state.user,
    getLastUseTime: (state) => state.lastUseTime,
    getMaintenance: (state) => state.maintenance,
    getFirstLayers: (state) => state.firstLayers,
    getPublisherList: (state) => state.publisherList,
  },
  actions: {
    // App.vueでprovideしている変数の更新イベント発火用にsetUserを定義
    setUser(user: StoreUser) {
      this.user = user
    },
  },
})
