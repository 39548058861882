/**
 * @type {string} エンドポイント定義です。
 */
const PORTAL_ENDPOINT = 'PortalAPI'
const BASE_ENDPOINT = 'BaseAPI'

/**
 * API情報のクラスです。
 * @constructor
 * @param {string} path
 * @param {string} endpointType デフォルトはPortalAPIとなる。基盤を参照する場合はBASE_ENDPOINTを指定する。
 */

export class ApiInfo {
  constructor(public path: string, public endpointType = PORTAL_ENDPOINT) {}
}

export default Object.freeze({
  auth: {
    login: new ApiInfo('/login', BASE_ENDPOINT),
    ipLogin: new ApiInfo('/ip-login', BASE_ENDPOINT),
    isAllowIp: new ApiInfo('/is-allow-ip', BASE_ENDPOINT),
    logout: new ApiInfo('/logout', BASE_ENDPOINT),
  },
  user: {
    getUser: new ApiInfo('/get-user', BASE_ENDPOINT),
    updateUser: new ApiInfo('/update-user', BASE_ENDPOINT),
  },
  ops: {
    getYmNotification: new ApiInfo('/get-ym-notification'),
    setYmNotificationView: new ApiInfo('/set-ym-notification-view'),
  },
  gacha: {
    checkExecGacha: new ApiInfo('/check-exec-gacha'),
    getExecGacha: new ApiInfo('/get-exec-gacha'),
  },
  readingCompleteHistory: {
    getReadingHistory: new ApiInfo('/get-reading-history'),
    checkReadingComplete: new ApiInfo('/check-reading-complete'),
  },
  book: {
    readingStatus: new ApiInfo('/reading-status'),
    bookSearchDetail: new ApiInfo('/book-search-detail'),
    bookSearchFreeword: new ApiInfo('/book-search-freeword'),
    bookSearchRelated: new ApiInfo('/book-search-related'),
    getBook: new ApiInfo('/get-book'),
    getBookReviewList: new ApiInfo('/get-book-review-list'),
    getBookReview: new ApiInfo('/get-book-review'),
    registerBookReview: new ApiInfo('/register-book-review'),
    deleteBookReview: new ApiInfo('/delete-book-review'),
    getFirstLayer: new ApiInfo('/get-first-layer'),
    getSecondaryLayer: new ApiInfo('/get-secondary-layer'),
    getPublisherList: new ApiInfo('/get-publisher-list'),
  },
  bookshelf: {
    getBookshelfBook: new ApiInfo('/get-bookshelf-book'),
    registerBookshelfBook: new ApiInfo('/register-bookshelf-book'),
    unregisterBookshelfBook: new ApiInfo('/unregister-bookshelf-book'),
    updateBookshelfBook: new ApiInfo('/update-bookshelf-book'),
    searchBookshelfBook: new ApiInfo('/search-bookshelf-book'),
  },
  feature: {
    getFeature: new ApiInfo('/get-feature'),
    getFeatureDetail: new ApiInfo('/get-feature-detail'),
  },
  ranking: {
    getMyRankingList: new ApiInfo('/get-my-ranking-list'),
    registerRanking: new ApiInfo('/register-ranking'),
    deleteRanking: new ApiInfo('/delete-ranking'),
    getRankingList: new ApiInfo('/get-ranking-list'),
  },
  item: {
    getExchangeableItemList: new ApiInfo('/get-exchangeable-item-list'),
    exchangeItem: new ApiInfo('/exchange-item'),
    updateBookshelfItem: new ApiInfo('/update-bookshelf-item'),
    getMyItemList: new ApiInfo('/get-my-item-list'),
  },
  point: {
    getPoint: new ApiInfo('/get-point'),
  },
  pickup: {
    getPickup: new ApiInfo('/get-pickup'),
  },
  summary: {
    getNationalRanking: new ApiInfo('/get-national-ranking'),
  },
})
