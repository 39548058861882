<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="243.619"
    height="239.14"
    viewBox="0 0 243.619 239.14"
  >
    <g transform="matrix(0.999, 0.035, -0.035, 0.999, -4453.607, -1686.329)">
      <g id="openBallTop">
        <path
          d="M4481.058,1766.279c-36.963-20.326-75.71-31.643-112.428-34.551a114.671,114.671,0,0,0,200.8,109.617C4547.4,1812.436,4517.451,1786.291,4481.058,1766.279Z"
          transform="translate(164.876 -134.405)"
          fill="#fff"
        />
        <path
          d="M4367.7,1730.15l1.045.083a267.7,267.7,0,0,1,56.439,10.8,299.713,299.713,0,0,1,106.419,58.3,268.363,268.363,0,0,1,39.021,41.1l.636.834-.565.884A116.164,116.164,0,0,1,4367.267,1731.1Zm199.9,111.268a265.735,265.735,0,0,0-37.945-39.8,296.71,296.71,0,0,0-105.34-57.708,265.045,265.045,0,0,0-54.756-10.6,113.165,113.165,0,0,0,198.041,108.11Z"
          transform="translate(164.876 -134.405)"
          fill="#303336"
        />
      </g>
    </g>
  </svg>
</template>
