<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="243.619"
    height="239.14"
    viewBox="0 0 243.619 239.14"
  >
    <g transform="matrix(0.999, 0.035, -0.035, 0.999, -4453.607, -1686.329)">
      <g id="openBallBottom" transform="translate(4515.289 1530.663)">
        <g transform="translate(0 36.172)">
          <g transform="translate(0 0)">
            <ellipse
              cx="35.857"
              cy="114.403"
              rx="35.857"
              ry="114.403"
              transform="matrix(0.483, -0.876, 0.876, 0.483, 0, 62.813)"
              fill="#d2c629"
            />
            <path
              d="M35.857-1.5A14.622,14.622,0,0,1,43.9,1.068,28.635,28.635,0,0,1,51.019,8.1c4.37,5.9,8.28,14.3,11.624,24.962a220.571,220.571,0,0,1,7.74,36.616A341.947,341.947,0,0,1,73.213,114.4a341.946,341.946,0,0,1-2.831,44.728,220.571,220.571,0,0,1-7.74,36.616c-3.343,10.667-7.254,19.065-11.624,24.962a28.635,28.635,0,0,1-7.116,7.029,14.622,14.622,0,0,1-8.046,2.568,14.622,14.622,0,0,1-8.046-2.568,28.635,28.635,0,0,1-7.116-7.029c-4.37-5.9-8.28-14.3-11.624-24.962a220.571,220.571,0,0,1-7.74-36.616A341.946,341.946,0,0,1-1.5,114.4,341.947,341.947,0,0,1,1.331,69.675a220.571,220.571,0,0,1,7.74-36.616C12.414,22.393,16.325,13.994,20.694,8.1A28.635,28.635,0,0,1,27.81,1.068,14.622,14.622,0,0,1,35.857-1.5Zm0,228.807a11.684,11.684,0,0,0,6.406-2.08,25.692,25.692,0,0,0,6.345-6.3,59.318,59.318,0,0,0,5.935-10.286A114.119,114.119,0,0,0,59.78,194.85a217.584,217.584,0,0,0,7.629-36.113,338.94,338.94,0,0,0,2.8-44.334,338.939,338.939,0,0,0-2.8-44.334A217.583,217.583,0,0,0,59.78,33.957,114.117,114.117,0,0,0,54.544,20.17,59.317,59.317,0,0,0,48.609,9.883a25.692,25.692,0,0,0-6.345-6.3A11.684,11.684,0,0,0,35.857,1.5,11.684,11.684,0,0,0,29.45,3.58a25.692,25.692,0,0,0-6.346,6.3A59.318,59.318,0,0,0,17.17,20.17a114.117,114.117,0,0,0-5.236,13.787A217.583,217.583,0,0,0,4.3,70.07,338.939,338.939,0,0,0,1.5,114.4a338.94,338.94,0,0,0,2.8,44.334,217.584,217.584,0,0,0,7.629,36.113,114.119,114.119,0,0,0,5.236,13.787A59.318,59.318,0,0,0,23.1,218.923a25.692,25.692,0,0,0,6.346,6.3A11.684,11.684,0,0,0,35.857,227.307Z"
              transform="matrix(0.483, -0.876, 0.876, 0.483, 0, 62.813)"
              fill="#303336"
            />
          </g>
        </g>
        <path
          d="M4584.6,1631.8c37.171,20.439,66.971,47.914,87.435,78.09,1.838-2.751,3.6-5.583,5.219-8.536a114.7,114.7,0,1,0-201.019-110.537c-1.625,2.955-3.073,5.956-4.412,8.981C4508.269,1600.914,4547.432,1611.36,4584.6,1631.8Z"
          transform="translate(-4454.771 -1531.361)"
          fill="#f9f247"
        />
        <path
          d="M4672.047,1712.576l-1.251-1.844a234.277,234.277,0,0,0-37.214-42.423A269.111,269.111,0,0,0,4527.538,1610a234.33,234.33,0,0,0-55.758-8.7l-2.228-.068.9-2.038c1.481-3.347,2.943-6.322,4.469-9.1a116.2,116.2,0,1,1,203.648,111.983c-1.534,2.79-3.263,5.618-5.287,8.647Zm-197.934-114.2a238.1,238.1,0,0,1,54.231,8.729,272.11,272.11,0,0,1,107.245,58.971,238.083,238.083,0,0,1,36.421,41.119c1.462-2.267,2.757-4.43,3.932-6.567a114.074,114.074,0,0,0,8.979-21.1,112.574,112.574,0,0,0,4.578-43.674,113.535,113.535,0,0,0-23.881-59.94,112.624,112.624,0,0,0-15.44-16.017,114.178,114.178,0,0,0-45.679-23.591,112.6,112.6,0,0,0-27.647-3.446,113.763,113.763,0,0,0-56.9,15.284,112.674,112.674,0,0,0-42.394,43.394C4476.383,1593.667,4475.25,1595.919,4474.114,1598.379Z"
          transform="translate(-4454.771 -1531.361)"
          fill="#303336"
        />
      </g>
    </g>
  </svg>
</template>
