import { EnvConfigType, AmplifyConfigType } from './ConfigType'

// local環境の設定をこちらに追加してください。
// EnvConfigTypeの変更も必要です。

const amplifyConfig: AmplifyConfigType = {
  aws_project_region: 'ap-northeast-1',
  aws_cloud_logic_custom: [
    {
      name: 'PortalAPI',
      endpoint: 'http://localhost:3000/dev',
      region: 'ap-northeast-1',
    },
    {
      name: 'BaseAPI',
      endpoint: 'http://localhost:3090/dev',
      region: 'ap-northeast-1',
    },
  ],
}

const config: EnvConfigType = {
  env: 'local',
  amplifyConfig: amplifyConfig,
  logLevel: 'DEBUG',
  analyticsTrackingId: 'G-SF3ELBEQJC',
  viewerEndpoint: '',
  siteDomain: 'localhost',
  adminUrl: 'http://localhost:8081',
  yomokkaUrl: 'http://localhost:8082',
  sagasokkaUrl: 'http://localhost:8083',
  mottosokkaUrl: 'http://localhost:8080',
}

export default config
