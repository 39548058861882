<template>
  <SlotModal :loading="false" :keyboard="false" @close="onClickClose">
    <div class="notifications">
      <div class="inner">
        <ul class="list">
          <li
            v-for="item in notificationList"
            :key="item.notificationId"
            class="item"
          >
            <div class="item__main" v-html="item.notification"></div>
            <div v-if="isUserId" class="item__action">
              <AppCheckbox
                class="item__checkbox"
                name="notification"
                displayName="つぎから<ruby><rb>表示</rb><rt>ひょうじ</rt></ruby>しない"
                :checked="Boolean(item.isHide)"
                @change="onChangeSelected(item.notificationId)"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </SlotModal>
</template>

<script lang="ts" setup>
/**
 * Notifications.
 *
 * お知らせモーダル.
 */

import { inject, defineProps, defineEmits, ref, onMounted, Ref } from 'vue'

import { injectionKeys } from '@/constants/injectionKeys'

import {
  GetYmNotificationResponseItem,
  SetYmNotificationViewRequest,
} from '@/models/ApiIF'

import AppCheckbox from '@/components/AppCheckbox/AppCheckbox.vue'
import SlotModal from '@/components/SlotModal/SlotModal.vue'
import ApiHelper from '@/helpers/ApiHelper'
import { useHandleError } from '@/composables/useHandleError'

const isUserId = inject<Ref<boolean>>(injectionKeys.isUserId)!
const { handleErrors } = useHandleError()

interface Props {
  notifications: GetYmNotificationResponseItem[]
}

const props = defineProps<Props>()
const emit = defineEmits(['close'])

type NotificationForDisplay = GetYmNotificationResponseItem & {
  isHide: number
}
const notificationList = ref<NotificationForDisplay[]>([])

// 「次回から表示しない」のチェックの切り替え
const onChangeSelected = (notificationId: number) => {
  const notification = notificationList.value.find(
    (item) => item.notificationId === notificationId
  )
  if (!notification) return
  notification.isHide = Number(!notification.isHide)
}

// モーダルを閉じるとき
const onClickClose = () => {
  emit('close')

  if (!isUserId) return
  setYmNotification()
}

onMounted(() => {
  notificationList.value = props.notifications.map((notification) => ({
    ...notification,
    isHide: 0,
  }))
})

// ↓ 主にTF様の編集領域
// ------------------------------

// Yomokka!お知らせ表示設定
const setYmNotification = () => {
  // 非表示対象のお知らせIDを取得
  const targetNotificationIds = notificationList.value
    .filter((notification) => {
      return notification.isHide
    })
    .map((notification) => {
      return notification.notificationId
    })
  // 非表示対象が無いときはリクエストしない
  if (targetNotificationIds.length === 0) return

  const req: SetYmNotificationViewRequest = {
    notificationIds: targetNotificationIds,
  }

  ApiHelper.setYmNotificationView(req).catch((err) => {
    handleErrors(err)
  })
}
</script>

<style lang="scss" scoped>
@import 'Notifications';
</style>
