import ApiException, { exceptions } from '@/exceptions/ApiException'
import { RouteLocationNamedRaw, useRouter } from 'vue-router'
import AuthHelper from '@/helpers/AuthHelper'
import config from '@/config/config'

/**
 * エラー内容に応じて遷移先情報を返却する
 * @param err エラーオブジェクト
 * @returns 遷移先情報
 */
/* eslint-disable max-lines-per-function */
const getNextLocation = (err: Error) => {
  let location: RouteLocationNamedRaw = { name: 'InternalServerError' }
  if (!(err instanceof ApiException)) {
    return location
  }

  switch (err.code) {
    case exceptions.auth.invalidSession.code:
      try {
        AuthHelper.logout()
        window.location.href = config.mottosokkaUrl
      } catch (err) {
        break
      }
      location = { name: 'SessionError' }
      break
    case exceptions.auth.autoLogout.code:
      location = { name: 'SessionError', params: { errorType: 'auto' } }
      break
    case exceptions.auth.forcedLogout.code:
      location = { name: 'SessionError', params: { errorType: 'forced' } }
      break
    case exceptions.access.denied.code:
      location = { name: 'NotFound' }
      break
    case exceptions.system.unavailable.code:
      location = { name: 'ServiceUnavailable' }
      break
    default:
      location = { name: 'InternalServerError' }
  }
  return location
}

export const useHandleError = () => {
  const router = useRouter()

  /**
   * エラー内容に応じてエラー画面に遷移する。
   * ※setup構文内で呼び出すときに使用する。
   *
   * @param err エラーオブジェクト
   */
  const handleErrors = (err: Error) => {
    router.replace(getNextLocation(err))
  }

  /**
   * エラー内容に応じて遷移先情報を返却する。
   * ※setup構文以外で呼び出すときに使用する。
   *
   * @param err エラーオブジェクト
   * @returns 遷移先情報
   */
  const handleErrorsOther = (err: Error) => {
    return getNextLocation(err)
  }

  return {
    handleErrors,
    handleErrorsOther,
  }
}
