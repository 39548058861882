import { ref } from 'vue'

/**
 * useDialog.
 */
export const useDialog = () => {
  const isVisible = ref(false)

  const open = () => {
    isVisible.value = true
  }

  const close = () => {
    isVisible.value = false
  }

  return {
    isVisible,
    open,
    close,
  }
}
