import { VueApp } from '@/main'
//
// 画像の読み込みがエラーの際に代わりの画像を表示するカスタムディレクティブ
export default function (app: VueApp) {
  app.directive('fallback-image', {
    mounted(el, binding) {
      const once = binding.modifiers.once
      el.addEventListener('error', function onError() {
        el.src = binding.value
        el.setAttribute('data-fallback-image', 'true')
        // フォールバックデザイン用
        el.parentElement.parentElement.parentElement.classList.add('is-404')
        if (once) {
          el.removeEventListener('error', onError)
        }
      })
    },
    updated(el, binding) {
      if (binding.oldValue !== binding.value && binding.oldValue === el.src) {
        el.src = binding.value
      }
    },
  })
}
