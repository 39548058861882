import { defineStore } from 'pinia'

export type BookshelfModeStore = {
  isRankingShow: boolean
  isMyItemShow: boolean
}

export const useBookshelfModeStore = defineStore('bookshelfMode', {
  state: (): BookshelfModeStore => ({
    isRankingShow: false,
    isMyItemShow: false,
  }),
  getters: {
    getRankingShow: (state) => state.isRankingShow,
    getMyItemShow: (state) => state.isMyItemShow,
  },
  actions: {
    setRankingShow(value: boolean) {
      this.isRankingShow = value
    },
    setMyItemShow(value: boolean) {
      this.isMyItemShow = value
    },
  },
})
