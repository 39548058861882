export class GaEventName {
  constructor(public key: string, public value: number) {}
}

export default Object.freeze({
  libraryTop: {
    clickTopButton: new GaEventName('トップボタンクリック', 1),
    clickBookshelfButton: new GaEventName('本だなボタンクリック', 2),
    clickThemeButton: new GaEventName('テーマボタンクリック', 3),
    clickHelpButton: new GaEventName('ヘルプボタンクリック', 4),
    clickKeyboardButton: new GaEventName('キーボードボタンクリック', 5),
    clickBanner: new GaEventName('特集バナークリック', 6),
    clickBookThumbnail: new GaEventName('書誌サムネイルクリック', 7),
    clickRankingButton: new GaEventName('ランキングボタンクリック', 8),
    clickGachaButton: new GaEventName('ガチャボタンクリック', 9),
    clickNowRead: new GaEventName('今読んでいる本クリック', 10),
    searchBook: new GaEventName('検索実施', 11),
    changeOrder: new GaEventName('並べ替えリスト変更', 12),
    gachaResult: new GaEventName('ガチャ結果画面表示', 13),
    searchBookshelf: new GaEventName('本だな検索実施', 14),
    clickHelpItem: new GaEventName('ヘルプ項目ボタンクリック', 15),
  },
})
