<template>
  <div class="header_top">
    <div class="header_top_inner">
      <div class="header_top_inner_logo">
        <router-link
          :to="{
            name: 'ExternalLink',
            params: { href: config.mottosokkaUrl },
          }"
        >
          <img
            :src="
              require(`@/assets/design/image/logo_mottosokka02_${config.env}.svg`)
            "
            alt="MottoSokka!"
          />
        </router-link>
      </div>
      <div class="header_top_inner_info">
        <div v-if="!isErrorPage" class="l_userinfo">
          <div class="userinfo">
            <div class="userinfo_icon">
              <router-link
                :to="{
                  name: 'ExternalLink',
                  params: {
                    href: config.mottosokkaUrl + '/my_page?type=Yomokka',
                  },
                }"
                v-if="isUserId"
              >
                <img
                  :src="
                    require(`@/assets/design/icon/profile/icon_profile${userIcon}.svg`)
                  "
                  alt="ユーザーアイコン"
                />
              </router-link>
            </div>
            <div class="userinfo_name">
              <span>{{ displayNickname }}</span>
            </div>
            <div class="userinfo_btn">
              <div class="userinfo_btn_logout">
                <a @click="logout" v-if="AuthHelper.isLogin()">ログアウト</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, Ref } from 'vue'
import { useRoute } from 'vue-router'
import AuthHelper from '@/helpers/AuthHelper'
import config from '@/config/config'
import { usePortalStore } from '@/store/index'
import { injectionKeys } from '@/constants/injectionKeys'
import { errorPageNames } from '@/router'

const route = useRoute()
const portal = usePortalStore()
const emit = defineEmits(['click:logout'])

const isUserId = inject<Ref<boolean>>(injectionKeys.isUserId)!

// 404,500,503エラー判定
const isErrorPage = computed(() => {
  return typeof route.name === 'string' && errorPageNames.includes(route.name)
})

const userIcon = computed(() => {
  const icon = portal.getUser?.profileImageType
  return icon || 1
})

// ログアウト
const logout = async () => {
  emit('click:logout')
}

const displayNickname = computed(() => {
  const nickname = portal.getUser?.displayName ?? ''
  return (
    nickname.slice(0, 25) +
    (nickname.length > 25 ? '...' : '') +
    (isUserId.value ? 'さん' : '')
  )
})
</script>

<style lang="scss" scoped>
@import 'Header';
</style>
