export default class ApiException extends Error {
  constructor(public code: ApiErrorCode, public details?: any) {
    super(`API Exception [${code}]`)
  }
}

export type ApiErrorCode =
  | 'PARAM.NOT_FOUND'
  | 'PARAM.INVALID_TYPE'
  | 'PARAM.INVALID_LENGTH'
  | 'PARAM.VALIDATION_ERROR'
  | 'DATA.DUPLICATION_ERROR'
  | 'DATA.NOT_EXIST'
  | 'DATA.ALREADY_PROCESSED'
  | 'DATA.FREEID_NOTFOUND'
  | 'DATA.CLASS_NOT_EMPTY'
  | 'DATA.NG_WORD_ERROR'
  | 'DATA.REVIEW_OVER_LIMIT_ERROR'
  | 'DATA.INPUT_RULE_ERROR'
  | 'DATA.BOOK_MAX_ERROR'
  | 'DATA.BOOK_POSITION_MAX_ERROR'
  | 'DATA.PURCHASE_MAX_ERROR'
  | 'DATA.NOT_ENOUGH_BALANCE_ERROR'
  | 'DATA.REGISTER_RANKING_OVER_LIMIT_ERROR'
  | 'DATA.PUBLISH_RANKING_OVER_LIMIT_ERROR'
  | 'DATA.REGISTER_AND_PUBLISH_RANKING_OVER_LIMIT_ERROR'
  | 'DATA.WRITE_INTERVAL_ERROR'
  | 'DATA.STUDENTS_OVER_LIMIT_ERROR'
  | 'DATA.CSV_STUDENT_DUPLICATION'
  | 'DATA.USING_ITEM'
  | 'DATA.CONFLICT'
  | 'DATA.NOT_EXIST_BOOK_GENRE'
  | 'DATA.UPDATE_FREE_ID_ERROR'
  | 'DATA.UPDATE_SPECIAL_NEEDS_STUDENT_ERROR'
  | 'DATA.FORBIDDEN_DISPLAY_NAME'
  | 'DATA.STUDENTS_NOT_ENROLLED_IN_CLASS'
  | 'AUTH.INVALID_LOGIN_INFO'
  | 'AUTH.INVALID_SESSION'
  | 'AUTH.AUTO_LOGOUT'
  | 'AUTH.FORCED_LOGOUT'
  | 'AUTH.MISMATCHED_PASSWORD'
  | 'ACCESS.DENIED'
  | 'ACCESS.UNAVAILABLE'
  | 'ACCESS.OUT_OF_LIMIT'
  | 'TARGET.NOT_FOUND'
  | 'SERVER.INTERNAL_ERROR'
  | 'SYSTEM.UNAVAILABLE'

export const exceptions = Object.freeze({
  param: {
    notFound: new ApiException('PARAM.NOT_FOUND'),
    invalidType: new ApiException('PARAM.INVALID_TYPE'),
    invalidLength: new ApiException('PARAM.INVALID_LENGTH'),
    validationError: new ApiException('PARAM.VALIDATION_ERROR'),
  },
  auth: {
    invalidLoginInfo: new ApiException('AUTH.INVALID_LOGIN_INFO'),
    invalidSession: new ApiException('AUTH.INVALID_SESSION'),
    autoLogout: new ApiException('AUTH.AUTO_LOGOUT'),
    forcedLogout: new ApiException('AUTH.FORCED_LOGOUT'),
    mismatchedPassword: new ApiException('AUTH.MISMATCHED_PASSWORD'),
  },
  access: {
    denied: new ApiException('ACCESS.DENIED'),
    unavailable: new ApiException('ACCESS.UNAVAILABLE'),
    outOfLimit: new ApiException('ACCESS.OUT_OF_LIMIT'),
  },
  data: {
    reviewOverLimitError: new ApiException('DATA.REVIEW_OVER_LIMIT_ERROR'),
    alreadyProcessed: new ApiException('DATA.ALREADY_PROCESSED'),
    bookMaxError: new ApiException('DATA.BOOK_MAX_ERROR'),
    bookPositionMaxError: new ApiException('DATA.BOOK_POSITION_MAX_ERROR'),
    duplicationError: new ApiException('DATA.DUPLICATION_ERROR'),
    freeIdNotfound: new ApiException('DATA.FREEID_NOTFOUND'),
    classNotEmpty: new ApiException('DATA.CLASS_NOT_EMPTY'),
    notExist: new ApiException('DATA.NOT_EXIST'),
    studentsOverLimitError: new ApiException('DATA.STUDENTS_OVER_LIMIT_ERROR'),
    csvStudentDuplicationError: new ApiException(
      'DATA.CSV_STUDENT_DUPLICATION'
    ),
    purchaseMaxError: new ApiException('DATA.PURCHASE_MAX_ERROR'),
    notEnoughBalanceError: new ApiException('DATA.NOT_ENOUGH_BALANCE_ERROR'),
    usingItemError: new ApiException('DATA.USING_ITEM'),
    registerRankingOverLimitError: new ApiException(
      'DATA.REGISTER_RANKING_OVER_LIMIT_ERROR'
    ),
    publishRankingOverLimitError: new ApiException(
      'DATA.PUBLISH_RANKING_OVER_LIMIT_ERROR'
    ),
    registerAndPublishRankingOverLimitError: new ApiException(
      'DATA.REGISTER_AND_PUBLISH_RANKING_OVER_LIMIT_ERROR'
    ),
    conflict: new ApiException('DATA.CONFLICT'),
    notExistBookGenre: new ApiException('DATA.NOT_EXIST_BOOK_GENRE'),
    inputRuleError: new ApiException('DATA.INPUT_RULE_ERROR'),
    writeIntervalError: new ApiException('DATA.WRITE_INTERVAL_ERROR'),
    updateFreeIdError: new ApiException('DATA.UPDATE_FREE_ID_ERROR'),
    updateSpecialNeedsStudentError: new ApiException(
      'DATA.UPDATE_SPECIAL_NEEDS_STUDENT_ERROR'
    ),
    forbiddenDisplayName: new ApiException('DATA.FORBIDDEN_DISPLAY_NAME'),
    studentsNotEnrolledInClass: new ApiException(
      'DATA.STUDENTS_NOT_ENROLLED_IN_CLASS'
    ),
  },
  server: {
    internalError: new ApiException('SERVER.INTERNAL_ERROR'),
  },
  system: {
    unavailable: new ApiException('SYSTEM.UNAVAILABLE'),
  },
})
