<template>
  <div class="gacha" :class="[`anime-${scene}`, start ? 'gacha--start' : '']">
    <div class="gacha__body">
      <div class="gacha__body_inner">
        <GachaBody class="gacha__body_svg" />
        <span class="gacha__lever">
          <GachaLever class="gacha__lever_svg" />
        </span>
      </div>
      <div class="gacha__ball">
        <div
          v-for="(ball, i) in ballArr"
          :key="`ball-${i}`"
          class="gacha__ball_svg"
        >
          <GachaBall
            :fill="ball.fill"
            :style="`transform: translate(${move(
              ball.default.x,
              ball.move.x,
              'px'
            )}, ${move(ball.default.y, ball.move.y, 'px')}) rotate(${move(
              ball.default.rotate,
              ball.move.rotate,
              'deg'
            )});`"
          />
        </div>
      </div>
    </div>

    <div class="dropBall">
      <div class="dropBall__inner">
        <div class="dropBall__ball">
          <DropBall />
        </div>
      </div>
    </div>
    <div class="openBall">
      <OpenBallBottom />
      <OpenBallTop />
    </div>
    <div class="openBook">
      <div class="openBook__bg">
        <img
          :src="require('@/assets/image/gacha/img_gacha_bg_line.svg')"
          alt=""
        />
      </div>
      <div class="openBook__star">
        <img
          :src="require('@/assets/image/gacha/img_gacha_bg_star1.svg')"
          alt=""
        />
      </div>
      <div class="openBook__item" :class="`openBook__page--${pageNum}`">
        <div class="openBook__item_inner">
          <OpenBook />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
/**
 * GachaAnimation.
 *
 * ガチャアニメーション.
 */

import { withDefaults, defineProps, ref, toRefs, watch } from 'vue'

// inline svg
import GachaBody from '@/components/GachaAnimation/svg/GachaBody.vue'
import GachaLever from '@/components/GachaAnimation/svg/GachaLever.vue'
import GachaBall from '@/components/GachaAnimation/svg/GachaBall.vue'
import DropBall from '@/components/GachaAnimation/svg/DropBall.vue'
import OpenBallTop from '@/components/GachaAnimation/svg/OpenBallTop.vue'
import OpenBallBottom from '@/components/GachaAnimation/svg/OpenBallBottom.vue'
import OpenBook from '@/components/GachaAnimation/svg/OpenBook.vue'

interface Props {
  start: boolean
  scene: number
}

const props = withDefaults(defineProps<Props>(), {
  start: false,
  scene: 0,
})

// アニメーション発火
const move = (oldValue: number, newValue: number, unit: string) => {
  switch (props.start) {
    case true:
      return `${newValue}${unit}`
    default:
      return `${oldValue}${unit}`
  }
}

// 本のパラパラ
const pageNum = ref<number>(0)
const { scene } = toRefs(props)
const bookPageTurn = () => {
  setTimeout(() => {
    pageNum.value++
    if (pageNum.value === 7) {
      pageNum.value = 4
    }
    bookPageTurn()
  }, 30)
}
watch(scene, () => {
  if (scene.value === 2) {
    setTimeout(() => {
      bookPageTurn()
    }, 1800)
  }
})

// ボール配列
const ballArr = [
  {
    fill: '#8DE0A9',
    default: {
      x: -256,
      y: -68,
      rotate: 43,
    },
    move: {
      x: -170,
      y: -190,
      rotate: 120,
    },
  },
  {
    fill: '#60BCF5',
    default: {
      x: -230,
      y: 110,
      rotate: 300,
    },
    move: {
      x: -255,
      y: -35,
      rotate: 180,
    },
  },
  {
    fill: '#FAA54F',
    default: {
      x: -160,
      y: 200,
      rotate: 30,
    },
    move: {
      x: -230,
      y: 130,
      rotate: 30,
    },
  },
  {
    fill: '#EB6E50',
    default: {
      x: -15,
      y: 220,
      rotate: 60,
    },
    move: {
      x: -160,
      y: 200,
      rotate: 200,
    },
  },
  {
    fill: '#FAA54F',
    default: {
      x: 170,
      y: 200,
      rotate: 340,
    },
    move: {
      x: 10,
      y: 220,
      rotate: 170,
    },
  },
  {
    fill: '#FAA54F',
    default: {
      x: -135,
      y: -175,
      rotate: 30,
    },
    move: {
      x: -5,
      y: -215,
      rotate: 140,
    },
  },
  {
    fill: '#F9F247',
    default: {
      x: -5,
      y: -230,
      rotate: -40,
    },
    move: {
      x: 150,
      y: -170,
      rotate: 40,
    },
  },
  {
    fill: '#60BCF5',
    default: {
      x: 140,
      y: -165,
      rotate: 220,
    },
    move: {
      x: 220,
      y: -60,
      rotate: 340,
    },
  },
  {
    fill: '#8DE0A9',
    default: {
      x: 200,
      y: -50,
      rotate: 200,
    },
    move: {
      x: 240,
      y: 85,
      rotate: 120,
    },
  },
  {
    fill: '#F9F247',
    default: {
      x: 260,
      y: 35,
      rotate: 30,
    },
    move: {
      x: 170,
      y: 200,
      rotate: -40,
    },
  },
  {
    fill: '#F9F247',
    default: {
      x: -125,
      y: -15,
      rotate: -30,
    },
    move: {
      x: 15,
      y: -65,
      rotate: -120,
    },
  },
  {
    fill: '#8DE0A9',
    default: {
      x: -45,
      y: 65,
      rotate: 10,
    },
    move: {
      x: -125,
      y: -15,
      rotate: 90,
    },
  },
  {
    fill: '#60BCF5',
    default: {
      x: 115,
      y: 80,
      rotate: 20,
    },
    move: {
      x: -45,
      y: 65,
      rotate: -60,
    },
  },
  {
    fill: '#EB6E50',
    default: {
      x: 20,
      y: -75,
      rotate: 140,
    },
    move: {
      x: 115,
      y: 60,
      rotate: 240,
    },
  },
]
</script>

<style lang="scss" scoped>
@import 'GachaAnimation';
</style>
