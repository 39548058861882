<template>
  <transition name="fade">
    <div
      v-if="scrollY > 10"
      class="ScrollTopButton"
      :class="{
        'is-keyboard': keyboard,
        'is-ranking': isRankingShow,
        'is-myItem': isMyItemShow,
        'is-fix': isFootFix,
      }"
    >
      <button
        class="ScrollTopButton__button animationLink"
        @click="onScrollTop"
      >
        <AnimationPushToShadow
          tag="span"
          backgroundColor="#eaeef4"
          shadowColor="#93a8b5"
          rounded="circle"
        >
          <div class="ScrollTopButton__icon">
            <AppImage
              :src="require('@/assets/image/icon/icon_arrow_top.svg')"
              alt="上へ戻る"
            />
          </div>
        </AnimationPushToShadow>
      </button>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { defineProps, withDefaults, ref, onMounted } from 'vue'

import { useScrollStickyPos } from '@/composables/useScrollStickyPos'

import { useBookshelfModeStore } from '@/store/bookshelfMode'

import AnimationPushToShadow from '@/components/AnimationPushToShadow/AnimationPushToShadow.vue'
import AppImage from '@/components/AppImage/AppImage.vue'

interface Props {
  keyboard?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  keyboard: false,
})

// スクロールTOP
const onScrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
  document.body.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

// 本だなモード取得
const bookshelfModeStore = useBookshelfModeStore()
const isRankingShow = ref(false)
const isMyItemShow = ref(false)
bookshelfModeStore.$subscribe(async () => {
  isRankingShow.value = bookshelfModeStore.getRankingShow
  isMyItemShow.value = bookshelfModeStore.getMyItemShow
})

// スクロール位置調整
const { scrollY, getScrollY, isFootFix, options, intersectionCallback } =
  useScrollStickyPos()

onMounted(() => {
  // スクロールしてるか
  window.addEventListener('scroll', getScrollY)
  document.body.addEventListener('scroll', getScrollY)

  // キーボードがあるかないか
  const footer = document.querySelector('.l_footer') as HTMLElement
  const observer = new window.IntersectionObserver(
    intersectionCallback,
    options
  )
  if (footer) {
    observer.observe(footer)
  }
})
</script>

<style lang="scss">
@import 'ScrollTopButton';
</style>
