import { EnvConfigType, AmplifyConfigType } from './ConfigType'

// dev環境の設定をこちらに追加してください。
// EnvConfigTypeの変更も必要です。

const amplifyConfig: AmplifyConfigType = {
  aws_project_region: 'ap-northeast-1',
  aws_cloud_logic_custom: [
    {
      name: 'PortalAPI',
      endpoint:
        'https://saqi4in72l.execute-api.ap-northeast-1.amazonaws.com/dev',
      region: 'ap-northeast-1',
    },
    {
      name: 'BaseAPI',
      endpoint:
        'https://oz203mnns3.execute-api.ap-northeast-1.amazonaws.com/dev',
      region: 'ap-northeast-1',
    },
  ],
}

const config: EnvConfigType = {
  env: 'dev',
  amplifyConfig: amplifyConfig,
  logLevel: 'DEBUG',
  analyticsTrackingId: 'G-E7EY8NDP9Z',
  viewerEndpoint: '',
  siteDomain: 'dev.manabi.poplar.co.jp',
  adminUrl: 'https://admin.dev.manabi.poplar.co.jp',
  yomokkaUrl: 'https://yomokka.dev.manabi.poplar.co.jp',
  sagasokkaUrl: 'https://sagasokka.dev.manabi.poplar.co.jp',
  mottosokkaUrl: 'https://dev.manabi.poplar.co.jp',
}

export default config
