<template>
  <div class="list">
    <label v-for="item in list" :key="item.code">
      <input
        type="radio"
        :name="groupName"
        :value="item.code"
        :checked="item.code === currentValue"
        class="input"
        @change="onChange(item, $event)"
      />
      <span
        class="radio"
        :class="[groupColor, { checked: item.code === currentValue }]"
      >
        <span v-if="item.icon">
          <AppImage :src="item.icon" :alt="item.name" width="20" height="20" />
        </span>
        <span v-html="item.rubyName || item.name" />
      </span>
    </label>
  </div>
</template>

<script lang="ts" setup>
/**
 * RadioGroup.
 *
 * ラジオボタンのグループ
 */

import { defineProps, withDefaults, defineEmits } from 'vue'

import AppImage from '@/components/AppImage/AppImage.vue'

interface Props {
  currentValue: string | number | null
  groupName: string
  groupColor?: string
  list: {
    code: string | number | null
    name: string
    rubyName?: string
    icon?: string
  }[]
}

const props = withDefaults(defineProps<Props>(), {
  groupColor: 'cyan',
})

const emit = defineEmits(['change'])

const onChange = (item: Props['list'][0], event: Event) => {
  const target = event.currentTarget

  if (!(target instanceof HTMLInputElement)) return

  emit('change', item, event)
}
</script>

<style lang="scss" scoped>
@import 'RadioGroup';
</style>
