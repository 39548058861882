<template>
  <component :is="tag" :class="className" :style="style">
    <span class="animePushToShadow__front">
      <slot />
    </span>
    <span class="animePushToShadow__back" />
  </component>
</template>

<script lang="ts" setup>
/**
 * AnimationPushToShadow.
 * @description コンポーネントを囲うボタンやリンク要素に`.animationLink`のCSSクラスを付与してください。
 */

import { defineProps, withDefaults, computed } from 'vue'
import { useMediaQuery } from '@vueuse/core'

import { BREAK_POINTS } from '@/constants/mediaQuery'

interface Props {
  tag?: string
  backgroundColor?: string
  shadowColor?: string
  shadowX?: string
  shadowY?: string
  disabled?: boolean
  rounded?: string | 'pill' | 'circle'
  duration?: string
  easing?: string

  // interface extendsを使用するとエラーになるため、前述をベタ書き
  wide?: {
    tag?: string
    backgroundColor?: string
    shadowColor?: string
    shadowX?: string
    shadowY?: string
    disabled?: boolean
    rounded?: string | 'pill' | 'circle'
    duration?: string
    easing?: string
  }
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'span',
  backgroundColor: '#fff',
  shadowColor: '#718998',
  shadowX: '4px',
  shadowY: '4px',
  disabled: false,
  duration: '0.2s',
  easing: 'cubic-bezier(0, 0, 0.58, 1)',
})

const className = computed(() => {
  return ['animePushToShadow', props.disabled ? 'disabled' : '']
})

const isWideScreen = useMediaQuery(`(min-width: ${BREAK_POINTS.pc}px)`)

const style = computed(() => {
  const backgroundColor =
    (isWideScreen.value && props.wide?.backgroundColor) || props.backgroundColor
  const shadowColor =
    (isWideScreen.value && props.wide?.shadowColor) || props.shadowColor
  const shadowX = (isWideScreen.value && props.wide?.shadowX) || props.shadowX
  const shadowY = (isWideScreen.value && props.wide?.shadowY) || props.shadowY
  const rounded = (() => {
    const value = (isWideScreen.value && props.wide?.rounded) || props.rounded
    switch (value) {
      case 'pill':
        return '999px'
      case 'circle':
        return '50%'
      default:
        return value
    }
  })()
  const duration =
    (isWideScreen.value && props.wide?.duration) || props.duration
  const easing = (isWideScreen.value && props.wide?.easing) || props.easing

  return {
    '--animePushToShadow-background-color': backgroundColor,
    '--animePushToShadow-shadow-color': shadowColor,
    '--animePushToShadow-shadow-x': shadowX,
    '--animePushToShadow-shadow-y': shadowY,
    '--animePushToShadow-rounded': rounded,
    '--animePushToShadow-duration': duration,
    '--animePushToShadow-easing': easing,
  }
})
</script>

<style lang="scss" scoped>
@import 'AnimationPushToShadow';
</style>
