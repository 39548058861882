import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(isBetween)
dayjs.tz.setDefault('Asia/Tokyo')

const JST_OFFSET = 9 * 60

export default class DateHelper {
  private static instance: DateHelper

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance(): DateHelper {
    if (!DateHelper.instance) {
      DateHelper.instance = new DateHelper()
    }

    return DateHelper.instance
  }

  /* 日付のフォーマット */
  public DateFormat = Object.freeze({
    SIMPLE_HH_MM: 'HHmm',
    SIMPLE_HH_MM_SS: 'HHmmss',
    SIMPLE_YYYY_MM_DD_HH_MM_SS: 'YYYYMMDDHHmmss',
    SIMPLE_YYYY_MM_DD: 'YYYYMMDD',
    DATE_YYYY_MM_DD_HH_MM_SS: 'YYYYMMDD_HHmmss',
    ISO_YYYY_MM_DD: 'YYYY-MM-DD',
    ISO_YYYY_MM_DD_HH_MM_SS: 'YYYY-MM-DD HH:mm:ss',
    ISO_YYYY_MM_DD_00_00_00: 'YYYY-MM-DD 00:00:00',
    DATE_YYYY_MM_DD_JP: 'YYYY/MM/DD',
    DATE_YYYY_MM_DD_HH_MM_SS_JP: 'YYYY/MM/DD HH:mm:ss',
    YYYY: 'YYYY',
  })

  /**
   * 現在日時を日本標準時（JST）で取得する。
   * dateが指定された場合は日本標準時（JST）に変換する
   *
   * @param date 日付
   * @return {string} 日付
   */
  public newDate(date?: string) {
    if (date) {
      return dayjs(date).tz().format()
    }
    return dayjs().tz().format()
  }

  /**
   * 現在日付（JST）を指定したフォーマットで取得する
   *
   * @param format フォーマット
   * @return {string} 日付
   */
  public formatCurrentDate(format: string): string {
    return dayjs().tz().format(format)
  }
  /**
   * 日付のフォーマット変換を行う。
   *
   * @param {String} date 日付
   * @param {String} format フォーマット
   * @return {string} 日付
   */
  public formatDateToString(date: string, format: string) {
    if (!date) return ''

    return dayjs(date).tz().format(format)
  }

  /**
   * 現在日付を日で加算／減算して返却する。
   *
   * @param {string} format フォーマット
   * @param {number} days 日数
   * @return {string} 日付
   */
  public getCurrentDateAddDays(format: string, days: number) {
    return dayjs().tz().add(days, 'days').format(format)
  }

  /**
   * 日付を日で加算／減算して返却する。
   *
   * @param {string} date 日付
   * @param {string} format フォーマット
   * @param {number} days 日数
   * @return {string} 日付
   */
  public getDateAddDays(date: string, format: string, days: number) {
    if (!date) return ''

    return dayjs(date).tz().add(days, 'days').format(format)
  }

  /**
   * 現在日時と日付を比較チェックを行う。
   *
   * @param {string} compareDate 日付（比較対象）
   * @param {string} format フォーマット
   * @return {boolean} true: 現在日時 > compareDate、false:現在日時 <= compareDate
   */
  public validateAfterCurrentDate(compareDate: string, format: string) {
    if (!compareDate) return false

    const currentDateAfterFormat = this.formatCurrentDate(format)
    const compareDateAfterFormat = this.formatDateToString(compareDate, format)
    return dayjs(currentDateAfterFormat).isAfter(compareDateAfterFormat)
  }

  /**
   * ユニックスタイムを取得する(単位は秒)
   * @return {string}
   */
  public getUnixtime(date: string | null) {
    if (!date) return 0

    return dayjs(date).unix()
  }

  public formatDate(date: string | null) {
    if (!date) return null
    const [year, month, day] = date.substring(0, 10).split('-')
    return `${year}/${month}/${day}`
  }

  public formatYear(date: string | null) {
    if (!date) return null
    return date.substring(0, 4)
  }

  public formatUTC9Date(date: string | null) {
    if (!date) return null
    return date + 'T00:00:00.000+09:00'
  }

  /**
   * API用日時フォーマット（"2021-02-13T14:33:25.997+09:00"）からDateObjectを作成。
   */
  public getDateFromApiFormat(strDate: string): Date {
    return new Date(strDate)
  }

  DAY_JP = [
    { kanji: '日', ruby: 'にちようび' },
    { kanji: '月', ruby: 'げつようび' },
    { kanji: '火', ruby: 'かようび' },
    { kanji: '水', ruby: 'すいようび' },
    { kanji: '木', ruby: 'もくようび' },
    { kanji: '金', ruby: 'きんようび' },
    { kanji: '土', ruby: 'どようび' },
  ]

  /**
   * JSTでの曜日を返す
   * @param myDate
   */
  public getDayJp(dateStr: string) {
    const date = this.getDateFromApiFormat(dateStr)
    date.setTime(date.getTime() + this.getOffsetMin() * 60 * 1000)
    return this.DAY_JP[date.getDay()]
  }

  /** ローカルのタイムゾーンとJSTの時差を取得（単位：分） */
  private getOffsetMin() {
    const jstOffset = 9 * 60
    const date = new Date()
    return date.getTimezoneOffset() + jstOffset
  }
}
