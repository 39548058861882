<template>
  <label :tabIndex="0" class="appCheckbox" :class="{ disabled }">
    <input
      type="checkbox"
      :name="name"
      :checked="checked"
      :disabled="disabled"
      class="input"
      @change="onChange"
    />
    <span class="checkbox" />
    <span class="text" v-html="displayName" />
  </label>
</template>

<script lang="ts" setup>
/**
 * AppCheckbox.
 *
 * チェックボックス
 */

import { defineProps, withDefaults, defineEmits } from 'vue'

interface Props {
  name: string
  displayName: string
  checked: boolean
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
})

const emit = defineEmits(['change'])

const onChange = (event: Event) => {
  if (props.disabled) return

  const target = event.currentTarget

  if (!(target instanceof HTMLInputElement)) return

  const params = {
    name: props.name,
    checked: !props.checked,
  }
  emit('change', params, event)
}
</script>

<style lang="scss" scoped>
@import 'AppCheckbox';
</style>
