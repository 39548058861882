import { Grade } from '@/constants'

// 学年
export { type Grade, GRADE_LIST } from '@/constants'

// 検索結果の順序
const Order = (name: string, code: number) => ({ name, code })

export type OrderType = ReturnType<typeof Order>
export type OrdersType = Readonly<Record<string, Readonly<OrderType>>>

export const ORDERS: OrdersType = {
  orderRelated: Order('一致する順', 1),
  nOrderReleaseStart: Order('新しく入った順', 2),
  orderTitleAsc: Order('タイトル　あ～', 3),
  orderTitleDesc: Order('タイトル　ん～', 4),
  nOrderBottomBookRelease: Order('刊行年が新しい順', 5),
  oOrderBottomBookRelease: Order('刊行年が古い順', 6),
}

// 検索タイプ
const Search = (name: string, type: string) => ({ name, type })

export const SEARCHS = {
  freeword: Search('フリーワード検索', 'freeword'),
  detail: Search('詳しく検索', 'detail'),
  grade: Search('学年別検索', 'grade'),
  reSearch: Search('再検索', 're_search'),
}

export type FreeWordSearchParams = {
  freeWord: string
  grade: Grade['code'] | string | null
  first: string | null
  second: string | null
  order: OrderType['code'] | string | null
  learning: number
  page: number
}
export type DetailSearchParams = {
  area: number
  title: string
  author: string
  dateTo: string
  dateFrom: string
  grade: Grade['code'] | string | null
  first: string | null
  second: string | null
  publisher: string
  contentsStyle: number
  learning: number
  page: number
}
export type GradeSearchParams = {
  grade: Grade['code'] | string | null
  order: OrderType['code'] | string | null
}
export type ResearchParams = {
  freeWord: string
  grade: Grade['code'] | string | null
  first: string | null
  second: string | null
  order: OrderType['code'] | string | null
}
export type SearchParams = {
  freeword: FreeWordSearchParams & { searchType: typeof SEARCHS.freeword.type }
  detail: DetailSearchParams & { searchType: typeof SEARCHS.detail.type }
  grade: GradeSearchParams & { searchType: typeof SEARCHS.grade.type }
  reSearch: ResearchParams & { searchType: typeof SEARCHS.reSearch.type }
}
