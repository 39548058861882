<template>
  <v-app id="app" v-bind="attrs">
    <div v-if="StoreHelper.isMaintenance" class="maintenance-hint-text">
      <div>メンテナンス中</div>
    </div>
    <v-main class="l-main" :style="backgroundThemeStyle">
      <router-view />
    </v-main>
  </v-app>
</template>

<script lang="ts" setup>
import { provide, ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useMediaQuery } from '@vueuse/core'

import { usePortalStore } from '@/store'

import { injectionKeys } from '@/constants/injectionKeys'
import AuthHelper from '@/helpers/AuthHelper'
import StoreHelper from '@/helpers/StoreHelper'

// ルート情報
const route = useRoute()

// ユーザー種別
const isUserId = ref(false)
const isSharedId = ref(false)
const isFixedIp = ref(false)
const isWhitelist = ref(false)
const forbidRegister = ref(false)
const forbidPublish = ref(false)

// ユーザー種別を下層コンポーネントに渡す
provide(injectionKeys.isUserId, isUserId)
provide(injectionKeys.isSharedId, isSharedId)
provide(injectionKeys.isFixedIp, isFixedIp)
provide(injectionKeys.isWhitelist, isWhitelist)
provide(injectionKeys.forbidRegister, forbidRegister)
provide(injectionKeys.forbidPublish, forbidPublish)

// MEMO: タッチデバイスでcssの:activeが反応しないため、ルートにontouchstart属性を付与することで解決
// @see https://iholdings.jp/archives/2504
const isTouchDevice = useMediaQuery(`(hover: none)`)
const attrs = computed(() => {
  return isTouchDevice.value ? { ontouchstart: '' } : {}
})

// 100dvh計算用
const setCalcVh = () => {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}
let resizeTimeout: number | null = null
const handleResize = () => {
  if (resizeTimeout) {
    window.cancelAnimationFrame(resizeTimeout)
  }
  resizeTimeout = window.requestAnimationFrame(() => {
    setCalcVh()
  })
}
onMounted(() => {
  setCalcVh()
  window.addEventListener('resize', handleResize)
})

// ダブルタップを無効化
let lastTouch = 0
const disableTouchAction = (e: any) => {
  const now = window.performance.now()
  if (now - lastTouch <= 500) {
    e.preventDefault()
  }
  lastTouch = now
}
document.addEventListener('touchend', disableTouchAction, true)

// 404,500エラー判定
const isErrorPage = computed(() => {
  if (route.name === 'InternalServerError') return true
  if (route.name === 'ServiceUnavailable') return true
  if (route.name === 'NotFound') return true
  return false
})

// テーマ切り替え
const portal = usePortalStore()
const backgroundThemeStyle = computed(() => {
  if (!isErrorPage.value) {
    if (isSharedId.value || isFixedIp.value) {
      return {
        '--background-theme': `url('${require(`@/assets/image/background_theme/background/1.jpg`)}')`,
      }
    }
    if (!portal.getBackgroundTheme) return { '--background-theme': 'none' }
    return {
      '--background-theme': `url('${require(`@/assets/image/background_theme/background/${portal.getBackgroundTheme}.jpg`)}')`,
    }
  } else {
    return {
      '--background-theme': 'none',
    }
  }
})

portal.$onAction(({ after }) => {
  after(() => {
    // ユーザー種別を設定
    isUserId.value = AuthHelper.isUserId()
    isSharedId.value = AuthHelper.isSharedId()
    isFixedIp.value = AuthHelper.isFixedIp()
    isWhitelist.value = AuthHelper.isWhitelist()
    forbidRegister.value = AuthHelper.forbidRegister()
    forbidPublish.value = AuthHelper.forbidPublish()
  })
})
</script>

<style lang="scss" src="./App.scss" />
