import AppEnum from '@/enums/AppEnum'
import * as ls from 'local-storage'
import DateHelper from './DateHelper'

const dateHelper = DateHelper.getInstance()
const DATE_YYYY_MM_DD_JP = dateHelper.DateFormat.DATE_YYYY_MM_DD_JP

export default class LocalStorageHelper {
  public static setReadBookDialogClosedTime(time: number) {
    ls.set('readBookDialogClosedTime', time)
  }

  public static getReadBookDialogClosedTime() {
    return ls.get('readBookDialogClosedTime')
  }

  private static getBiBStartUpCount() {
    return ls.get<number | null>('BiB_StartUpCount')
  }

  private static getBiBShowTipsOnNextTime() {
    return ls.get<string | null>('BiB_ShowTipsOnNextTime')
  }

  private static getBiBSPDStartUpCount() {
    return ls.get<number | null>('BiBSPD_StartUpCount')
  }

  private static getBiBSPDShowTipsOnNextTime() {
    return ls.get<string | null>('BiBSPD_ShowTipsOnNextTime')
  }

  private static getYmLoginDatetime() {
    return ls.get<string | null>('ym_login_datetime')
  }

  private static getYmHideTipsDatetime() {
    return ls.get<string | null>('ym_hide_tips_datetime')
  }
  private static getYmHideTipsStatus() {
    return ls.get<number | null>('ym_hide_tips_status')
  }

  public static setViewerData() {
    const beforeHideStatus = this.getYmHideTipsStatus()

    // Tips非表示状況
    this.setYmHideTipsStatus()

    const afterHideStatus = this.getYmHideTipsStatus()
    this.setYmHideTipsDatetime(beforeHideStatus, afterHideStatus)

    this.clearViewerData()
    // YMトップ表示日付
    ls.set(
      'ym_login_datetime',
      dateHelper.formatCurrentDate(DATE_YYYY_MM_DD_JP)
    )
  }

  /**
   * ビューア制御情報をクリア(ym_hide_tips_datetimeが14日以前(14日目含む)場合)
   */
  private static clearViewerData() {
    const ymHideTipsDatetime = this.getYmHideTipsDatetime()

    if (
      ymHideTipsDatetime !== null &&
      dateHelper.validateAfterCurrentDate(
        dateHelper.getDateAddDays(ymHideTipsDatetime, DATE_YYYY_MM_DD_JP, 13),
        DATE_YYYY_MM_DD_JP
      )
    ) {
      ls.remove('BiB_StartUpCount')
      ls.remove('BiB_ShowTipsOnNextTime')
      ls.remove('BiBSPD_StartUpCount')
      ls.remove('BiBSPD_ShowTipsOnNextTime')
      ls.remove('ym_hide_tips_datetime')
      ls.remove('ym_hide_tips_status')
    }
  }

  /**
   * スタンダード非表示条件
   */
  private static hideTipsStatusOfStandard() {
    return (
      !this.getBiBShowTipsOnNextTime() && Number(this.getBiBStartUpCount()) >= 1
    )
  }

  /**
   * スピード非表示条件
   */
  private static hideTipsStatusOfSpeed() {
    return (
      !this.getBiBSPDShowTipsOnNextTime() &&
      Number(this.getBiBSPDStartUpCount()) >= 1
    )
  }

  /**
   * Tips非表示状況設定
   */
  private static setYmHideTipsStatus() {
    if (this.hideTipsStatusOfStandard() && this.hideTipsStatusOfSpeed()) {
      ls.set('ym_hide_tips_status', AppEnum.ymHideTipsStatus.all.value)
      return
    }
    if (this.hideTipsStatusOfStandard()) {
      ls.set('ym_hide_tips_status', AppEnum.ymHideTipsStatus.onlyStandard.value)
      return
    }
    if (this.hideTipsStatusOfSpeed()) {
      ls.set('ym_hide_tips_status', AppEnum.ymHideTipsStatus.onlySpeed.value)
      return
    }
    return
  }

  /**
   * Tips非表示日付設定
   * @param beforeHideStatus
   * @param afterHideStatus
   */
  private static setYmHideTipsDatetime(
    beforeHideStatus: number | null,
    afterHideStatus: number | null
  ) {
    const ymLoginDatetime =
      this.getYmLoginDatetime() ??
      dateHelper.formatCurrentDate(DATE_YYYY_MM_DD_JP)
    if (beforeHideStatus === null && afterHideStatus !== null) {
      ls.set('ym_hide_tips_datetime', ymLoginDatetime)
      return
    }
    if (beforeHideStatus !== 0 && afterHideStatus === 0) {
      ls.set('ym_hide_tips_datetime', ymLoginDatetime)
      return
    }
  }
}
