<template>
  <div class="slotModal" :class="{ 'is-noKeyboard': !keyboard }">
    <div class="slotModal__overlay"></div>
    <div
      class="slotModal__wrapper"
      :class="{
        'no-head': !$slots.title,
        'no-actionArea': !$slots.actionArea,
        'is-fillHeight': fillHeight,
        'is-fit': fit || isModalHeightOver,
        isKeyboardShow,
      }"
    >
      <div class="slotModal__frame">
        <div class="slotModal__inner">
          <div
            class="slotModal__close"
            :class="{ 'is-hide': !isButtonShow }"
            @click="close"
          >
            <AnimationPushToShadow
              tag="button"
              backgroundColor="#eaeef4"
              shadowColor="#93a8b5"
              rounded="circle"
            >
              <span class="slotModal__close_icon">
                <AppImage
                  :src="require('@/assets/image/icon/icon_close.svg')"
                  alt="とじる"
                />
              </span>
            </AnimationPushToShadow>
          </div>
          <div v-if="$slots.title" class="slotModal__head">
            <div v-if="icon" class="slotModal__icon">
              <AppImage
                :src="icon"
                :noImageSrc="require('@/assets/image/img_book_404.svg')"
                alt=""
                width="36"
                height="36"
              />
            </div>
            <div class="slotModal__title">
              <slot name="title"></slot>
            </div>
          </div>
          <div
            v-if="$slots.default"
            class="slotModal__contents"
            :data-loading="loadingState?.loading"
          >
            <div class="slotModal__contents_inner">
              <slot></slot>
            </div>
            <transition v-if="loading" name="fade">
              <AppLoading
                v-if="loadingState && loadingState.loading"
                :type="loadingState.type"
                :class="{
                  'is-head': $slots.title,
                  noActionArea: !$slots.actionArea,
                }"
              />
            </transition>
          </div>
          <div
            v-if="$slots.actionArea"
            class="slotModal__actionArea"
            :data-loading="loadingState?.loading"
          >
            <slot name="actionArea"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps,
  withDefaults,
  defineEmits,
  computed,
  ref,
  toRefs,
  watch,
  onMounted,
} from 'vue'

import { sleep } from '@/utils'

import { useKeyboardStore } from '@/store/keyboard'
import { LoadingStore, useLoadingStore, loadingReset } from '@/store/loading'

import AppLoading from '@/components/AppLoading/AppLoading.vue'
import AnimationPushToShadow from '@/components/AnimationPushToShadow/AnimationPushToShadow.vue'
import AppImage from '@/components/AppImage/AppImage.vue'

interface Props {
  fillHeight?: boolean
  icon?: string
  button?: boolean
  fit?: boolean
  loading?: boolean
  keyboard?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  fillHeight: true,
  icon: '',
  button: true,
  fit: false,
  loading: true,
  keyboard: true,
})

// 閉じるボタンの表示監視
const isButtonShow = ref<boolean>(true)
const { button } = toRefs(props)
watch(button, (res) => {
  isButtonShow.value = res
})

const emit = defineEmits(['close'])

const close = () => {
  setModalHeightOver()
  emit('close')
}

// モーダルの高さを計算
const isModalHeightOver = ref<boolean>(false)
const setModalHeightOver = (state = false) => {
  isModalHeightOver.value = state
}
const calcModalHeight = () => {
  // props.keyboardがfalseの場合は処理しない
  if (!props.keyboard) return
  // 既に設定されていたら処理しない
  if (isModalHeightOver.value) return
  const wrapper = document.querySelectorAll('.slotModal__wrapper')
  wrapper.forEach((el) => {
    const contains = el.classList.contains('is-fit')
    const modalAreaHeight = el?.clientHeight || 0
    const modalHeight = el.querySelector('.slotModal__frame')?.clientHeight || 0

    // 既に設定されていたら処理しない
    if (contains) {
      setModalHeightOver(true)
      return
    }
    // モーダルの高さがモーダルエリアの高さを超えていたら
    if (!isModalHeightOver.value && modalAreaHeight < modalHeight) {
      setModalHeightOver(true)
    }
  })
}

// ソフトキーボードが表示されているか
const keyboardStore = useKeyboardStore()
const isKeyboardShow = computed(() => keyboardStore.getShow)
keyboardStore.$subscribe(async (mutation, state) => {
  if (state.isShow) {
    await sleep(200)
    // モーダルの高さを計算
    setModalHeightOver()
    calcModalHeight()
  }
})

// ローディング
const loadingStore = useLoadingStore()
const loadingState = ref<LoadingStore>()
loadingStore.$subscribe(async (mutation, state) => {
  // nameで設定
  loadingState.value = state.name === 'modal' ? loadingReset : undefined
  // モーダルの高さを計算
  calcModalHeight()
})
// 初回
loadingState.value = loadingStore.getLoadingState

onMounted(() => {
  // モーダルの高さを計算
  calcModalHeight()
})
</script>

<style lang="scss" scoped>
@import 'SlotModal';
</style>
