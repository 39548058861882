import { GetUserResponse } from './ApiIF'

export default class StoreUser {
  constructor(
    public userId: number,
    public idType: number,
    public displayName: string | null,
    public contractPattern: number,
    public userType: number | null,
    public profileImageType: number | null,
    public contractApplicationManagerFlg: number | null,
    public disclosureRange: number | null,
    public point: number | null,
    public passwordConfigured: number | null,
    public constraintOption: number,
    public schoolType: number | null,
    public functionLimitList: number[],
    public calculatedGrade: number | null,
    public municipalName: string | null,
    public schoolInfoId: number | null
  ) {}

  public static fromGetUserResponse(res: GetUserResponse) {
    return new StoreUser(
      res.user.userId,
      res.user.idType,
      res.user.displayName ?? res.user.loginId,
      res.user.contractPattern,
      res.user.userType,
      res.user.profileImageType,
      res.user.contractApplicationManagerFlg,
      res.user.disclosureRange,
      res.user.point,
      res.user.passwordConfigured,
      res.user.constraintOption,
      res.user.schoolType,
      res.user.functionLimitCode,
      res.user.calculatedGrade,
      res.user.municipalName,
      res.user.schoolInfoId
    )
  }
}
