import InputSettings from '@/models/InputSettings'

import emojiRegex from 'emoji-regex'

export default class ValidationHelper {
  private static instance: ValidationHelper

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance(): ValidationHelper {
    if (!ValidationHelper.instance) {
      ValidationHelper.instance = new ValidationHelper()
    }

    return ValidationHelper.instance
  }

  public rules: Record<string, any> = {
    required: (value: string, flag: boolean, child?: boolean, ruby?: boolean) =>
      flag
        ? this.required(value) ||
          (child
            ? ruby
              ? '<ruby><rb>必</rb><rt>かなら</rt></ruby>ず<ruby><rb>入</rb><rt>い</rt></ruby>れてください'
              : '必ず入れてください'
            : '必須項目です')
        : true,
    number: (value: string, flag: boolean, child?: boolean, ruby?: boolean) =>
      flag
        ? this.number(value) ||
          (child
            ? '使える文字は半角すうじです'
            : '使用可能な文字は半角数字です')
        : true,
    alphanumeric: (
      value: string,
      flag: boolean,
      child?: boolean,
      ruby?: boolean
    ) =>
      flag
        ? this.alphanumeric(value) ||
          (child
            ? ruby
              ? '<ruby><rb>使</rb><rt>つか</rt></ruby>える<ruby><rb>文</rb><rt>も</rt></ruby><ruby><rb>字</rb><rt>じ</rt></ruby>は<ruby><rb>半</rb><rt>はん</rt></ruby><ruby><rb>角</rb><rt>かく</rt></ruby>えいすうじです'
              : '使える文字は半角えいすうじです'
            : '使用可能な文字は半角数字です')
        : true,
    fullWidth: (
      value: string,
      flag: boolean,
      child?: boolean,
      ruby?: boolean
    ) =>
      flag
        ? this.fullWidth(value) ||
          (child ? '使える文字は全角です' : '使用可能な文字は全角です')
        : true,
    fullWidthKana: (
      value: string,
      flag: boolean,
      child?: boolean,
      ruby?: boolean
    ) =>
      flag
        ? this.fullWidthKana(value) ||
          (child ? '使える文字は全角です' : '使用可能な文字は全角カナです')
        : true,
    phone: (value: string, flag: boolean, child?: boolean, ruby?: boolean) =>
      flag
        ? this.phone(value) ||
          (child
            ? '使える文字は半角数字、- (半角ハイフン)です'
            : '使用可能な文字は半角数字、- (半角ハイフン)です')
        : true,
    email: (value: string, flag: boolean, child?: boolean, ruby?: boolean) =>
      flag
        ? this.email(value) ||
          (child
            ? 'メールアドレスがただしくありません'
            : 'メールアドレスが正しくありません')
        : true,
    ip: (value: string, flag: boolean, child?: boolean, ruby?: boolean) =>
      flag
        ? this.ip(value) ||
          (child
            ? '有効なIPアドレスを入力してください'
            : '有効なIPアドレスを入力してください')
        : true,
    unsignedFloat: (
      value: string,
      flag: boolean,
      child?: boolean,
      ruby?: boolean
    ) =>
      flag
        ? this.unsignedFloat(value) ||
          (child
            ? '使える文字は半角数字、.(半角ドット）です'
            : '使用可能な文字は半角数字、.(半角ドット）です')
        : true,
    maxLength: (
      value: string,
      length: number,
      child?: boolean,
      ruby?: boolean
    ) =>
      this.maxLength(value, length) ||
      (child
        ? ruby
          ? `${length}<ruby><rb>文字</rb><rt>もじ</rt></ruby><ruby><rb>以内</rb><rt>いない</rt></ruby>で<ruby><rb>入力</rb><rt>にゅうりょく</rt></ruby>してください`
          : `${length}文字以内で入力してください`
        : `${length}文字以内で入力してください`),
    minLength: (
      value: string,
      length: number,
      child?: boolean,
      ruby?: boolean
    ) =>
      this.minLength(value, length) ||
      (child
        ? ruby
          ? `${length}<ruby><rb>文字</rb><rt>もじ</rt></ruby><ruby><rb>以上</rb><rt>いじょう</rt></ruby>で<ruby><rb>入力</rb><rt>にゅうりょく</rt></ruby>してください`
          : `${length}文字以上で入力してください`
        : `${length}文字以上で入力してください`),
    exactLength: (
      value: string,
      length: number,
      child?: boolean,
      ruby?: boolean
    ) =>
      this.exactLength(value, length) ||
      (child
        ? ruby
          ? `${length}<ruby><rb>文字</rb><rt>もじ</rt></ruby>で<ruby><rb>入力</rb><rt>にゅうりょく</rt></ruby>してください`
          : `${length}文字で入力してください`
        : `${length}文字で入力してください`),
    stringMatch: (inputString: string, compareString: string) =>
      this.stringMatch(inputString, compareString) || ``,
    fullWidthHalfWidthNumber: (
      value: string,
      flag: boolean,
      child?: boolean,
      ruby?: boolean
    ) =>
      flag
        ? this.fullWidthHalfWidthNumber(value) ||
          (child ? '使える文字はすうじです' : '使用可能な文字は数字です')
        : true,
    max: (value: number, max: number, child?: boolean, ruby?: boolean) =>
      this.max(value, max) ||
      (child
        ? `${max}以内で入力してください。`
        : `${max}以内で入力してください。`),
    min: (value: number, min: number, child?: boolean, ruby?: boolean) =>
      this.min(value, min) ||
      (child
        ? `${min}以上を入力してください。`
        : `${min}以上を入力してください。`),
    checkEmoji: (
      value: string,
      flag: boolean,
      child?: boolean,
      ruby?: boolean
    ) =>
      flag
        ? this.checkEmoji(value) ||
          (child
            ? ruby
              ? `さがすことのできない<ruby><rb>文字</rb><rt>もじ</rt></ruby>が<ruby><rb>入力</rb><rt>にゅうりょく</rt></ruby>されています`
              : `さがすことのできない文字が入力されています`
            : `さがすことのできない文字が入力されています`)
        : true,
  }

  public required(inputString: string) {
    return !!inputString
  }

  public number(inputString: string) {
    if (!inputString) return true
    const regEx = new RegExp('^\\d+$')
    if (inputString.match(regEx)) {
      return true
    }
    return false
  }

  public fullWidth(inputString: string) {
    if (!inputString) return true
    const regEx = new RegExp('^[‐－―ー ぁ-んァ-ヴ一-龥ａ-ｚＡ-Ｚ０-９]*$')
    if (inputString.match(regEx)) {
      return true
    }
    return false
  }

  public fullWidthKana(inputString: string) {
    if (!inputString) return true
    // /^([ァ-ン]|ー)+$/
    const regEx = new RegExp('^[ァ-ヴー]*$')
    if (inputString.match(regEx)) {
      return true
    }
    return false
  }

  public halfWidth(inputString: string) {
    if (!inputString) return true
    // Unicode : 0x0 ~ 0x80, 0xf8f0, 0xff61 ~ 0xff9f, 0xf8f1 ~ 0xf8f3
    const regEx = new RegExp(
      '^[\u0032-\u0080\uf8f0\uff61-\uff9f\uf8f1-\uf8f3]*$'
    )
    if (inputString.match(regEx)) {
      return true
    }
    return false
  }

  public phone(inputString: string) {
    if (!inputString) return true
    const regEx = new RegExp('^[0-9-]*$')
    if (inputString.match(regEx)) {
      return true
    }
    return false
  }

  public email(inputString: string) {
    if (!inputString) return true
    const regEx = new RegExp(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
    if (inputString.match(regEx)) {
      return true
    }
    return false
  }

  public ip(inputString: string) {
    if (!inputString) return true
    const regEx = new RegExp('^[0-9]+\\.[0-9]+\\.[0-9]+\\.[0-9]+$')
    if (inputString.match(regEx)) {
      const ipSplit = inputString.split('.')
      for (let i = 0; i < ipSplit.length; i++) {
        if (parseInt(ipSplit[i]) > 255 || parseInt(ipSplit[i]) < 0) {
          return false
        }
      }
      return true
    }
    return false
  }

  public unsignedFloat(inputString: string) {
    if (!inputString) return true
    const regEx = new RegExp('^[0-9.]*$')
    if (inputString.match(regEx)) {
      return true
    }
    return false
  }

  public alphanumeric(inputString: string) {
    if (!inputString) return true
    const regEx = new RegExp('^[a-zA-Z0-9]*$')
    if (inputString.match(regEx)) {
      return true
    }
    return false
  }

  public maxLength(inputString: string, length: number) {
    if (!inputString) return true
    return inputString.length <= length
  }

  public minLength(inputString: string, length: number) {
    if (!inputString) return true
    return inputString.length >= length
  }

  public stringMatch(inputString: string, compareString: string) {
    if (!inputString && !compareString) return true
    return inputString === compareString
  }

  public fullWidthHalfWidthNumber(inputString: string) {
    if (!inputString) return true
    const regEx = new RegExp('^[0-9０-９]*$')
    if (inputString.match(regEx)) {
      return true
    }
    return false
  }

  public max(inputNumber: number, max: number) {
    return inputNumber <= max
  }

  public min(inputNumber: number, min: number) {
    return inputNumber >= min
  }

  public exactLength(inputString: string, length: number) {
    if (!inputString) return true
    return inputString.length === length
  }

  public regExpMatch(inputString: string, regExp: string) {
    if (!inputString) return true
    const regEx = new RegExp(regExp)
    if (inputString.match(regEx)) {
      return true
    }
    return false
  }

  public ipToNum(ip: string | undefined) {
    if (!ip) return 0
    return Number(
      ip
        .split('.')
        .map((d) => ('000' + d).substr(-3))
        .join('')
    )
  }

  public validate(
    value: string,
    inputSettings: InputSettings,
    field: string,
    child?: boolean,
    ruby?: boolean
  ) {
    //Validation
    let valid = true
    const rulesKey = Object.keys(inputSettings[field].rules)
    const rulesValue = Object.values(inputSettings[field].rules)
    const rulesLength = rulesKey.length

    for (let i = 0; i < rulesLength; i++) {
      const key = rulesKey[i]
      valid = this.rules[key](value, rulesValue[i], child)
      if (typeof valid === 'string') {
        return valid
      }
    }
    return valid
  }

  public getAllErrorMessages(
    value: string,
    inputSettings: InputSettings,
    field: string,
    value2?: string,
    child = false,
    ruby = false
  ) {
    let valid = true
    let errorMsg = ''
    const rulesKey = Object.keys(inputSettings[field].rules)
    const rulesValue = Object.values(inputSettings[field].rules)
    const rulesLength = rulesKey.length

    for (let i = 0; i < rulesLength; i++) {
      const key = rulesKey[i]
      valid = this.rules[key](value, rulesValue[i], child, ruby)
      if (typeof valid === 'string') {
        errorMsg += valid
        break
      }
      if (value2 && typeof value2 !== 'undefined') {
        valid = this.rules[key](value2, rulesValue[i], child, ruby)
        if (typeof valid === 'string') {
          errorMsg += valid
          break
        }
      }
    }
    return errorMsg
  }

  /**
   * 入力エラーをチェックして、エラーがある場合は最初にヒットしたエラーメッセージを返します
   * @param value 入力文字列
   * @param inputSettings 入力規則
   * @param field 入力文字列に対応した入力規則カラム名
   * @param child 児童向けか
   * @param ruby ルビが必要か
   * @returns 入力エラーがある場合：最初にヒットしたエラーメッセージ、入力エラーがない場合：空文字
   */
  public getErrorMessage(
    value: string,
    inputSettings: InputSettings,
    field: string,
    child = false,
    ruby = false
  ) {
    //Validation
    const rulesKey = Object.keys(inputSettings[field].rules)
    const rulesValue = Object.values(inputSettings[field].rules)
    const rulesLength = rulesKey.length

    for (let i = 0; i < rulesLength; i++) {
      const key = rulesKey[i]
      const errorMsg = this.rules[key](value, rulesValue[i], child, ruby)
      if (typeof errorMsg === 'string') {
        return errorMsg
      }
    }
    return ''
  }

  private checkEmoji(inputText: string) {
    if (!inputText) return true
    const regex = emojiRegex()
    for (const match of inputText.matchAll(regex)) {
      const emoji = match[0]
      if (emoji) return false
    }
    return true
  }
}
