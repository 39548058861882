import { defineStore } from 'pinia'

export type KeyboardStore = {
  isShow: boolean
}

export const useKeyboardStore = defineStore('keyboard', {
  state: (): KeyboardStore => ({
    isShow: false,
  }),
  getters: {
    getShow: (state) => state.isShow,
  },
  actions: {
    toggleShow() {
      this.isShow = !this.isShow
    },
    setShow(value: boolean) {
      this.isShow = value
    },
  },
})
