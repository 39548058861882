<template>
  <v-text-field
    v-model="value"
    hideDetails
    :id="id"
    :name="name"
    :type="type"
    :placeholder="placeholder"
    :readonly="readonly"
    class="input"
    :class="{
      error: errorMessages && errorMessages.length > 0,
    }"
    @change="onInputChange"
    @keydown.enter="onEnter($event)"
    @focus="onInputFocus"
    @click="onInputFocus"
  />
  <div class="errors">
    <div v-if="typeof errorMessages === 'string'">{{ errorMessages }}</div>
    <template v-else-if="Array.isArray(errorMessages)">
      <div v-for="(message, i) in errorMessages" :key="i">
        {{ message }}
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
/**
 * AppInput.
 *
 * VuetifyのコンポーネントをラップしたInputコンポーネント.
 */

import { defineProps, withDefaults, defineEmits, computed, watch } from 'vue'

import { useKeyboardStore } from '@/store/keyboard'
import { useKeyboardInputStore } from '@/store/keyboardInput'

import { VTextField } from 'vuetify/components'

interface Props {
  id?: string
  name?: string
  type?: string
  modelValue: string
  placeholder?: string
  errorMessages?: string | string[]
}

const props = withDefaults(defineProps<Props>(), {
  name: '',
  type: 'text',
  modelValue: '',
  placeholder: '',
  errorMessages: '',
})

const emit = defineEmits(['update:modelValue', 'enter'])

const value = computed({
  get: () => props.modelValue,
  set: (value: string) => {
    emit('update:modelValue', value)
  },
})

const keyboardInputStore = useKeyboardInputStore()

const onInputChange = (event: Event) => {
  const target = event.currentTarget

  if (!(target instanceof HTMLInputElement)) return

  emit('update:modelValue', target.value)
  keyboardInputStore.setInput(target.value)
}

const onEnter = (e: { keyCode: number }) => {
  if (keyboardStore.getShow) return
  if (e.keyCode === 229) return // 文字変換
  emit('enter')
}

const onInputFocus = () => {
  if (props.id) {
    keyboardInputStore.setInputId(props.id)
    keyboardInputStore.setInput(value.value)
  }
}

// ソフトキーボードフォーカス設定
const keyboardStore = useKeyboardStore()
const readonly = computed(() => {
  if (!keyboardStore.getShow) return false
  return true
})

// ストアで保持する入力値が変更された時、実際の要素に反映
watch(
  () => keyboardInputStore.getInput,
  (newInput: string) => {
    if (props.id && keyboardInputStore.getInputId === props.id) {
      value.value = newInput
    }
  }
)
</script>

<style lang="scss" scoped>
@import 'AppInput';
</style>
>
