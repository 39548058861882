<template>
  <div>
    <div class="book-wrapper">
      <div class="book bound">
        <div class="pages">
          <!-- 表紙(表1) ※見開きから始まるため非表示 -->
          <div class="page flipped"></div>

          <!-- 1ページ目(左側) -->
          <div
            class="page flipped"
            :style="{
              backgroundImage: `url(${firstPage.backgroundImage})`,
            }"
            @click="executeFlip"
          >
            <span
              data-observe="firstPageTitle"
              class="text text--head-left-pages"
              :style="{
                right: `-${elementsHalfWidth.firstPageTitle}`,
              }"
              v-html="firstPage.title"
            />
            <span
              data-observe="firstPageCount"
              class="text text--num-left"
              :style="{
                right: `-${elementsHalfWidth.firstPageCount}`,
              }"
            >
              {{ firstPage.count }}
            </span>
            <span
              data-observe="firstPageUnit"
              class="text text--foot-left-pages"
              :style="{
                right: `-${elementsHalfWidth.firstPageUnit}`,
              }"
              >{{ firstPage.unit }}</span
            >
          </div>

          <!-- 1ページ目(右側) -->
          <div
            class="page flipped with-page-flip-right"
            data-flip="next"
            :style="{
              backgroundImage: `url(${firstPage.backgroundImage})`,
            }"
            @click="executeFlip"
          >
            <span
              class="text text--head-right-pages"
              :style="{
                left: `-${elementsHalfWidth.firstPageTitle}`,
              }"
              v-html="firstPage.title"
            />
            >
            <span
              class="text text--num-right"
              :style="{
                left: `-${elementsHalfWidth.firstPageCount}`,
              }"
            >
              {{ firstPage.count }}
            </span>
            <span
              class="text text--foot-right-pages"
              :style="{
                left: `-${elementsHalfWidth.firstPageUnit}`,
              }"
              >{{ firstPage.unit }}</span
            >
          </div>

          <!-- 2ページ目(左側) -->
          <div
            class="page flipped with-page-flip-left"
            data-flip="prev"
            :style="{
              backgroundImage: `url(${secondPage.backgroundImage})`,
            }"
            @click="executeFlip"
          >
            <span
              data-observe="secondPageTitle"
              class="text text--head-left-books"
              :style="{
                right: `-${elementsHalfWidth.secondPageTitle}`,
              }"
              v-html="secondPage.title"
            />
            <span
              data-observe="secondPageCount"
              class="text text--num-left"
              :style="{
                right: `-${elementsHalfWidth.secondPageCount}`,
              }"
            >
              {{ secondPage.count }}
            </span>
            <span
              data-observe="secondPageUnit"
              class="text text--foot-left-books"
              :style="{
                right: `-${elementsHalfWidth.secondPageUnit}`,
              }"
              >{{ secondPage.unit }}</span
            >
          </div>

          <!-- 2ページ目(右側) -->
          <div
            class="page"
            :style="{
              backgroundImage: `url(${secondPage.backgroundImage})`,
            }"
            @click="executeFlip"
          >
            <span
              class="text text--head-right-books"
              :style="{
                left: `-${elementsHalfWidth.secondPageTitle}`,
              }"
              v-html="secondPage.title"
            />
            <span
              class="text text--num-right"
              :style="{
                left: `-${elementsHalfWidth.secondPageCount}`,
              }"
            >
              {{ secondPage.count }}
            </span>
            <span
              class="text text--foot-right-books"
              :style="{
                left: `-${elementsHalfWidth.secondPageUnit}`,
              }"
              >{{ secondPage.unit }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
/**
 * ReadBookStatus.
 *
 * 読書ステータス.
 */

import { sleep } from '@/utils'
import {
  defineProps,
  withDefaults,
  reactive,
  onMounted,
  onUnmounted,
  toRefs,
  watch,
} from 'vue'

interface Props {
  firstPage: {
    backgroundImage: string
    title: string
    count: number
    unit: string
  }
  secondPage: {
    backgroundImage: string
    title: string
    count: number
    unit: string
  }
  loaded: boolean
}

const props = withDefaults(defineProps<Props>(), {
  firstPage: () => ({
    backgroundImage: require('@/assets/image/top/book_pages.svg'),
    title: '',
    count: 0,
    unit: '',
  }),
  secondPage: () => ({
    backgroundImage: require('@/assets/image/top/book_books.svg'),
    title: '',
    count: 0,
    unit: '',
  }),
  loaded: false,
})

const elementsHalfWidth = reactive({
  firstPageTitle: '',
  firstPageCount: '',
  firstPageUnit: '',
  secondPageTitle: '',
  secondPageCount: '',
  secondPageUnit: '',
})
type ElementsHalfWidthType = keyof typeof elementsHalfWidth

let nextFlip: 'prev' | 'next' = 'prev'

// 自動でページをめくるクロージャー
const flipper = (() => {
  const interval = 60000
  let intervalId: number

  const once = () => {
    executeFlip()
  }

  const start = () => {
    intervalId = window.setInterval(() => {
      executeFlip()
    }, interval)
  }

  const stop = () => clearInterval(intervalId)

  return {
    once,
    start,
    stop,
  }
})()

// ローディング完了を監視
const { loaded } = toRefs(props)
watch(loaded, async () => {
  // アニメーション分を待つ
  await sleep(300)
  flipper.once()
})

onMounted(() => {
  init()
  flipper.start()
})

onUnmounted(() => {
  flipper.stop()
})

/**
 * observeResize.
 *
 * 本に表示しているテキストのDOMの幅の半分をセット.
 *
 * @param {ElementsHalfWidthType} type
 * @returns {ResizeObserver} observer
 */
const observeResize = (type: ElementsHalfWidthType): ResizeObserver => {
  const el = document.querySelector(`[data-observe=${type}]`)

  if (!el) {
    throw Error(`[data-observe=${type}] element not found.`)
  }

  const observer = new ResizeObserver((entries) => {
    for (const entry of entries) {
      let width: number

      if (entry.contentBoxSize) {
        // Firefox implements `contentBoxSize` as a single content rect, rather than an array
        const contentBoxSize = Array.isArray(entry.contentBoxSize)
          ? entry.contentBoxSize[0]
          : entry.contentBoxSize

        width = contentBoxSize.inlineSize
      } else {
        width = entry.contentRect.width
      }

      elementsHalfWidth[type] = `${width / 2}px`
    }
  })

  observer.observe(el)

  return observer
}

/**
 * init.
 */
const init = async (): Promise<undefined> => {
  // 本に表示しているテキストのDOMの幅を監視
  observeResize('firstPageTitle')
  observeResize('firstPageCount')
  observeResize('firstPageUnit')
  observeResize('secondPageTitle')
  observeResize('secondPageCount')
  observeResize('secondPageUnit')

  // z-index調整
  const pages = document.querySelectorAll('.page')

  if (!pages) return

  pages.forEach((page, i) => {
    if (i % 2 === 0) {
      ;(page as HTMLElement).style.zIndex = String(pages.length - i)
    }
  })
}

/**
 * executeFlip.
 */
const executeFlip = () => {
  const pageEl = document.querySelector(`[data-flip="${nextFlip}"]`)

  if (pageEl instanceof HTMLElement) {
    nextFlip = nextFlip === 'prev' ? 'next' : 'prev'
    flip(pageEl)
  }
}

let timerId: number

/**
 * flip.
 *
 * @param {HTMLElement} pageEl
 */
const flip = (pageEl: HTMLElement): void => {
  flipper.stop()

  const animationDuration = 1400
  clearTimeout(timerId)
  timerId = window.setTimeout(() => {
    flipper.start()
  }, animationDuration)

  const pages = document.querySelector('.pages')?.children
  const page = pageEl

  if (!pages || !page) return

  const pageNum = [...pages].indexOf(page) + 1

  if (pageNum % 2 === 0) {
    page.classList.remove('flipped')
    page.previousElementSibling?.classList.remove('flipped')
  } else {
    page.classList.add('flipped')
    page.nextElementSibling?.classList.add('flipped')
  }
}
</script>

<style lang="scss" scoped>
@import 'ReadBookStatus';
</style>
