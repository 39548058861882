<template>
  <v-slide-y-reverse-transition>
    <v-footer :height="props.keyboard ? 250 : 0" app v-show="props.keyboard">
      <div class="softwareKeyboard" :style="{ width: keyboardStyle }"></div>
    </v-footer>
  </v-slide-y-reverse-transition>
</template>

<script lang="ts" setup>
import Keyboard from 'simple-keyboard'
import { defineProps, onMounted, watch, computed, onBeforeUnmount } from 'vue'
import { useDisplay } from 'vuetify'
import 'simple-keyboard/build/css/index.css'
import { useKeyboardStore } from '@/store/keyboard'
import { useKeyboardInputStore } from '@/store/keyboardInput'

interface Props {
  // ソフトウェアキーボードが表示されているか否か
  keyboard: boolean
}
// v-footerでprpps.keyboardを使用
const props = defineProps<Props>()

const BLANK_KEY = 'BLANK_KEY'
// {backspace}の代替 物理backspaceキーによるアニメーションを無効化するため
const DELETE_ONE_KEY = '{deleteOne}'
// {space}の代替 物理spaceキーによるアニメーションを無効化するため
const SPACE_KEY = '{alternateSpace}'
let currentOnKeyPress = ''
let softwareKeyboard!: Keyboard

// ソフトキーボード内アイコン
const defaultIcon = require('@/assets/image/icon/icon_softkeyboard_default.svg')
const alphanumericIcon = require('@/assets/image/icon/icon_softkeyboard_alphanumeric.svg')
const deleteOneKeyIcon = require('@/assets/image/icon/icon_softkeyboard_delete_one_key.svg')
const deleteAllIcon = require('@/assets/image/icon/icon_softkeyboard_delete_all.svg')
const closeIcon = require('@/assets/image/icon/icon_softkeyboard_close.svg')

const display = useDisplay()
const keyboardStyle = computed(() => {
  if (display.mdAndDown.value) {
    return '100vw'
  } else if (display.lg.value) {
    return '80vw'
  } else {
    return '60vw'
  }
})
const keyboardInputStore = useKeyboardInputStore()
const keyboardStore = useKeyboardStore()
const closeKeyboard = () => {
  keyboardStore.setShow(false)
}

/**
 * 現在フォーカス中のカーソル位置を取得
 */
const getCursorPosition = () => {
  const inputElement = document.querySelector(
    `#${keyboardInputStore.getInputId}`
  ) as HTMLInputElement
  const caretPosition = softwareKeyboard.getCaretPosition()
  const caretPositionEnd = softwareKeyboard.getCaretPositionEnd()
  return {
    selectionStart:
      caretPosition || caretPosition === 0
        ? caretPosition
        : inputElement.selectionStart,
    selectionEnd:
      caretPositionEnd || caretPositionEnd === 0
        ? caretPositionEnd
        : inputElement.selectionEnd,
  }
}

/**
 * 入力文字列を反映
 * @param input 入力要素に反映すべき文字列
 */
const setInput = (input: string) => {
  const inputElement = document.querySelector(
    `#${keyboardInputStore.getInputId}`
  ) as HTMLInputElement
  setTimeout(() => {
    // カーソル配置・フォーカス再設定
    const { selectionStart, selectionEnd } = getCursorPosition()
    // 入力反映操作によって要素のフォーカスが外れるため、再度フォーカス
    inputElement.focus()
    // 再フォーカスによって実際のカーソル位置が右端にずれるため、ソフトウェアキーボード上のカーソル位置と同期
    inputElement.setSelectionRange(selectionStart, selectionEnd)
  }, 0)
  // 入力文字列をストアに反映
  keyboardInputStore.setInput(input)
}

// ソフトウェアキーボード表示中、キーボード入力を無効化
const disableHardwareKeyboard = (event: KeyboardEvent) => {
  if (keyboardStore.getShow) {
    event.preventDefault()
  }
}
document.addEventListener('keydown', disableHardwareKeyboard)
onBeforeUnmount(() => {
  document.removeEventListener('keydown', disableHardwareKeyboard)
})

const handleShift = () => {
  if (currentOnKeyPress === '{alphanumeric}') {
    softwareKeyboard.setOptions({ layoutName: 'alphanumeric' })
  } else if (currentOnKeyPress === '{default}') {
    softwareKeyboard.setOptions({ layoutName: 'default' })
  }
}

/**
 * 代替backspaceキーおよび代替spaceキーの処理
 * @param button "{deleteOne}" | "{alternateSpace}"
 */
const alternateKeyProgress = (
  button: typeof DELETE_ONE_KEY | typeof SPACE_KEY
) => {
  const { selectionStart, selectionEnd } = getCursorPosition()
  const input = keyboardInputStore.getInput

  // カーソル位置前後で文字列を分割、各処理を実行
  const inputLeft = selectionStart ? input.slice(0, selectionStart) : ''
  const inputRight = selectionEnd ? input.slice(selectionEnd) : input
  if (button == DELETE_ONE_KEY) {
    // 複数選択か否かで処理を分岐
    if (selectionStart === selectionEnd) {
      setInput([inputLeft.slice(0, -1), inputRight].join(''))
      softwareKeyboard.setCaretPosition(
        selectionStart && selectionStart > 1 ? selectionStart - 1 : 0
      )
    } else {
      setInput([inputLeft, inputRight].join(''))
      softwareKeyboard.setCaretPosition(selectionStart ? selectionStart : 0)
    }
  } else if (button == SPACE_KEY) {
    setInput([inputLeft, ' ', inputRight].join(''))
    softwareKeyboard.setCaretPosition(selectionStart ? selectionStart + 1 : 1)
  }
}

/**
 * ソフトウェアキーボードのキー押下時の処理
 * @param button 押下されたキー
 */
const onKeyPress = (button: string) => {
  if (button == '{default}') {
    softwareKeyboard.addButtonTheme('{default}', 'dynamic-key-color')
    softwareKeyboard.removeButtonTheme('{alphanumeric}', 'dynamic-key-color')
  } else if (button == '{alphanumeric}') {
    softwareKeyboard.addButtonTheme('{alphanumeric}', 'dynamic-key-color')
    softwareKeyboard.removeButtonTheme('{default}', 'dynamic-key-color')
  }

  currentOnKeyPress = button
  const emptyChar = ''

  if (button == '{deleteAll}') {
    setInput(emptyChar)
    softwareKeyboard.setCaretPosition(0)
  }
  if (button == DELETE_ONE_KEY || button == SPACE_KEY) {
    alternateKeyProgress(button)
  }
  /**
   * If you want to handle the shift and caps lock buttons
   */
  if (button === '{default}' || button === '{alphanumeric}') {
    handleShift()
  }

  if (button === '{close}') {
    closeKeyboard()
  }
}

/**
 * ソフトウェアキーボード入力が行われた時に呼び出される
 * 濁点・半濁点入力時の処理
 * @param input inputに入力されている文字列
 */
// eslint-disable-next-line
const onChange = (input: string) => {
  // 入力された文字およびキャレット前後の文字列を取得
  let caretPosition = softwareKeyboard.getCaretPosition()
  let lastKeyPress = ''
  let stringBeforeCaret = ''
  let stringAfterCaret = ''
  if (!caretPosition) caretPosition = input.length
  if (caretPosition === input.length) {
    lastKeyPress = input.slice(-1)
    stringBeforeCaret = input.slice(0, -2)
  } else {
    lastKeyPress = input.slice(caretPosition - 1, caretPosition)
    stringBeforeCaret = input.slice(0, caretPosition - 2)
    stringAfterCaret = input.slice(caretPosition, input.length)
  }
  //濁点半濁点処理
  if (lastKeyPress === '゛') {
    // キャレット直前の文字が濁点である場合
    const secondToLastKeyPress = input[caretPosition - 2]
    if (!secondToLastKeyPress) {
      // 濁点の直前に文字がない場合、濁点単体の文字を削除
      input = input.replace('゛', '')
      // ソフトウェアキーボード上のinputに濁点消去を反映
      softwareKeyboard.setInput(input)
    } else {
      // 濁点の直前に文字がある場合
      const beforeDakutenList =
        'かきくけこさしすせそたちつてとはひふへほウカキクケコサシスセソタチツテトハヒフヘホ'.indexOf(
          secondToLastKeyPress
        )
      if (beforeDakutenList !== -1) {
        // 濁点の直前が濁点をつけられる文字の場合、該当文字を濁点アリに置き換え、濁点単体の文字を削除
        const addDakuten =
          'がぎぐげござじずぜぞだぢづでどばびぶべぼヴガギグゲゴザジズゼゾダヂヅデドバビブベボ'.charAt(
            beforeDakutenList
          )
        input = stringBeforeCaret + addDakuten + stringAfterCaret
      } else {
        // 濁点の直前の文字が濁点をつけられない場合、濁点単体の文字を削除
        input = input.replace('゛', '')
        // ソフトウェアキーボード上のinputに濁点消去を反映
        softwareKeyboard.setInput(input)
      }
    }
    // 濁点削除の分キャレット位置を調整
    softwareKeyboard.setCaretPosition(caretPosition - 1)
  } else if (lastKeyPress === '゜') {
    // キャレット直前の文字が半濁点である場合
    const secondToLastKeyPress = input[caretPosition - 2]
    if (!secondToLastKeyPress) {
      // 半濁点の直前に文字がない場合、半濁点単体の文字を削除
      input = input.replace('゜', '')
      // ソフトウェアキーボード上のinputに半濁点消去を反映
      softwareKeyboard.setInput(input)
    } else {
      // 半濁点の直前に文字がある場合
      const beforeHandakutenList = 'はひふへほハヒフヘホ'.indexOf(
        secondToLastKeyPress
      )
      if (beforeHandakutenList !== -1) {
        // 半濁点の直前が半濁点をつけられる文字の場合、該当文字を半濁点アリに置き換え、半濁点単体の文字を削除
        const addHandakuten = 'ぱぴぷぺぽパピプペポ'.charAt(
          beforeHandakutenList
        )
        input = stringBeforeCaret + addHandakuten + stringAfterCaret
      } else {
        // 半濁点の直前の文字が半濁点をつけられない場合、半濁点単体の文字を削除
        input = input.replace('゜', '')
        // ソフトウェアキーボード上のinputに半濁点消去を反映
        softwareKeyboard.setInput(input)
      }
    }
    // 半濁点削除の分キャレット位置を調整
    softwareKeyboard.setCaretPosition(caretPosition - 1)
  }
  setInput(input)
}

/**
 * ソフトウェアキーボード初期化
 */
// eslint-disable-next-line
const initializeKeyboard = () => {
  softwareKeyboard = new Keyboard('.softwareKeyboard', {
    ...commonKeyboardOptions,
    layout: {
      default: [
        `{alphanumeric} ゛ ゃ ${BLANK_KEY} ぁ ${BLANK_KEY} わ ら や ま は な た さ か あ`,
        `${DELETE_ONE_KEY} ゜ ${BLANK_KEY} ${BLANK_KEY} ぃ ${BLANK_KEY} ${BLANK_KEY} り ${BLANK_KEY} み ひ に ち し き い`,
        `{deleteAll} ー ゅ っ ぅ ${BLANK_KEY} を る ゆ む ふ ぬ つ す く う`,
        `${SPACE_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ぇ ${BLANK_KEY} ${BLANK_KEY} れ ${BLANK_KEY} め へ ね て せ け え`,
        `{close} ${BLANK_KEY} ょ ${BLANK_KEY} ぉ ${BLANK_KEY} ん ろ よ も ほ の と そ こ お`,
      ],
      alphanumeric: [
        `{default} A B C D E F ${BLANK_KEY} 1 2 3 ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY}`,
        `${DELETE_ONE_KEY} G H I J K L ${BLANK_KEY} 4 5 6 ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY}`,
        `{deleteAll} M N O P Q R ${BLANK_KEY} 7 8 9 ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY}`,
        `${SPACE_KEY} S T U V X Y ${BLANK_KEY} ${BLANK_KEY} 0 ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY}`,
        `{close} Z ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY} ${BLANK_KEY}`,
      ],
    },
    display: {
      '{default}': `<span><img src=${defaultIcon} /></span><span class="key-icon-text">ひらがな</span>`,
      '{alphanumeric}': `<span><img src=${alphanumericIcon} /></span><span class="key-icon-text">ABC・123</span>`,
      [DELETE_ONE_KEY]: `<span><img src=${deleteOneKeyIcon} /></span><span class="key-icon-text">1<ruby>文字消<rp>(<rt>もじけ<rp>)</ruby>す</span>`,
      '{deleteAll}': `<span><img src=${deleteAllIcon} /></span><span class="key-icon-text"><ruby>全部消<rp>(<rt>ぜんぶけ<rp>)</ruby>す</span>`,
      [SPACE_KEY]:
        '<span>スペース(1<ruby>文字<rp>(<rt>もじ<rp>)</ruby>あける)</span>',
      '{close}': `<span><img src=${closeIcon} /></span><span class="key-icon-text">とじる</span>`,
    },
    buttonTheme: [
      {
        class: 'dynamic-key-color',
        buttons: '{default}',
      },
      {
        class: 'control-keys',
        buttons: `{default} {alphanumeric} ${SPACE_KEY} {close}`,
      },
      {
        class: 'backspace-keys',
        buttons: `${DELETE_ONE_KEY} {deleteAll}`,
      },
      {
        class: 'key-icon',
        buttons: `{default} {alphanumeric} ${DELETE_ONE_KEY} {deleteAll} {close}`,
      },
    ],
  })
}

// ソフトウェアキーボードの設定
const commonKeyboardOptions = {
  onKeyPress: (button: any) => onKeyPress(button),
  onChange: (input: string) => onChange(input),
  theme: 'simple-keyboard hg-theme-default hg-layout-default',
  physicalKeyboardHighlight: true,
  syncInstanceInputs: true,
  mergeDisplay: true,
  debug: false,
}

// keyboardInputStoreのinput変更時、ソフトウェアキーボードのinputに反映
watch(
  () => keyboardInputStore.getInput,
  (val: string) => {
    softwareKeyboard.setInput(val)
  }
)

// ソフトウェアキーボード表示時処理
watch(
  () => keyboardStore.getShow,
  (newShow) => {
    if (newShow) {
      // ソフトウェアキーボード上のカーソル位置を実際のカーソル位置と同期
      const inputId = keyboardInputStore.getInputId
      if (inputId) {
        const inputElement = document.querySelector(
          `#${inputId}`
        ) as HTMLInputElement
        if (inputElement) {
          softwareKeyboard.setCaretPosition(
            inputElement.selectionStart,
            inputElement.selectionEnd
          )
        }
      }
      // デフォルトで「ひらがな」のキーボードを表示
      softwareKeyboard.setOptions({ layoutName: 'default' })
    }
  }
)

onMounted(() => {
  initializeKeyboard()
})
</script>

<style lang="scss">
@import 'SoftwareKeyboard';
</style>
