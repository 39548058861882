<template>
  <div v-if="open" class="themeModal">
    <SlotModal
      :keyboard="false"
      :icon="require('@/assets/image/navi/navi_theme.svg')"
      @close="close"
    >
      <template v-slot:title>
        <p>
          テーマを<ruby><rb>変更</rb><rp>(</rp><rt>へんこう</rt><rp>)</rp></ruby
          >する
        </p>
      </template>
      <ul class="themeSelect__lists">
        <li
          v-for="theme in themes"
          class="themeSelect__list"
          :key="`theme-row-${theme.id}`"
        >
          <input
            :id="`theme-${theme.id}`"
            v-model="selectedTheme"
            type="radio"
            name="theme"
            :value="theme.id"
            class="themeSelect__input"
          />
          <label :for="`theme-${theme.id}`" class="themeSelect__img">
            <span class="themeSelect__img_inner">
              <AppImage
                :src="
                  require(`@/assets/image/background_theme/thumbnail/${theme.src}`)
                "
                alt=""
              />
            </span>
          </label>
        </li>
      </ul>
      <template v-slot:actionArea>
        <div @click="onClickSave">
          <AnimationPushToShadow
            tag="button"
            backgroundColor="#fdd400"
            shadowColor="#ff7600"
            shadowX="8px"
            shadowY="8px"
            rounded="pill"
            class="themeSelect__button"
            ><span class="themeSelect__button_inner"
              >えらぶ</span
            ></AnimationPushToShadow
          >
        </div>
      </template>
    </SlotModal>
  </div>
</template>

<script lang="ts" setup>
/**
 * ThemeSelect.
 *
 * テーマ選択モーダル.
 */

import { defineEmits, ref, withDefaults, defineProps } from 'vue'

import { usePortalStore } from '@/store'

import AnimationPushToShadow from '@/components/AnimationPushToShadow/AnimationPushToShadow.vue'
import AppImage from '@/components/AppImage/AppImage.vue'
import SlotModal from '@/components/SlotModal/SlotModal.vue'
import ApiHelper from '@/helpers/ApiHelper'
import * as ApiIF from '@/models/ApiIF'
import StoreHelper from '@/helpers/StoreHelper'
import { useHandleError } from '@/composables/useHandleError'

const { handleErrors } = useHandleError()

const emit = defineEmits(['close', 'update:theme'])

const themes = Object.freeze([
  { id: 1, src: '1.jpg' },
  { id: 31, src: '31.jpg' },
  { id: 32, src: '32.jpg' },
  { id: 36, src: '36.jpg' },
  { id: 33, src: '33.jpg' },
  { id: 34, src: '34.jpg' },
  { id: 35, src: '35.jpg' },
  { id: 29, src: '29.jpg' },
  { id: 37, src: '37.jpg' },
  { id: 27, src: '27.jpg' },
  { id: 4, src: '4.jpg' },
  { id: 5, src: '5.jpg' },
  { id: 26, src: '26.jpg' },
  { id: 30, src: '30.jpg' },
  { id: 3, src: '3.jpg' },
  { id: 2, src: '2.jpg' },
  { id: 28, src: '28.jpg' },
  { id: 42, src: '42.jpg' },
  { id: 43, src: '43.jpg' },
  { id: 44, src: '44.jpg' },
  { id: 45, src: '45.jpg' },
  { id: 46, src: '46.jpg' },
  { id: 47, src: '47.jpg' },
  { id: 48, src: '48.jpg' },
  { id: 38, src: '38.jpg' },
  { id: 39, src: '39.jpg' },
  { id: 40, src: '40.jpg' },
  { id: 41, src: '41.jpg' },
  { id: 22, src: '22.jpg' },
  { id: 23, src: '23.jpg' },
  { id: 24, src: '24.jpg' },
  { id: 25, src: '25.jpg' },
  { id: 6, src: '6.jpg' },
  { id: 7, src: '7.jpg' },
  { id: 8, src: '8.jpg' },
  { id: 9, src: '9.jpg' },
  { id: 10, src: '10.jpg' },
  { id: 11, src: '11.jpg' },
  { id: 12, src: '12.jpg' },
  { id: 13, src: '13.jpg' },
  { id: 14, src: '14.jpg' },
  { id: 15, src: '15.jpg' },
  { id: 16, src: '16.jpg' },
  { id: 17, src: '17.jpg' },
  { id: 18, src: '18.jpg' },
  { id: 19, src: '19.jpg' },
  { id: 20, src: '20.jpg' },
  { id: 21, src: '21.jpg' },
])

const selectedTheme = ref(themes[0].id)

// Store
const portal = usePortalStore()
selectedTheme.value = portal.backgroundTheme!

type Props = {
  open?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  open: false,
})

const close = () => {
  emit('update:theme')
}

const onClickSave = () => {
  close()
  selectTheme(selectedTheme.value)
}

// テーマを保存する
const selectTheme = (selectTheme: number) => {
  const req: ApiIF.UpdateUserRequest = {
    userId: StoreHelper.getUser().userId,
    themeSettingId: selectTheme,
  }
  const backupTheme = portal.getBackgroundTheme!
  portal.$patch({
    backgroundTheme: selectTheme,
  })

  ApiHelper.updateUser(req).catch((err) => {
    portal.$patch({
      backgroundTheme: backupTheme,
    })

    handleErrors(err)
  })
}
</script>

<style lang="scss" scoped>
@import 'ThemeSelect';
</style>
