import { EnvConfigType, AmplifyConfigType } from './ConfigType'

// tst環境の設定をこちらに追加してください。
// EnvConfigTypeの変更も必要です。

const amplifyConfig: AmplifyConfigType = {
  aws_project_region: 'ap-northeast-1',
  aws_cloud_logic_custom: [
    {
      name: 'PortalAPI',
      endpoint:
        'https://sdwx33j0wg.execute-api.ap-northeast-1.amazonaws.com/tst',
      region: 'ap-northeast-1',
    },
    {
      name: 'BaseAPI',
      endpoint:
        'https://r8bd0hhek6.execute-api.ap-northeast-1.amazonaws.com/tst',
      region: 'ap-northeast-1',
    },
  ],
}

const config: EnvConfigType = {
  env: 'tst',
  amplifyConfig: amplifyConfig,
  logLevel: 'DEBUG',
  analyticsTrackingId: 'G-E7EY8NDP9Z', // Dev用
  viewerEndpoint: '',
  siteDomain: isLocal() ? 'localhost' : 'tst.manabi.poplar.co.jp',
  adminUrl: isLocal()
    ? 'http://localhost:8081'
    : 'https://admin.tst.manabi.poplar.co.jp',
  yomokkaUrl: isLocal()
    ? 'http://localhost:8082'
    : 'https://yomokka.tst.manabi.poplar.co.jp',
  sagasokkaUrl: isLocal()
    ? 'http://localhost:8083'
    : 'https://sagasokka.tst.manabi.poplar.co.jp',
  mottosokkaUrl: isLocal()
    ? 'http://localhost:8080'
    : 'https://tst.manabi.poplar.co.jp',
}

// ローカル実行でtst環境を起動する場合
function isLocal() {
  const appUrl: string = process.env.VUE_APP_SITE_URL ?? ''
  return appUrl.includes('localhost')
}

export default config
