import { EnvConfigType, AmplifyConfigType } from './ConfigType'

// sub環境の設定をこちらに追加してください。
// EnvConfigTypeの変更も必要です。

const amplifyConfig: AmplifyConfigType = {
  aws_project_region: 'ap-northeast-1',
  aws_cloud_logic_custom: [
    {
      name: 'PortalAPI',
      endpoint:
        'https://a9rg7o7pti.execute-api.ap-northeast-1.amazonaws.com/sub',
      region: 'ap-northeast-1',
    },
    {
      name: 'BaseAPI',
      endpoint:
        'https://yr3noeltm2.execute-api.ap-northeast-1.amazonaws.com/sub',
      region: 'ap-northeast-1',
    },
  ],
}

const config: EnvConfigType = {
  env: 'sub',
  amplifyConfig: amplifyConfig,
  logLevel: 'DEBUG',
  analyticsTrackingId: 'G-SF3ELBEQJC', // local用
  viewerEndpoint: '',
  siteDomain: 'sub.manabi.poplar.co.jp',
  adminUrl: 'https://admin.sub.manabi.poplar.co.jp',
  yomokkaUrl: 'https://yomokka.sub.manabi.poplar.co.jp',
  sagasokkaUrl: 'https://sagasokka.sub.manabi.poplar.co.jp',
  mottosokkaUrl: 'https://sub.manabi.poplar.co.jp',
}

export default config
