import { API } from 'aws-amplify'
import ApiEnum, { ApiInfo } from '@/enums/ApiEnum'
import ApiException from '@/exceptions/ApiException'
import * as ApiIF from '@/models/ApiIF'
import config from '@/config/config'
import StoreHelper from './StoreHelper'

export default class ApiHelper {
  private static encodeProperty(obj: Record<string, any>, keys: string[]) {
    Object.keys(obj).map((k) => {
      if (keys.includes(k)) {
        // Base64でエンコード
        obj[k] = btoa(obj[k]) // オブジェクトは参照渡しなのでbody内の値も更新される
      }
      //オブジェクトの中身がまたオブジェクトの場合
      if (obj[k] && typeof obj[k] === 'object') {
        ApiHelper.encodeProperty(obj[k], keys)
      }
    })
  }

  private static decodeProperty(obj: Record<string, any>, keys: string[]) {
    Object.keys(obj).map((k) => {
      if (keys.includes(k)) {
        // Base64でエンコードされている文字列をデコード
        obj[k] = atob(obj[k])
      }
      //オブジェクトの中身がまたオブジェクトの場合
      if (obj[k] && typeof obj[k] === 'object') {
        ApiHelper.decodeProperty(obj[k], keys)
      }
    })
  }

  public static async post(apiInfo: ApiInfo, body: object | null = null) {
    try {
      StoreHelper.updateLastUseTime()
      const headers = {
        'X-Session-Id': StoreHelper.sessionId() ?? undefined,
        'App-Service-Id': '2',
      }
      //エンコードとデコード対処プロパティ
      const targetProperties = config.encodeTargetProperties
      if (body) {
        ApiHelper.encodeProperty(body, targetProperties)
      }

      const res = await API.post(apiInfo.endpointType, apiInfo.path, {
        response: true,
        body,
        headers,
      })
      if (res.status === 200) {
        StoreHelper.maintenance = res.headers['x-maintenance'] ? true : false
        ApiHelper.decodeProperty(res, targetProperties)
        return res
      } else {
        throw this.createApiException(res)
      }
    } catch (err) {
      throw this.createApiException(err.response, err)
    }
  }

  private static createApiException(res: any, err?: unknown) {
    if (res?.data?.error?.code) {
      return new ApiException(res.data.error.code, res.data.error.details)
    } else {
      logger.error(err ?? res)
      return new ApiException('SERVER.INTERNAL_ERROR')
    }
  }

  public static async isAllowIp(req: ApiIF.IsAllowIpRequest) {
    const res = await this.post(ApiEnum.auth.isAllowIp, req)
    return res.data as ApiIF.IsAllowIpResponse
  }

  public static async login(req: ApiIF.LoginRequest) {
    const res = await this.post(ApiEnum.auth.login, req)
    return res.data as ApiIF.LoginResponse
  }

  public static async ipLogin(req: ApiIF.IpLoginRequest) {
    const res = await this.post(ApiEnum.auth.ipLogin, req)
    return res.data as ApiIF.IpLoginResponse
  }

  public static async logout(req: ApiIF.LogoutRequest) {
    const res = await this.post(ApiEnum.auth.logout, req)
    return res.data as ApiIF.LogoutResponse
  }

  public static async getUser(req: ApiIF.GetUserRequest) {
    const res = await this.post(ApiEnum.user.getUser, req)
    return res.data as ApiIF.GetUserResponse
  }

  public static async updateUser(req: ApiIF.UpdateUserRequest) {
    const res = await this.post(ApiEnum.user.updateUser, req)
    return res.data as ApiIF.UpdateUserResponse
  }

  public static async checkExecGacha(req: ApiIF.CheckExecGachaRequest) {
    const res = await this.post(ApiEnum.gacha.checkExecGacha, req)
    return res.data as ApiIF.CheckExecGachaResponse
  }

  public static async getExecGacha(req: ApiIF.GetExecGachaRequest) {
    const res = await this.post(ApiEnum.gacha.getExecGacha, req)
    return res.data as ApiIF.GetExecGachaResponse
  }

  public static async getReadingStatus(req: ApiIF.ReadingStatusRequest) {
    const res = await this.post(ApiEnum.book.readingStatus, req)
    return res.data as ApiIF.ReadingStatusResponse
  }

  public static async bookSearchDetail(req: ApiIF.BookSearchDetailRequest) {
    const res = await this.post(ApiEnum.book.bookSearchDetail, req)
    return res.data as ApiIF.BookSearchDetailResponse
  }

  public static async bookSearchFreeword(req: ApiIF.BookSearchFreewordRequest) {
    const res = await this.post(ApiEnum.book.bookSearchFreeword, req)
    return res.data as ApiIF.BookSearchFreewordResponse
  }

  public static async bookSearchRelated(req: ApiIF.BookSearchRelatedRequest) {
    const res = await this.post(ApiEnum.book.bookSearchRelated, req)
    return res.data as ApiIF.BookSearchRelatedResponse
  }

  public static async getBook(req: ApiIF.GetBookRequest) {
    const res = await this.post(ApiEnum.book.getBook, req)
    return res.data as ApiIF.GetBookResponse
  }

  public static async getBookReviewList(req: ApiIF.GetBookReviewListRequest) {
    const res = await this.post(ApiEnum.book.getBookReviewList, req)
    return res.data as ApiIF.GetBookReviewListResponse
  }

  public static async getBookReview(req: ApiIF.GetBookReviewRequest) {
    const res = await this.post(ApiEnum.book.getBookReview, req)
    return res.data as ApiIF.GetBookReviewResponse
  }

  public static async registerBookReview(req: ApiIF.RegisterBookReviewRequest) {
    const res = await this.post(ApiEnum.book.registerBookReview, req)
    return res.data as ApiIF.RegisterBookReviewResponse
  }

  public static async deleteBookReview(req: ApiIF.DeleteBookReviewRequest) {
    const res = await this.post(ApiEnum.book.deleteBookReview, req)
    return res.data as ApiIF.DeleteBookReviewResponse
  }

  public static async getBookshelfBook(req: ApiIF.GetBookshelfBookRequest) {
    const res = await this.post(ApiEnum.bookshelf.getBookshelfBook, req)
    return res.data as ApiIF.GetBookshelfBookResponse
  }

  public static async registerBookshelfBook(
    req: ApiIF.RegisterBookshelfBookRequest
  ) {
    const res = await this.post(ApiEnum.bookshelf.registerBookshelfBook, req)
    return res.data as ApiIF.RegisterBookshelfBookResponse
  }

  public static async unregisterBookshelfBook(
    req: ApiIF.UnregisterBookshelfBookRequest
  ) {
    const res = await this.post(ApiEnum.bookshelf.unregisterBookshelfBook, req)
    return res.data as ApiIF.UnregisterBookshelfBookResponse
  }

  public static async updateBookshelfBook(
    req: ApiIF.UpdateBookshelfBookRequest
  ) {
    const res = await this.post(ApiEnum.bookshelf.updateBookshelfBook, req)
    return res.data as ApiIF.UpdateBookshelfBookResponse
  }

  public static async searchBookshelfBook(
    req: ApiIF.SearchBookshelfBookRequest
  ) {
    const res = await this.post(ApiEnum.bookshelf.searchBookshelfBook, req)
    return res.data as ApiIF.SearchBookshelfBookResponse
  }

  public static async getFeature(req: ApiIF.GetFeatureRequest) {
    const res = await this.post(ApiEnum.feature.getFeature, req)
    return res.data as ApiIF.GetFeatureResponse
  }

  public static async getFeatureDetail(req: ApiIF.GetFeatureDetailRequest) {
    const res = await this.post(ApiEnum.feature.getFeatureDetail, req)
    return res.data as ApiIF.GetFeatureDetailResponse
  }

  public static async getMyRankingList(req: ApiIF.GetMyRankingListRequest) {
    const res = await this.post(ApiEnum.ranking.getMyRankingList, req)
    return res.data as ApiIF.GetMyRankingListResponse
  }

  public static async deleteRanking(req: ApiIF.DeleteRankingRequest) {
    const res = await this.post(ApiEnum.ranking.deleteRanking, req)
    return res.data as ApiIF.DeleteRankingResponse
  }

  public static async getMyItemList(req: ApiIF.GetMyItemListRequest) {
    const res = await this.post(ApiEnum.item.getMyItemList, req)
    return res.data as ApiIF.GetMyItemListResponse
  }

  public static async updateBookshelfItem(
    req: ApiIF.UpdateBookshelfItemRequest
  ) {
    const res = await this.post(ApiEnum.item.updateBookshelfItem, req)
    return res.data as ApiIF.UpdateBookshelfItemResponse
  }

  public static async getReadingCompleteHistory(
    req: ApiIF.GetReadingHistoryRequest
  ) {
    const res = await this.post(
      ApiEnum.readingCompleteHistory.getReadingHistory,
      req
    )
    return res.data as ApiIF.GetReadingHistoryResponse
  }

  public static async getExchangeableItemList(
    req: ApiIF.GetExchangeableItemListRequest
  ) {
    const res = await this.post(ApiEnum.item.getExchangeableItemList, req)
    return res.data as ApiIF.GetExchangeableItemListResponse
  }

  public static async exchangeItem(req: ApiIF.ExchangeItemRequest) {
    const res = await this.post(ApiEnum.item.exchangeItem, req)
    return res.data as ApiIF.ExchangeItemResponse
  }

  public static async registerRanking(req: ApiIF.RegisterRankingRequest) {
    const res = await this.post(ApiEnum.ranking.registerRanking, req)
    return res.data as ApiIF.RegisterRankingResponse
  }

  public static async getRankingList(req: ApiIF.GetRankingListRequest) {
    const res = await this.post(ApiEnum.ranking.getRankingList, req)
    return res.data as ApiIF.GetRankingListResponse
  }

  public static async checkReadingComplete(
    req: ApiIF.CheckReadingCompleteRequest
  ) {
    const res = await this.post(
      ApiEnum.readingCompleteHistory.checkReadingComplete,
      req
    )
    return res.data as ApiIF.CheckReadingCompleteResponse
  }

  public static async getPoint(req: ApiIF.GetPointRequest) {
    const res = await this.post(ApiEnum.point.getPoint, req)
    return res.data as ApiIF.GetPointResponse
  }

  public static async getYmNotification(req: ApiIF.GetYmNotificationRequest) {
    const res = await this.post(ApiEnum.ops.getYmNotification, req)
    return res.data as ApiIF.GetYmNotificationResponse
  }

  public static async setYmNotificationView(
    req: ApiIF.SetYmNotificationViewRequest
  ) {
    const res = await this.post(ApiEnum.ops.setYmNotificationView, req)
    return res.data as ApiIF.SetYmNotificationViewResponse
  }

  public static async getFirstLayer(req: ApiIF.GetFirstLayerRequest) {
    const res = await this.post(ApiEnum.book.getFirstLayer, req)
    return res.data as ApiIF.GetFirstLayerResponse
  }

  public static async getSecondaryLayer(req: ApiIF.GetSecondaryLayerRequest) {
    const res = await this.post(ApiEnum.book.getSecondaryLayer, req)
    return res.data as ApiIF.GetSecondaryLayerResponse
  }

  public static async getPublisherList(req: ApiIF.GetPublisherListRequest) {
    const res = await this.post(ApiEnum.book.getPublisherList, req)
    return res.data as ApiIF.GetPublisherListResponse
  }

  public static async getPickupList(req: ApiIF.GetPickupListRequest) {
    const res = await this.post(ApiEnum.pickup.getPickup, req)
    return res.data as ApiIF.GetPickupListResponse
  }

  public static async getNationalRanking(req: ApiIF.GetNationalRankingRequest) {
    const res = await this.post(ApiEnum.summary.getNationalRanking, req)
    return res.data as ApiIF.GetNationalRankingResponse
  }
}
