/**
 * sliceByNumber.
 * 配列を指定のnumberごとに区切る.
 *
 * @param {Array} array
 * @param {number} number
 * @returns Array
 */
export const sliceByNumber = <T>(array: T[], number: number) => {
  const length = Math.ceil(array.length / number)
  return new Array(length).fill('').map(function (_, i) {
    return array.slice(i * number, (i + 1) * number)
  })
}

/**
 * extractYear.
 * 日付から年を抽出する.
 *
 * @param {string} date
 * @returns string
 */
export const extractYear = (date: string) => {
  return date.split('/')[0]
}

/**
 * randomNumber.
 * 指定した範囲のランダムな数値を返す.
 *
 * @param {number} min
 * @param {number} max
 * @returns number
 */
export const randomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * randomNumbersNotDuplicated.
 * 指定した数値配列の中からランダムに重複しない値を返す.
 *
 * @param {number[]} array
 * @param {number} length
 * @returns number[]
 */
export const randomNumbersNotDuplicated = (array: number[], length: number) => {
  const result: number[] = []
  while (result.length < length) {
    const random = randomNumber(0, array.length - 1)
    if (!result.includes(array[random])) {
      result.push(array[random])
    }
  }
  return result
}

/**
 * generateNumbers.
 *
 * 指定した範囲の数値を配列で返す.
 * @param {number} min
 * @param {number} max
 * @returns number[]
 */
export const generateNumbers = (min: number, max: number) => {
  const result: number[] = []
  for (let i = min; i <= max; i++) {
    result.push(i)
  }
  return result
}

/**
 * numberWithCommas.
 * 3桁ごとにカンマを挿入する.
 *
 * @param {number} number
 * @returns string
 */
export const numberWithCommas = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * shuffleArray.
 * 配列をシャッフルする.
 *
 * @param {array} array
 * @returns array
 */
export const shuffleArray = <T>(array: T[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const r = Math.floor(Math.random() * (i + 1))
    const tmp = array[i]
    array[i] = array[r]
    array[r] = tmp
  }
  return array
}

/**
 * cloneDeep.
 */
export function cloneDeep<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj))
}

/**
 * sleep.
 * 指定した時間だけ処理を止める.
 * @param {number} ms
 * @returns Promise
 */
export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms))

/**
 * 現在日時取得
 * @returns String
 * @desc フォーマット： YYYY/m/d HH:ii:ss
 */
export const getNowDate = () => {
  const now = new Date()
  const Y = now.getFullYear()
  const m = now.getMonth() + 1
  const d = now.getDate()
  const H = now.getHours().toString().padStart(2, '0')
  const i = now.getMinutes().toString().padStart(2, '0')
  const s = now.getSeconds().toString().padStart(2, '0')

  return `${Y}/${m}/${d} ${H}:${i}:${s}`
}

// オブジェクトの差分を取得
export const getDiff = <T extends object>(obj1: T, obj2: T) => {
  const result: { [key in keyof T]?: T[keyof T] } = {}
  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) {
      result[key] = obj2[key]
    }
  }
  for (const key in obj2) {
    if (obj1[key] !== obj2[key] && !(key in result)) {
      result[key] = obj2[key]
    }
  }
  return result
}
