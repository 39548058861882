<template>
  <v-select
    v-model="select"
    :items="items"
    :item-value="itemValue"
    :item-title="itemTitle"
    returnObject
    hideDetails
    hideSelected
    singleLine
    outlined
    label="Standard"
    dense
    class="select"
  />
</template>

<script lang="ts" setup>
/**
 * AppSelect.
 *
 * セレクトボックス.
 */

import { nextTick, defineProps, withDefaults, defineEmits, computed } from 'vue'

import { VSelect } from 'vuetify/components'

interface Props {
  modelValue: string | number | null
  itemValue?: string
  itemTitle?: string
  items: any[] // filterのためunknownではなくanyで回避
}

const props = withDefaults(defineProps<Props>(), {
  itemValue: 'key',
  itemTitle: 'value',
})

const emit = defineEmits(['update:modelValue'])

const select = computed({
  get: () => {
    return (
      props.items.find((item) => item[props.itemValue] === props.modelValue)?.[
        props.itemTitle
      ] ?? ''
    )
  },
  set: (object: typeof props.items[0]) => {
    emit('update:modelValue', object[props.itemValue])
  },
})

const concatenatedPropsOptions = computed(() => {
  return props.items.reduce((acc, item) => {
    return acc + item[props.itemTitle]
  }, '')
})

// Vuetify^3.0.1で選択した要素にアクティブと判定できる属性値がつかないため独自実装
const observe = (async () => {
  await nextTick()

  // 選択した要素にアクティブと判定できる属性値をつける
  // 選択した要素が見えるようにスクロール
  const onOpenOptions = (mutationsList: MutationRecord[]) => {
    for (const mutation of mutationsList) {
      if (mutation.type !== 'childList') return
      if (!(mutation.target instanceof HTMLElement)) return

      const optionsSelector = '.v-list-item .v-list-item-title'
      const options = mutation.target.querySelectorAll(optionsSelector)
      const concatenatedOptions = [...options].reduce((acc, option) => {
        return acc + option.innerHTML
      }, '')

      if (concatenatedOptions !== concatenatedPropsOptions.value) return

      // アクティブ化するoptionを取得
      const targetOption = [...options].find((option) => {
        return option.innerHTML === select.value
      })
      // アクティブな要素をすべて解除
      ;[...options].forEach((option) => {
        if (!(option instanceof HTMLElement)) return
        if (!option.parentElement?.parentElement) return
        option.parentElement.parentElement.dataset.appSelectOption = ''
      })

      if (!targetOption) return
      if (!targetOption.parentElement?.parentElement) return
      if (!(targetOption?.parentElement.parentElement instanceof HTMLElement))
        return

      // アクティブ化
      targetOption.parentElement.parentElement.dataset.appSelectOption =
        'selected'

      // scrollIntoViewの対象がwindowではない時に
      // windowまでスクロールされてしまうことがあるため、元の位置まで戻す
      const scrollY = window.scrollY || document.body.scrollTop
      targetOption.scrollIntoView({ block: 'center' })
      window.scrollTo(0, scrollY)
      document.body.scrollTo(0, scrollY)
    }
  }

  const selectOptionsWrapper = document.querySelector('.v-overlay-container')
  if (!selectOptionsWrapper) return

  const obs = new MutationObserver(onOpenOptions)
  obs.observe(selectOptionsWrapper, { childList: true, subtree: true })
})()
</script>

<style lang="scss">
@import 'AppSelect';
</style>
