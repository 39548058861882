/**
 * DB以外で定義しているコード値をまとめるEnum
 */
export class AppCodeInfo {
  constructor(public key: string, public value: number) {}
}

export default Object.freeze({
  // 許可IPチェックAPI
  isFixedIp: {
    unauthorized: new AppCodeInfo('未許可のIPアドレス', 0),
    permission: new AppCodeInfo('許可されたIPアドレス', 1),
  },
  topDisplayFlag: {
    other: new AppCodeInfo('Yomokka!トップ画面以外の場合', 0),
    yomokkaTop: new AppCodeInfo('Yomokka!トップ画面の場合', 1),
  },
  // 全国ランキング 集計日と現在日時の差
  nationalRantkingAggregationDateDiff: {
    aggregationStartDate: new AppCodeInfo('集計開始日', -1),
    aggregationEndDate: new AppCodeInfo('集計終了日', -1),
  },
  // yomokkaフラグ
  yomokkaFlag: {
    studentSite: new AppCodeInfo('児童生徒サイトからの呼出', 1),
  },
  // ローカルストレージ.Tips非表示状況
  ymHideTipsStatus: {
    all: new AppCodeInfo('全非表示', 0),
    onlyStandard: new AppCodeInfo('スタンダードのみ非表示', 1),
    onlySpeed: new AppCodeInfo('リフローのみ非表示', 2),
  },
})
