<template>
  <picture v-if="srcSp">
    <source :srcset="srcSp" :media="`(max-width: ${breakPoints.sp}px)`" />
    <source :srcset="src" :media="`(min-width: ${breakPoints.pc}px)`" />
    <img
      v-fallback-image="noImageSrc"
      :src="src"
      :alt="alt"
      :width="size.width"
      :height="size.height"
      :loading="loading"
    />
  </picture>
  <img
    v-else
    v-fallback-image="noImageSrc"
    :src="src"
    :alt="alt"
    :width="size.width"
    :height="size.height"
    :loading="loading"
  />
</template>

<script lang="ts" setup>
/**
 * AppImage.
 *
 * srcの読み込み, PC/SPでの画像出しわけ, width/heightの自動付与, 遅延ロードなどが可能.
 */

import {
  defineProps,
  withDefaults,
  reactive,
  computed,
  onMounted,
  toRefs,
  watch,
} from 'vue'
import { BREAK_POINTS } from '@/constants/mediaQuery'

const breakPoints = BREAK_POINTS

interface Props {
  lazyLoad?: boolean
  src: string
  srcSp?: string
  alt?: string
  noImageSrc?: string
  width?: number | string
  height?: number | string
}

const props = withDefaults(defineProps<Props>(), {
  lazyLoad: true,
  srcSp: '',
  alt: '',
  noImageSrc: require('@/assets/image/img_book_none.svg'),
})

const size = reactive({
  width: props.width,
  height: props.height,
})

const loading = computed(() => (props.lazyLoad ? 'lazy' : undefined))

/**
 * init.
 *
 * 画像本来のwidth, heightを取得する.
 * Propsでwidthとheightを受け取っている場合は取得しない.
 */
const init = () => {
  if (props.width && props.height) {
    return
  }

  const src = props.src
  const img = new Image()

  img.onload = () => {
    size.width = props.width ?? img.naturalWidth
    size.height = props.height ?? img.naturalHeight
  }

  img.src = src
}

const { src } = toRefs(props)

watch(src, init)

onMounted(() => {
  init()
})
</script>
