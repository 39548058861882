export class ColorThemeInfo {
  constructor(
    public themeCode: number,
    public themeName: string,
    public borderColor: string,
    public innerBorderColor: string,
    public borderBottomColor: string,
    public roofColor: string,
    public roofColorGradient?: string
  ) {}
}

export default Object.freeze({
  colorTheme: {
    default: new ColorThemeInfo(
      0,
      'デフォルト',
      '#DE9D36',
      '#C68418',
      '#AF7210',
      '#C68418'
    ),
    black: new ColorThemeInfo(
      1,
      '黒',
      '#464646',
      '#333333',
      '#1F1F1F',
      '#333333'
    ),
    red: new ColorThemeInfo(
      2,
      '赤',
      '#D93516',
      '#B92B00',
      '#9F2500',
      '#B92B00'
    ),
    pastelPink: new ColorThemeInfo(
      3,
      'ピンク-パステル',
      '#FFAFE3',
      '#FF9ADC',
      '#FF7CD2',
      '#FF91D9'
    ),
    mintPink: new ColorThemeInfo(
      4,
      'ミントピンク',
      '#88FAD5',
      '#4FE0B0',
      '#2ECC98',
      '#FCDDFC'
    ),
    pastelPurple: new ColorThemeInfo(
      5,
      '紫-パステル',
      '#BAA5FF',
      '#9A7EF4',
      '#16C0EA',
      '#9A7EF4'
    ),
    pastelBlue: new ColorThemeInfo(
      6,
      '青-パステル',
      '#A8EEFF',
      '#51D7F8',
      '#886AE8',
      '#51D7F8'
    ),
    blueGreen: new ColorThemeInfo(
      7,
      '黄緑×青',
      '#66FE45',
      '#1A91AD',
      '#0977B2',
      '#D5FD4E'
    ),
    silver: new ColorThemeInfo(
      8,
      'シルバー',
      '#D3D3D3',
      '#B2B2B2',
      '#A5A5A5',
      '#E6E6E6',
      '#5F5F5F'
    ),
    gold: new ColorThemeInfo(
      9,
      'ゴールド',
      '#FFF5AA',
      '#DBBC3C',
      '#C9A535',
      '#FFF5AA',
      '#B18100'
    ),
    platinum: new ColorThemeInfo(
      10,
      'プラチナ',
      '#FFFCE3',
      '#D6C983',
      '#C6B86D',
      '#F8EDA2'
    ),
    stripe: new ColorThemeInfo(
      11,
      'ストライプ',
      '#43A9FF',
      '#007AE2',
      '#005DAD',
      '#0086F8'
    ),
    zigzag: new ColorThemeInfo(
      12,
      'ジグザグ',
      '#FF7F00',
      '#E66403',
      '#D0551D',
      '#FFA700'
    ),
    check: new ColorThemeInfo(
      13,
      'チェック',
      '#74FF74',
      '#4DB54D',
      '#398839',
      '#5ED05E'
    ),
    bigDots: new ColorThemeInfo(
      14,
      'ドット大',
      '#00E246',
      '#0BCE48',
      '#01B73A',
      '#80FF62'
    ),
    smallDots: new ColorThemeInfo(
      15,
      'ドット小',
      '#7D65FF',
      '#4E36D1',
      '#3620AD',
      '#634DDB'
    ),
    heart: new ColorThemeInfo(
      16,
      'ハート',
      '#FD99B7',
      '#F85083',
      '#EB386E',
      '#FF5D8E'
    ),
    cloisonne: new ColorThemeInfo(
      17,
      '七宝',
      '#FDA000',
      '#D58600',
      '#9F6400',
      '#D58600'
    ),
  },
})
