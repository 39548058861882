<template>
  <div class="tooltip">
    <transition name="tooltip">
      <span
        v-if="enabled && isVisible"
        class="tooltip__main"
        :class="[absolute ? 'absolute' : '', location]"
        v-html="text"
      />
    </transition>
    <slot :listeners="listeners" />
  </div>
</template>

<script lang="ts" setup>
/**
 * AppTooltip.
 */

import { defineProps, withDefaults, ref } from 'vue'

interface Props {
  enabled?: boolean
  text: string
  absolute?: boolean
  location?: 'top' | 'bottom'
}

const props = withDefaults(defineProps<Props>(), {
  enabled: true,
  absolute: true,
  location: 'top',
})

const isVisible = ref(false)

const showTooltip = () => {
  isVisible.value = true
}

const hideTooltip = () => {
  isVisible.value = false
}

const listeners = {
  mouseenter: showTooltip,
  mouseleave: hideTooltip,
  focus: showTooltip,
  blur: hideTooltip,
}
</script>

<style lang="scss" scoped>
@import 'AppTooltip';
</style>
