export const STAMPS = [
  {
    id: 1,
    displayName: 'ニコニコ',
  },
  {
    id: 2,
    displayName: 'あはは',
  },
  {
    id: 3,
    displayName: 'ほっこり',
  },
  {
    id: 4,
    displayName: 'にやり',
  },
  {
    id: 5,
    displayName: 'きゅん',
  },
  {
    id: 6,
    displayName: 'すてき',
  },
  {
    id: 7,
    displayName: 'えっ！',
  },
  {
    id: 8,
    displayName: 'いひひ',
  },
  {
    id: 9,
    displayName: 'ぐーすか',
  },
  {
    id: 10,
    displayName: 'むむむ',
  },
  {
    id: 11,
    displayName: 'あせあせ',
  },
  {
    id: 12,
    displayName: 'う〜ん',
  },
  {
    id: 13,
    displayName: 'あ～あ',
  },
  {
    id: 14,
    displayName: 'うぇっ',
  },
  {
    id: 15,
    displayName: 'ぎゃっ',
  },
  {
    id: 16,
    displayName: 'ひやひや',
  },
  {
    id: 17,
    displayName: 'しくしく',
  },
  {
    id: 18,
    displayName: 'うわ～ん',
  },
  {
    id: 19,
    displayName: 'ぎゃふん',
  },
  {
    id: 20,
    displayName: 'きゃー',
  },
  {
    id: 21,
    displayName: 'えっへん',
  },
  {
    id: 22,
    displayName: 'すやぴ',
  },
  {
    id: 23,
    displayName: 'にんまり',
  },
  {
    id: 24,
    displayName: 'パチパチ',
  },
  {
    id: 25,
    displayName: 'あちゃあ',
  },
  {
    id: 26,
    displayName: 'うるうる',
  },
  {
    id: 27,
    displayName: 'そっか！',
  },
  {
    id: 28,
    displayName: 'はてな？',
  },
  {
    id: 29,
    displayName: 'しーっ',
  },
  {
    id: 30,
    displayName: 'びっくり',
  },
]
