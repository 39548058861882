// 通知
import { defineStore } from 'pinia'

import {
  BookSearchDetailBookItem,
  GetFeatureDetailBookItem,
  BookSearchRelatedBookItem,
  GetBookResponse,
  GetExecGachaResponse,
  RegisterBookshelfBookRequest,
  UnregisterBookshelfBookRequest,
} from '@/models/ApiIF'
import ApiHelper from '@/helpers/ApiHelper'
import { useHandleError } from '@/composables/useHandleError'
import { exceptions } from '@/exceptions/ApiException'

const { handleErrors } = useHandleError()

type Book =
  | BookSearchDetailBookItem
  | BookSearchRelatedBookItem
  | GetFeatureDetailBookItem
  | GetBookResponse['book']
  | GetExecGachaResponse

export type SnackbarStore = {
  loading: {
    [key: string]: boolean
  }
  isModal: boolean
  lists: any[]
  errorMessages: string[]
}

const time = 3200

const addBookErrorMessage = [
  {
    code: exceptions.data.alreadyProcessed.code,
    messages: ['すでに本だなにあります'],
  },
  {
    code: exceptions.data.bookMaxError.code,
    messages: ['本がいっぱいなので、本だなにいれることができません</ruby>'],
  },
  {
    code: exceptions.data.bookPositionMaxError.code,
    messages: [
      '本だなが<ruby><rb>大</rb><rt>おお</rt></ruby>きすぎて、本をいれることができません',
      '本だなの<ruby><rb>最後</rb><rt>さいご</rt></ruby>にある本を1さつはずしてください',
    ],
  },
]

const removeBookErrorMessage = [
  {
    code: exceptions.data.alreadyProcessed.code,
    messages: [
      '<ruby><rb>今</rb><rp>(</rp><rt>いま</rt><rp>)</rp></ruby>は本だなにありません',
    ],
  },
]

export const useSnackbarStore = defineStore('snackbar', {
  state: (): SnackbarStore => ({
    loading: {},
    isModal: false,
    lists: [],
    errorMessages: [],
  }),
  getters: {
    isLoading(state) {
      return (contentsId: string) => !!state.loading[contentsId]
    },
  },
  actions: {
    async handleAddBook(contentsId: string, book: Book) {
      if (this.loading[contentsId]) return
      this.setLoadStatus(contentsId, true)
      const { toggleFlag, showPopup } = await this.addMyBooks(contentsId)
      this.setLoadStatus(contentsId, false)
      if (!toggleFlag) return

      if ('bookShelfEntryFlg' in book) {
        book.bookShelfEntryFlg = 1
      }
      if ('bookShelfEntryFlag' in book) {
        book.bookShelfEntryFlag = 1
      }

      if (showPopup) {
        this.addBookPopup()
      }
    },
    async handleRemoveBook(contentsId: string, book: Book) {
      if (this.loading[contentsId]) return
      this.setLoadStatus(contentsId, true)
      const { toggleFlag, showPopup } = await this.removeMyBooks(contentsId)
      this.setLoadStatus(contentsId, false)
      if (!toggleFlag) return

      if ('bookShelfEntryFlg' in book) {
        book.bookShelfEntryFlg = 0
      }
      if ('bookShelfEntryFlag' in book) {
        book.bookShelfEntryFlag = 0
      }

      if (showPopup) {
        this.removeBookPopup()
      }
    },
    addBookPopup(message = '本だなに<br>いれました') {
      const timer = setTimeout(() => {
        this.shiftSnackbar()
      }, time)
      this.setSnackbar(timer, message, 'add')
    },
    removeBookPopup(message = '本だなから<br>はずしました') {
      const timer = setTimeout(() => {
        this.shiftSnackbar()
      }, time)
      this.setSnackbar(timer, message, 'remove')
    },
    setSnackbar(id: any, message: string, type: 'add' | 'remove') {
      this.lists.push({ id, message, type })
    },
    shiftSnackbar() {
      this.lists.shift()
    },
    setSnackbarPosition(modal = false) {
      this.isModal = modal
    },
    setLoadStatus(contentsId: string, status: boolean) {
      this.loading[contentsId] = status
    },

    // ↓ 主にTF様の編集領域
    // ------------------------------

    // 本棚書籍登録
    async addMyBooks(contentsId: string) {
      const req: RegisterBookshelfBookRequest = {
        contentsId: contentsId,
      }
      try {
        await ApiHelper.registerBookshelfBook(req)
      } catch (err) {
        const errorMessage = addBookErrorMessage.find(
          (message) => message.code === err.code
        )?.messages
        if (errorMessage) {
          this.errorMessages = errorMessage
          if (err.code === exceptions.data.alreadyProcessed.code) {
            return { toggleFlag: true, showPopup: false }
          }
          return { toggleFlag: false, showPopup: false }
        } else {
          handleErrors(err)
        }
      }

      return { toggleFlag: true, showPopup: true }
    },

    // 本棚書籍解除
    async removeMyBooks(contentsId: string) {
      const req: UnregisterBookshelfBookRequest = {
        contentsIds: [contentsId],
      }

      try {
        await ApiHelper.unregisterBookshelfBook(req)
      } catch (err) {
        const errorMessage = removeBookErrorMessage.find(
          (message) => message.code === err.code
        )?.messages
        if (errorMessage) {
          this.errorMessages = errorMessage
          if (err.code === exceptions.data.alreadyProcessed.code) {
            return { toggleFlag: true, showPopup: false }
          }
          return { toggleFlag: false, showPopup: false }
        } else {
          handleErrors(err)
        }
      }
      return { toggleFlag: true, showPopup: true }
    },
  },
})

// 実際の追加削除処理
export const runSnackBar = (snackbarStore: any) => {
  snackbarStore.$subscribe(async (mutation: any, state: { lists: any[] }) => {
    const wrap = document.querySelector<HTMLElement>('.l-Snackbar__wrap')
    const els = document.querySelectorAll<HTMLDivElement>(
      '.l-Snackbar__wrap .snackbar'
    )
    const snackbarTpl = (
      id: number,
      message: string,
      type: 'add' | 'remove'
    ) => {
      return `<div class="snackbar snackbar--${type}" data-ids="${id}"><div class="snackbar__text">${message}</div></div>`
    }

    // elementのID配列作成
    const elsArray: number[] = []
    if (els.length) {
      els.forEach((el) => {
        elsArray.push(Number(el.dataset.ids))
      })
    }

    // storeのID配列作成
    const listArray: number[] = []
    state.lists.forEach((item) => {
      listArray.push(item.id)
    })

    // 追加削除
    if (wrap) {
      // 追加
      state.lists.forEach((item) => {
        if (elsArray.length) {
          // 要素がなければ追加
          if (!elsArray.includes(item.id)) {
            wrap.insertAdjacentHTML(
              'beforeend',
              snackbarTpl(item.id, item.message, item.type)
            )
          }
        } else {
          // 要素が空なら追加
          wrap.insertAdjacentHTML(
            'beforeend',
            snackbarTpl(item.id, item.message, item.type)
          )
        }
      })
      // 要素はあるが配列にない場合削除
      if (els.length) {
        els.forEach((el) => {
          if (!listArray.length) {
            el.remove()
          }
          if (!listArray.includes(Number(el.dataset.ids))) {
            el.remove()
          }
        })
      }
    }
  })
}
