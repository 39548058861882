import cookie from 'js-cookie'
import config from '@/config/config'

export default class CookieHelper {
  public static setSession(sessionId: string | null) {
    if (sessionId === null) {
      cookie.remove('sessionId') // 古いセッション情報の削除
      cookie.remove('sessionId', { domain: config.siteDomain })
    } else {
      cookie.set('sessionId', sessionId, { domain: config.siteDomain })
    }
  }
  public static getSession() {
    return cookie.get('sessionId')
  }

  public static setYmLoginFlag(value: boolean) {
    cookie.set('ymLoginFlag', String(value), { domain: config.siteDomain })
  }

  public static getYmLoginFlag() {
    return cookie.get('ymLoginFlag') === 'true' ? true : false
  }

  public static removeSkipReadingHistoryId() {
    cookie.remove('skipReadingHistoryId')
    cookie.remove('skipReadingHistoryId', { domain: config.siteDomain })
  }

  public static getSkipReadingHistoryId() {
    return cookie.get('skipReadingHistoryId')
  }

  public static setViewerUsageTimeSeconds(value: number) {
    cookie.set('viewerUsageTimeSeconds', String(value), {
      domain: config.siteDomain,
    })
  }

  public static setBreakTimeMinutes(value: number) {
    cookie.set('breakTimeMinutes', String(value), { domain: config.siteDomain })
  }
}
