<template>
  <SlotModal
    :icon="require('@/assets/image/icon/icon_book_capsule.svg')"
    :button="indexPlayAnimation !== 1"
    :fit="true"
    :keyboard="false"
    @close="close"
  >
    <template v-slot:title>
      <div class="bookCapsule__head">
        <p class="bookCapsule__head_title">きょうの1さつ</p>
        <div class="bookCapsule__head_buttonArea">
          <button
            v-if="isGachaPlay && scene === 1"
            class="animationLink"
            @click="onSkipAnimation"
          >
            <AppButton tag="span" rounded="pill" color="negative">
              <span class="bookCapsule__skip">
                <span class="bookCapsule__skip_text">スキップする</span>
              </span>
            </AppButton>
          </button>
        </div>
      </div>
    </template>

    <div class="bookCapsule__contents">
      <div class="stage">
        <div v-if="!isGachaPlay && gachaStatus" class="stage__error">
          <div
            v-if="!isGachaPointEnough && gachaStatus.remainingGachaNum > 0"
            class="stage__error_point"
          >
            <div class="stage__point">
              <div class="stage__point_inner">
                <div class="stage__point_consumption">
                  <p class="stage__point_consumption_inner">
                    <ruby><rb>使</rb><rp>(</rp><rt>つか</rt><rp>)</rp></ruby
                    >うポイント<span class="stage__point_consumption_text">
                      <span class="stage__point_num">{{
                        gachaStatus.consumptionPoints
                      }}</span
                      >P
                    </span>
                  </p>
                </div>
                <div class="stage__point_calc">
                  <p class="stage__point_calc_inner">
                    <span class="stage__point_remaining">
                      のこりのポイント<span class="stage__point_num">
                        {{ gachaStatus.remainingPoints }}</span
                      >P
                    </span>
                    <span class="stage__point_calc_arrow">→</span>
                    <span class="stage__point_color">
                      <span class="stage__point_num"
                        >{{
                          gachaStatus.consumptionPoints -
                          gachaStatus.remainingPoints
                        }} </span
                      >P<span class="stage__point_text">たりません</span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <GachaAnimation :start="false" :scene="animeScene" />
          </div>
          <div v-else class="stage__error_limit">
            <div class="stage__point stage__error_limit_inner">
              <div class="stage__point_inner">
                <p>
                  <ruby><rb>回</rb><rp>(</rp><rt>まわ</rt><rp>)</rp></ruby
                  >せるのは1<ruby
                    ><rb>日</rb><rp>(</rp><rt>にち</rt><rp>)</rp></ruby
                  >10<ruby><rb>回</rb><rp>(</rp><rt>かい</rt><rp>)</rp></ruby
                  >です<br />
                  また<ruby
                    ><rb>明日</rb><rp>(</rp><rt>あした</rt><rp>)</rp></ruby
                  ><ruby><rb>回</rb><rp>(</rp><rt>まわ</rt><rp>)</rp></ruby
                  >してください
                </p>
              </div>
            </div>
            <div class="stage__error_img">
              <img
                :src="require('@/assets/image/gacha/img_gacha_error.svg')"
                alt=""
              />
            </div>
          </div>
        </div>

        <div v-else class="stage__scene" :class="`stage__scene--${scene}`">
          <div v-if="scene === 0" class="stage__scene_start">
            <div v-if="gachaStatus" class="stage__point">
              <div
                v-if="gachaStatus.remainingPoints && !isSharedIdOrFixedIp"
                class="stage__point_inner"
              >
                <div class="stage__point_consumption">
                  <p class="stage__point_consumption_inner">
                    <ruby><rb>使</rb><rp>(</rp><rt>つか</rt><rp>)</rp></ruby
                    >うポイント<span class="stage__point_consumption_text">
                      <span class="stage__point_num">{{
                        gachaStatus.consumptionPoints
                      }}</span
                      >P
                    </span>
                  </p>
                </div>
                <div class="stage__point_calc">
                  <p class="stage__point_calc_inner">
                    <span class="stage__point_remaining">
                      のこりのポイント<span class="stage__point_num">
                        {{ gachaStatus.remainingPoints }}</span
                      >P
                    </span>
                    <span class="stage__point_calc_arrow">→</span>
                    <span class="stage__point_color">
                      <span class="stage__point_num"
                        >{{
                          gachaStatus.remainingPoints -
                          gachaStatus.consumptionPoints
                        }} </span
                      >P
                    </span>
                  </p>
                </div>
              </div>
              <div
                class="stage__buttonArea"
                :class="{ 'is-noPointShow': isSharedIdOrFixedIp }"
              >
                <button class="animationLink" @click="onClickPlay">
                  <AppButton tag="span" rounded="pill" shadow="m">
                    <span class="stage__playButton">
                      <span class="stage__playButton_text"
                        ><ruby
                          ><rb>回</rb><rp>(</rp><rt>まわ</rt><rp>)</rp></ruby
                        >す</span
                      >
                    </span>
                  </AppButton>
                </button>
              </div>
            </div>
          </div>

          <GachaAnimation
            v-if="scene !== 2"
            :start="scene === 1"
            :scene="animeScene"
          />

          <div v-if="scene === 2" class="stage__scene_result">
            <div v-if="bookCapsule" class="result">
              <div class="result__inner">
                <div class="result__book">
                  <Book
                    :contentsId="bookCapsule.contentsId"
                    :thumbnailPath="bookCapsule.thumbnailPath"
                    :bookShelfEntryFlg="bookCapsule.bookShelfEntryFlg"
                    :hasAction="false"
                  />
                </div>
                <div class="result__body">
                  <p class="result__book_title">
                    {{ bookCapsule.contentsName }}
                  </p>
                  <div class="result__buttonArea">
                    <router-link
                      :to="{
                        name: 'BookDetail',
                        params: { bookId: bookCapsule.contentsId },
                      }"
                      class="actionButton animationLink"
                    >
                      <AppButton tag="span" rounded="pill" shadow="m">
                        <span class="result__button">
                          <span class="result__button_icon">
                            <img
                              :src="
                                require('@/assets/image/icon/icon_book_info.svg')
                              "
                              alt=""
                            />
                          </span>
                          <span class="result__button_text"
                            >本の<ruby
                              ><rb>情報</rb><rp>(</rp><rt>じょうほう</rt
                              ><rp>)</rp></ruby
                            ></span
                          >
                        </span>
                      </AppButton>
                    </router-link>
                    <AppTooltip
                      v-if="!isSharedIdOrFixedIp"
                      :enabled="!!bookShelfEntryErrorMessage"
                      :text="bookShelfEntryErrorMessage"
                      location="top"
                    >
                      <template v-slot:default="{ listeners }">
                        <button
                          v-if="!bookShelfEntryErrorMessage"
                          class="actionButton animationLink"
                          @click="
                            bookCapsule &&
                              snackbarStore.handleAddBook(
                                bookCapsule.contentsId,
                                bookCapsule
                              )
                          "
                        >
                          <AppButton
                            tag="span"
                            rounded="pill"
                            color="add"
                            shadow="m"
                          >
                            <span class="result__button">
                              <span class="result__button_icon">
                                <AppImage
                                  :src="
                                    require('@/assets/image/icon/icon_book_plus.svg')
                                  "
                                  alt=""
                                  width="20"
                                  height="20"
                                />
                              </span>
                              <span class="result__button_text--add"
                                >本だなにいれる</span
                              >
                            </span>
                          </AppButton>
                        </button>
                        <button
                          v-else
                          v-on="listeners"
                          class="actionButton disabled"
                        >
                          <AppButton
                            tag="span"
                            rounded="pill"
                            color="remove"
                            shadow="none"
                            :disabled="true"
                          >
                            <span class="result__button">
                              <span class="result__button_icon">
                                <AppImage
                                  :src="
                                    require('@/assets/image/icon/icon_book_plus_line.svg')
                                  "
                                  alt=""
                                  width="20"
                                  height="20"
                                />
                              </span>
                              <span class="result__button_text--add"
                                >本だなにいれる</span
                              >
                            </span>
                          </AppButton>
                        </button>
                      </template>
                    </AppTooltip>
                  </div>
                </div>
              </div>
              <div class="result__animation">
                <div class="result__animation_star">
                  <picture>
                    <source
                      :srcset="
                        require('@/assets/image/gacha/img_gacha_result_star.svg')
                      "
                      media="(min-width: 1024px)"
                    />
                    <source
                      :srcset="
                        require('@/assets/image/gacha/img_gacha_result_star_h.svg')
                      "
                    />
                    <img
                      :src="
                        require('@/assets/image/gacha/img_gacha_result_star.svg')
                      "
                      alt=""
                    />
                  </picture>
                </div>
                <div
                  class="result__animation_ball result__animation_ball--left"
                >
                  <picture>
                    <source
                      :srcset="
                        require('@/assets/image/gacha/img_gacha_result_ball_l.svg')
                      "
                      media="(min-width: 1024px)"
                    />
                    <source
                      :srcset="
                        require('@/assets/image/gacha/img_gacha_result_ball_l_h.svg')
                      "
                    />
                    <img
                      :src="
                        require('@/assets/image/gacha/img_gacha_result_ball_l.svg')
                      "
                      alt=""
                    />
                  </picture>
                </div>
                <div
                  class="result__animation_ball result__animation_ball--right"
                >
                  <picture>
                    <source
                      :srcset="
                        require('@/assets/image/gacha/img_gacha_result_ball_r.svg')
                      "
                      media="(min-width: 1024px)"
                    />
                    <source
                      :srcset="
                        require('@/assets/image/gacha/img_gacha_result_ball_r_h.svg')
                      "
                    />
                    <img
                      :src="
                        require('@/assets/image/gacha/img_gacha_result_ball_r.svg')
                      "
                      alt=""
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SlotModal>

  <transition name="dialog">
    <div v-if="errorMessages.length" class="dialogArea">
      <SlotDialog
        type="error"
        :messages="errorMessages"
        @close="onCloseErrorDialog"
      />
    </div>
  </transition>
</template>

<script lang="ts" setup>
/**
 * BookCapsule.
 *
 * 今日の１さつ.
 */

import { inject, defineEmits, ref, computed, Ref } from 'vue'

import { injectionKeys } from '@/constants/injectionKeys'
import { useLoadingStore } from '@/store/loading'
import { useSnackbarStore } from '@/store/snackbar'

import { GetExecGachaResponse, CheckExecGachaResponse } from '@/models/ApiIF'

import { useHandleError } from '@/composables/useHandleError'

import AppButton from '@/components/AppButton/AppButton.vue'
import AppTooltip from '@/components/AppTooltip/AppTooltip.vue'
import AppImage from '@/components/AppImage/AppImage.vue'
import Book from '@/components/Book/Book.vue'
import SlotModal from '@/components/SlotModal/SlotModal.vue'
import SlotDialog from '../SlotDialog/SlotDialog.vue'
import GachaAnimation from '@/components/GachaAnimation/GachaAnimation.vue'
import ApiHelper from '@/helpers/ApiHelper'
import StoreHelper from '@/helpers/StoreHelper'
import { GA_EVENT_VALUES, GA_EVENT_DIMENSIONS } from '@/constants/ga'
import GaHelper from '@/helpers/GaHelper'

const loadingStore = useLoadingStore()
const { handleErrors } = useHandleError()

// ID認証、同時接続ID、IP認証
const isUserId = inject<Ref<boolean>>(injectionKeys.isUserId)!
const isSharedId = inject<Ref<boolean>>(injectionKeys.isSharedId)!
const isFixedIp = inject<Ref<boolean>>(injectionKeys.isFixedIp)!
const isSharedIdOrFixedIp = computed(() => {
  return isSharedId.value || isFixedIp.value
})

// モーダルとじる
const emit = defineEmits(['close'])
const close = () => {
  emit('close')
}

// 本だなにいれる
const snackbarStore = useSnackbarStore()
snackbarStore.setSnackbarPosition(true)
snackbarStore.$subscribe(async (mutation, state) => {
  if (state.errorMessages.length) {
    errorMessages.value = state.errorMessages
  }
})

// 本だなにいれる非活性&ツールチップ
const bookCapsule = ref<GetExecGachaResponse | null>(null)
const bookShelfEntryErrorMessage = computed(() => {
  if (bookCapsule.value?.bookShelfEntryFlg !== 0) {
    return 'すでに本だなにはいっています'
  }
  return ''
})

// エラーダイアログ
const errorMessages = ref<string[]>([])
const onCloseErrorDialog = () => {
  errorMessages.value = []
  snackbarStore.errorMessages = []
}

// ガチャデータ
// 同時接続ID・固定IPの場合はresultが常にtrue
const gachaStatus = ref<CheckExecGachaResponse | null>(null)
// ガチャ可否確認
const isGachaPlay = computed(() => gachaStatus.value?.result)
// ポイント残高確認
const isGachaPointEnough = computed(() => {
  if (
    gachaStatus.value &&
    gachaStatus.value.remainingPoints >= gachaStatus.value.consumptionPoints
  ) {
    // ポイント不足
    return true
  } else {
    return false
  }
})

// ガチャアニメーション
const scene = ref<number>(0)
const animeScene = ref<number>(0)
const animationTime = 8200
// 現在のシーンナンバー
const indexPlayAnimation = computed(() => scene.value)

// ガチャ開始
const playAnimation = () => {
  scene.value = 1

  setTimeout(() => {
    animeScene.value = 1
  }, 4000)

  setTimeout(() => {
    animeScene.value = 2
  }, 4600)

  setTimeout(() => {
    scene.value = 2
  }, animationTime)
}
// 結果までスキップ
const onSkipAnimation = () => {
  scene.value = 2
}
// まわす
const onClickPlay = async () => {
  playAnimation()
  execGacha()
}

// ↓ 主にTF様の編集領域
// ------------------------------

/**
 * ガチャ使用可否チェック
 */
const checkExecGacha = async () => {
  await ApiHelper.checkExecGacha({})
    .then((res) => {
      gachaStatus.value = res
    })
    .catch((err) => {
      handleErrors(err)
    })
}

/**
 * ガチャ結果取得
 */
const execGacha = async () => {
  await ApiHelper.getExecGacha({})
    .then((res) => {
      bookCapsule.value = res
      StoreHelper.updatePoint(res.afterPoints)
      GaHelper.registerGaEvent(GA_EVENT_VALUES.bookCapsule.execGacha, [
        res.contentsId,
      ])
    })
    .catch((err) => {
      handleErrors(err)
    })
}

/**
 * 初期表示用のリクエスト
 */
const initialRequest = async () => {
  // ID認証ユーザーの場合、ガチャ使用可否チェック
  if (isUserId.value) {
    const callCheckExecGacha = checkExecGacha()
    await callCheckExecGacha
  } else {
    gachaStatus.value = {
      result: true,
      consumptionPoints: 0,
      remainingPoints: 0,
      remainingGachaNum: 0,
    }
  }
}

const init = (async () => {
  try {
    // ローディング開始
    loadingStore.setLoading(true, 'modal')

    // 初期表示用のリクエスト
    const callInitialRequest = initialRequest()
    await callInitialRequest
  } catch (err) {
    handleErrors(err)
  } finally {
    // ローディング終了
    loadingStore.setLoading(false, 'modal')
  }
})()
</script>

<style lang="scss" scoped>
@import 'BookCapsule';
</style>
