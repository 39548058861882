import ApiHelper from '@/helpers/ApiHelper'
import { onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import config from '@/config/config'

const viewerEndpoint = config.viewerEndpoint

const pollingInterval = 1000 * 5

/**
 * useReadBook.
 */
// eslint-disable-next-line max-lines-per-function
export const useReadBook = () => {
  const router = useRouter()

  let bookId: string
  let readingToken: number
  let polling: number | null = null

  const read = (id: string) => {
    readingToken = Date.now()
    bookId = id
    const url = `${viewerEndpoint}/reader/speed.html?cid=${bookId}&u0=${readingToken}`
    window.open(url)
  }

  const startPollingCheckReadingComplete = () => {
    if (polling) {
      clearInterval(polling)
      polling = null
    }

    polling = window.setInterval(async () => {
      if (!readingToken) return

      const params = {
        contentsId: bookId,
        viewerCallUnixtime: readingToken,
      }
      const res = await checkReadingComplete(params)
      if (!res.result) return

      if (polling) {
        clearInterval(polling)
        polling = null
      }
      router.push({
        name: 'BookReview',
        params: {
          bookId,
          readingCompleteHistoryId: String(res.readingCompleteHistoryId),
        },
      })
    }, pollingInterval)
  }

  onBeforeUnmount(() => {
    if (polling) {
      clearInterval(polling)
    }
  })

  // ↓ 主にTF様の編集領域
  // ------------------------------

  type CheckReadingCompleteRequest = {
    contentsId: string
    viewerCallUnixtime: number
  }
  const checkReadingComplete = async (params: CheckReadingCompleteRequest) => {
    return ApiHelper.checkReadingComplete(params)
  }

  return {
    read,
    startPollingCheckReadingComplete,
  }
}
