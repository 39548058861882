<template>
  <span :class="className">
    <span class="inner">{{ message }}</span>
  </span>
</template>

<script lang="ts" setup>
/**
 * AppLoading
 *
 * ローディング
 */

import { defineProps, withDefaults, computed } from 'vue'

interface Props {
  type?: 'inner' | 'over'
  message?: string
}

const props = withDefaults(defineProps<Props>(), {
  type: 'inner',
  message: '読みこみ中',
})

const className = computed(() => {
  return ['appLoading', `appLoading--${props.type}`]
})
</script>

<style lang="scss" scoped>
@import 'AppLoading';
</style>
