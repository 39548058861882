<template>
  <component :is="tag" :class="className">
    <AnimationPushToShadow
      :shadowX="shadowSizes[shadow]"
      :shadowY="shadowSizes[shadow]"
      :rounded="rounded"
    >
      <slot />
    </AnimationPushToShadow>
  </component>
</template>

<script lang="ts" setup>
/**
 * AppButton.
 */

import { defineProps, withDefaults, computed } from 'vue'

import AnimationPushToShadow from '@/components/AnimationPushToShadow/AnimationPushToShadow.vue'

interface Props {
  tag?: string
  color?: string
  shadow?: 'none' | 'xs' | 's' | 'm' | 'l'
  rounded?: string
  outline?: boolean
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'div',
  color: 'yellow',
  shadow: 's',
  rounded: 'pill',
  outline: false,
  disabled: false,
})

const shadowSizes = {
  none: '0px',
  xs: '2px',
  s: '4px',
  m: '8px',
  l: '12px',
}

const className = computed(() => {
  return [
    'appButton',
    props.color,
    props.outline ? 'outline' : '',
    props.disabled ? 'disabled' : '',
  ]
})
</script>

<style lang="scss" scoped>
@import 'AppButton';
</style>
