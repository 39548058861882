export const GA_EVENT_VALUES = {
  navigation: {
    top: 1,
    bookshelf: 2,
    theme: 3,
    help: 4,
    keyboard: 5,
  },
  feature: {
    banner: 6,
  },
  top: {
    pickup: 7,
    ranking: 8,
    bookCapsule: 9,
    readingBook: 10,
  },
  bookSearch: {
    search: 11,
    sort: 12,
  },
  bookCapsule: {
    execGacha: 13,
  },
  bookShelf: {
    search: 14,
  },
  help: {
    menuItem: 15,
  },
}

export const GA_EVENT_DIMENSIONS = {
  help: {
    yomokka: '1',
    top: '2',
    search: '3',
    searchDetails: '4',
    bookSearch: '5',
    bookDetail: '6',
    bookRead: '7',
    bookReview: '8',
    feature: '9',
    ranking: '10',
    bookCapsule: '11',
    bookshelf: '12',
    itemsExchange: '13',
    itemsDecorate: '14',
    point: '15',
    myPage: '16',
  },
}
