<template>
  <div class="yomokkaNavi" :class="{ 'is-themeShow': isVisibleThemeSelect }">
    <nav class="yomokkaNavi__inner">
      <div>
        <component :is="isTopPage ? 'h1' : 'div'" class="yomokkaNavi__logo">
          <AppImage
            :src="require('@/assets/image/logo_yomokka.svg')"
            alt="Yomokka!"
            width="60"
            height="13"
          />
        </component>
        <ul class="yomokkaNavi__lists">
          <li
            v-for="item in menu"
            :key="item.name"
            class="navi__item"
            :class="`yomokkaNavi__list_${item.name.toLowerCase()}`"
          >
            <component
              :is="item.path ? 'router-link' : 'button'"
              v-bind="item.path ? { to: item.path } : {}"
              class="yomokkaNavi__link animationLink"
              :class="{ 'is-active': isMenuItemActive(item.name) }"
              @click="item.onClick"
            >
              <AnimationPushToShadow
                rounded="circle"
                class="yomokkaNavi__link_button"
              >
                <span class="yomokkaNavi__link_icon">
                  <AppImage
                    :src="
                      isMenuItemActive(item.name)
                        ? item.icon.srcActive
                        : item.icon.src
                    "
                    alt=""
                    width="40"
                    height="40"
                  />
                </span>
              </AnimationPushToShadow>
              <span class="yomokkaNavi__link_text">{{ item.displayName }}</span>
            </component>
          </li>
        </ul>
      </div>

      <div class="yomokkaNavi__options">
        <div
          v-if="!isSharedId && !isFixedIp && !isWhitelist && points !== null"
          class="yomokkaNavi__point"
        >
          <div class="yomokkaNavi__point_inner">
            <p class="yomokkaNavi__point_text">ポイント</p>
            <p class="yomokkaNavi__point_numberArea">
              <span class="yomokkaNavi__point_number">{{ points }}</span>
              <span class="yomokkaNavi__point_text">P</span>
            </p>
          </div>
        </div>
        <div class="yomokkaNavi__keyboard">
          <button
            class="yomokkaNavi__link animationLink"
            @click="onClickKeyboard"
          >
            <AnimationPushToShadow
              rounded="circle"
              class="yomokkaNavi__link_button"
            >
              <span class="yomokkaNavi__link_icon">
                <img
                  :src="require(`@/assets/image/navi/navi_keyboard.svg`)"
                  alt=""
                  width="40"
                  height="40"
                />
              </span>
            </AnimationPushToShadow>
            <span class="yomokkaNavi__link_text">キーボード</span>
          </button>
        </div>
      </div>
    </nav>

    <transition name="modal">
      <div v-if="isVisibleThemeSelect" class="themeModal__wrap">
        <ThemeSelect :open="showThemeModal" @update:theme="toggleThemeModal" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
/**
 * YomokkaNavi.
 */
import { inject, defineProps, withDefaults, ref, computed, Ref } from 'vue'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'

import { GA_EVENT_VALUES } from '@/constants/ga'
import { injectionKeys } from '@/constants/injectionKeys'
import { useKeyboardStore } from '@/store/keyboard'
import GaHelper from '@/helpers/GaHelper'

import AnimationPushToShadow from '@/components/AnimationPushToShadow/AnimationPushToShadow.vue'
import AppImage from '@/components/AppImage/AppImage.vue'
import ThemeSelect from '@/components/ThemeSelect/ThemeSelect.vue'

const isSharedId = inject<Ref<boolean>>(injectionKeys.isSharedId)!
const isFixedIp = inject<Ref<boolean>>(injectionKeys.isFixedIp)!
const isWhitelist = inject<Ref<boolean>>(injectionKeys.isWhitelist)!

type Props = {
  points?: number
}
const props = withDefaults(defineProps<Props>(), {
  points: 0,
})

const route = useRoute()
const isTopPage = computed(() => route.path === '/')

// テーマ
const isVisibleThemeSelect = ref(false)
const showThemeModal = computed(() => isVisibleThemeSelect.value)
const toggleThemeModal = (value?: boolean) => {
  isVisibleThemeSelect.value =
    typeof value !== 'undefined' ? value : !isVisibleThemeSelect.value
}

// キーボードボタン
const keyboardStore = useKeyboardStore()
const onClickKeyboard = () => {
  toggleKeyboard()
  GaHelper.registerGaEvent(GA_EVENT_VALUES.navigation.keyboard)
}
const toggleKeyboard = () => {
  // toggleThemeModal(false)
  keyboardStore.toggleShow()
}
const closeKeyboard = () => {
  keyboardStore.setShow(false)
}
// 他ページへ遷移する場合はキーボード閉じる
onBeforeRouteUpdate((to) => {
  // ヘルプモーダルの場合は閉じない
  if (to.path === '/help') return
  closeKeyboard()
})

// メニュー
const isMenuItemActive = computed(() => {
  return (name: string) => {
    if (!showThemeModal.value) {
      return name === route.name
    }
    return name === 'Theme'
  }
})
const menu = computed(() => {
  const list = [
    {
      visible: !isWhitelist.value,
      name: 'Top',
      displayName: 'トップ',
      path: '/',
      icon: {
        src: require('@/assets/image/navi/navi_top.svg'),
        srcActive: require('@/assets/image/navi/navi_top_active.svg'),
      },
      onClick: () => {
        toggleThemeModal(false)
        closeKeyboard()
        GaHelper.registerGaEvent(GA_EVENT_VALUES.navigation.top)
      },
    },
    {
      visible: true,
      name: 'Bookshelf',
      displayName: '本だな',
      path: '/bookshelf',
      icon: {
        src: require('@/assets/image/navi/navi_bookshelf.svg'),
        srcActive: require('@/assets/image/navi/navi_bookshelf_active.svg'),
      },
      onClick: () => {
        toggleThemeModal(false)
        closeKeyboard()
        GaHelper.registerGaEvent(GA_EVENT_VALUES.navigation.bookshelf)
      },
    },
    {
      visible: !isSharedId.value && !isFixedIp.value && !isWhitelist.value,
      name: 'Theme',
      displayName: 'テーマ',
      icon: {
        src: require('@/assets/image/navi/navi_theme.svg'),
        srcActive: require('@/assets/image/navi/navi_theme_active.svg'),
      },
      onClick: () => {
        toggleThemeModal()
        // closeKeyboard()
        GaHelper.registerGaEvent(GA_EVENT_VALUES.navigation.theme)
      },
    },
    {
      visible: true,
      name: 'Help',
      displayName: 'ヘルプ',
      path: '/help',
      icon: {
        src: require('@/assets/image/navi/navi_help.svg'),
        srcActive: require('@/assets/image/navi/navi_help_active.svg'),
      },
      onClick: () => {
        toggleThemeModal(false)
        closeKeyboard()
        GaHelper.registerGaEvent(GA_EVENT_VALUES.navigation.help)
      },
    },
  ]

  return list.filter((item) => item.visible)
})
</script>

<style lang="scss" scoped>
@import 'YomokkaNavi';
</style>
