import { SEARCHS } from '@/constants/search'
import GaEventName from '@/enums/GaEventNameEnum'
import DateHelper from '@/helpers/DateHelper'
import { usePortalStore } from '@/store'
import { event } from 'vue-gtag'

export default class GaHelper {
  /**
   * GAにイベントを送信します
   * @param gaEvent イベント識別子
   * @param dimensions カスタムディメンション
   */
  public static registerGaEvent(gaEvent: number, dimensions: string[] = []) {
    const eventName = Object.values(GaEventName.libraryTop).find(
      (code) => code.value === gaEvent
    )?.key

    const eventParam = getCommonParams()
    const paramsByEvent = getParamsByEvent(gaEvent, dimensions)

    Object.assign(eventParam, paramsByEvent)

    event(eventName!, eventParam)
  }
}

/**
 * gaに送信する共通のイベントパラメータを作成して返却
 * @returns 共通のイベントパラメータ
 */
function getCommonParams() {
  const dateHelper = DateHelper.getInstance()
  const portal = usePortalStore()

  return {
    timestamp: dateHelper.formatDateToString(
      dateHelper.newDate(),
      dateHelper.DateFormat.ISO_YYYY_MM_DD_HH_MM_SS
    ),
    m_user_id: portal.getUser?.userId,
    contract_pattern: portal.getUser?.contractPattern,
    user_type: portal.getUser?.userType ? portal.getUser?.userType : undefined,
    municipal_name: portal.getUser?.municipalName
      ? portal.getUser?.municipalName
      : undefined,
    grade: portal.getUser?.calculatedGrade
      ? portal.getUser?.calculatedGrade
      : undefined,
    school_type: portal.getUser?.schoolType
      ? portal.getUser?.schoolType
      : undefined,
    school_info_id: portal.getUser?.schoolInfoId
      ? portal.getUser?.schoolInfoId
      : undefined,
  }
}

/**
 * イベント毎に送信する固有なイベントパラメータを作成して返却
 * @param gaEvent イベント識別子
 * @param dimensions カスタムディメンション
 * @returns イベントパラメータ
 */
function getParamsByEvent(gaEvent: number, dimensions: string[]) {
  switch (gaEvent) {
    case GaEventName.libraryTop.clickBanner.value:
      return getParamsByClickBanner(dimensions)
    case GaEventName.libraryTop.clickBookThumbnail.value:
      return getParamsByClickBookThumbnail(dimensions)
    case GaEventName.libraryTop.searchBook.value:
      return getParamsBySearchBook(dimensions)
    case GaEventName.libraryTop.changeOrder.value:
      return getParamsByChangeOrder(dimensions)
    case GaEventName.libraryTop.gachaResult.value:
      return getParamsByGachaResult(dimensions)
    case GaEventName.libraryTop.clickHelpItem.value:
      return getParamsByClickHelpItem(dimensions)
    case GaEventName.libraryTop.searchBookshelf.value:
      return getParamsBySearchBookshelf(dimensions)
    default:
      return {}
  }
}

/**
 * バナークリック時の固有のイベントパラメータを作成して返却
 * @param dimensions カスタムディメンション
 * @returns イベントパラメータ
 */
function getParamsByClickBanner(dimensions: string[]) {
  return {
    feature_site: dimensions[0],
    feature_name: dimensions[1],
  }
}

/**
 * ピックアップ書影クリック時の固有のイベントパラメータを作成して返却
 * @param dimensions カスタムディメンション
 * @returns イベントパラメータ
 */
function getParamsByClickBookThumbnail(dimensions: string[]) {
  return {
    contents_id: dimensions[0],
  }
}

/**
 * 並び替え変更時の固有のイベントパラメータを作成して返却
 * @param dimensions カスタムディメンション
 * @returns イベントパラメータ
 */
function getParamsByChangeOrder(dimensions: string[]) {
  return {
    order: dimensions[0],
  }
}

/**
 * ガチャ結果表示時の固有のイベントパラメータを作成して返却
 * @param dimensions カスタムディメンション
 * @returns イベントパラメータ
 */
function getParamsByGachaResult(dimensions: string[]) {
  return {
    contents_id: dimensions[0],
  }
}

/**
 * ヘルプ項目クリック時の固有のイベントパラメータを作成して返却
 * @param dimensions カスタムディメンション
 * @returns イベントパラメータ
 */
function getParamsByClickHelpItem(dimensions: string[]) {
  return {
    help_category: dimensions[0],
  }
}

/**
 * 本だな検索時の固有のイベントパラメータを作成して返却
 * @param dimensions カスタムディメンション
 * @returns イベントパラメータ
 */
function getParamsBySearchBookshelf(dimensions: string[]) {
  const params = JSON.parse(dimensions[0]) as Record<string, string>
  return {
    sc_freeword: params['freeWord'] ? params['freeWord'] : undefined,
    sc_reading_status: params['readingStatus']
      ? params['readingStatus']
      : undefined,
    sc_review: params['reviewStatus'] ? params['reviewStatus'] : undefined,
    sc_stamp:
      params['stampIds'] && params['stampIds']?.length !== 0
        ? params['stampIds']
        : undefined,
  }
}

/**
 * 検索実施時の固有のイベントパラメータを作成して返却
 * @param dimensions カスタムディメンション
 * @returns イベントパラメータ
 */
function getParamsBySearchBook(dimensions: string[]) {
  const params = JSON.parse(dimensions[0]) as Record<string, string>
  switch (params['searchType']) {
    case SEARCHS.freeword.type:
      return getParamsByFreewordSearch(params)
    case SEARCHS.detail.type:
      return getParamsByDetailSearch(params)
    case SEARCHS.grade.type:
      return getParamsByGradeSearch(params)
    case SEARCHS.reSearch.type:
      return getParamsByReSearch(params)
    default:
      return {}
  }
}

/**
 * フリーワード検索時の固有のイベントパラメータを作成して返却
 * @param dimensions カスタムディメンション
 * @returns イベントパラメータ
 */
function getParamsByFreewordSearch(params: Record<string, string>) {
  return {
    search_type: params['searchType'],
    sc_freeword: params['freeWord'] ? params['freeWord'] : undefined,
  }
}

/**
 * くわしくさがす時の固有のイベントパラメータを作成して返却
 * @param dimensions カスタムディメンション
 * @returns イベントパラメータ
 */
function getParamsByDetailSearch(params: Record<string, string>) {
  return {
    search_type: params['searchType'],
    sc_title: params['title'] ? params['title'] : undefined,
    sc_author: params['author'] ? params['author'] : undefined,
    sc_publisher: params['publisher'] ? params['publisher'] : undefined,
    sc_release_from: params['dateFrom'] ? params['dateFrom'] : undefined,
    sc_release_to: params['dateTo'] ? params['dateTo'] : undefined,
    sc_grade: params['grade'] ? params['grade'] : 0,
    sc_1st_layer: params['first'] ? params['first'] : undefined,
    sc_2nd_layer: params['second'] ? params['second'] : undefined,
    sc_audio_flag: params['contentsStyle'] ? 1 : 0,
    sc_area_flag: params['area'] ? params['area'] : 0,
    sc_learning_flag: params['learning'] ? params['learning'] : undefined,
  }
}

/**
 * 学年検索時の固有のイベントパラメータを作成して返却
 * @param dimensions カスタムディメンション
 * @returns イベントパラメータ
 */
function getParamsByGradeSearch(params: Record<string, string>) {
  return {
    search_type: params['searchType'],
    sc_grade: params['grade'] ? params['grade'] : 0,
  }
}

/**
 * 再検索時の固有のイベントパラメータを作成して返却
 * @param dimensions カスタムディメンション
 * @returns イベントパラメータ
 */
function getParamsByReSearch(params: Record<string, string>) {
  return {
    search_type: params['searchType'],
    sc_freeword: params['freeWord'] ? params['freeWord'] : undefined,
    sc_grade: params['grade'] ? params['grade'] : undefined,
    sc_1st_layer: params['first'] ? params['first'] : undefined,
    sc_2nd_layer: params['second'] ? params['second'] : undefined,
    sc_learning_flag: params['learning'] ? params['learning'] : undefined,
  }
}
