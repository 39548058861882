<template>
  <div class="l-contents">
    <div class="l_header">
      <Header @click:logout="logout()" />
    </div>
    <div class="l-contents__wrap" :class="{ 'is-error': isErrorPage }">
      <YomokkaNavi
        v-if="!isErrorPage"
        :points="portal.user?.point"
        @update:keyboard="onChangeKeyboard"
      />
      <div class="l-contents__inner" :class="{ 'is-error': isErrorPage }">
        <div class="l-contents__body">
          <router-view />
        </div>
        <ScrollTopButton
          v-if="!isErrorPage"
          v-model:keyboard="isKeyboardShow"
        />
        <Footer v-if="footer" />
        <SoftwareKeyboard
          v-model:keyboard="isKeyboardShow"
          class="l-keyboard"
        ></SoftwareKeyboard>
      </div>
    </div>
  </div>

  <aside class="l-Snackbar">
    <div
      class="l-Snackbar__wrap"
      :class="{ 'is-modal': snackbarPosition }"
    ></div>
  </aside>

  <transition name="dialog">
    <div v-if="isConfirming" class="dialogArea">
      <SlotDialog
        type="confirm"
        title="ログアウトしますか？"
        @agree="agree"
        @cancel="disagree"
      />
    </div>
  </transition>

  <transition name="fade">
    <AppLoading
      v-if="loadingStateGlobal && loadingStateGlobal.loading"
      :type="loadingStateGlobal.type"
    />
  </transition>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { usePortalStore } from '@/store'
import { useKeyboardStore } from '@/store/keyboard'
import { LoadingStore, useLoadingStore, loadingReset } from '@/store/loading'
import { useSnackbarStore, runSnackBar } from '@/store/snackbar'

import Header from '@/components/Header/Header.vue'
import Footer from '@/components/Footer/Footer.vue'
import SlotDialog from '@/components/SlotDialog/SlotDialog.vue'
import SoftwareKeyboard from '@/components/SoftwareKeyboard/SoftwareKeyboard.vue'
import YomokkaNavi from '@/components/YomokkaNavi/YomokkaNavi.vue'
import ScrollTopButton from '@/components/ScrollTopButton/ScrollTopButton.vue'
import AppLoading from '@/components/AppLoading/AppLoading.vue'

import { useConfirm } from '@/composables/useConfirm'

const portal = usePortalStore()
const route = useRoute()
const router = useRouter()

// 404,500,503エラー判定
const isErrorPage = computed(() => {
  if (route.name === 'InternalServerError') return true
  if (route.name === 'ServiceUnavailable') return true
  if (route.name === 'NotFound') return true
  return false
})

// TODO: footer非表示のパターンがあるか
const footer = ref(true)

// キーボード
const keyboardStore = useKeyboardStore()
const isKeyboardShow = computed(() => keyboardStore.getShow)
const onChangeKeyboard = () => {
  keyboardStore.toggleShow()
}

// ローディング
const loadingStore = useLoadingStore()
const loadingStateGlobal = ref<LoadingStore | null>()
loadingStore.$subscribe(async (mutation, state) => {
  loadingStateGlobal.value = state.name ? loadingReset : state
})

// 通知
const snackbarStore = useSnackbarStore()
const snackbarPosition = ref<boolean>()
snackbarStore.$subscribe(async (mutation, state) => {
  snackbarPosition.value = state.isModal
})
// 通知処理呼び出し
runSnackBar(snackbarStore)

// ログアウト
const { isConfirming, confirm, disagree, agree } = useConfirm()
const logout = async () => {
  if (await confirm()) {
    await router.push({ name: 'Logout' })
  }
}
</script>
