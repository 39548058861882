<template>
  <div class="slotDialog">
    <div class="slotDialog__outer">
      <div
        class="slotDialog__wrapper"
        :class="[size === 'wide' ? 'slotDialog__wrapper--wide' : '']"
      >
        <div class="slotDialog__inner">
          <div v-if="isError" class="slotDialog__icon">
            <AppImage
              :src="require('@/assets/image/icon/icon_warning.svg')"
              alt=""
              width="48"
              height="48"
            />
          </div>
          <p v-if="title" class="slotDialog__title" v-html="title" />
          <ul
            v-if="messages && messages.length"
            class="slotDialog__messages"
            :class="{ center: messages.length === 1 }"
          >
            <li
              v-for="(message, i) in messages"
              :key="i"
              v-html="message"
              class="slotDialog__messages_item"
              :class="{ single: messages.length === 1 }"
            />
          </ul>
          <div v-else-if="$slots.default" class="slotDialog__contents">
            <slot />
          </div>
          <div class="slotDialog__buttonArea">
            <slot name="buttonArea">
              <template v-if="isMessage || isError">
                <AnimationPushToShadow
                  tag="button"
                  backgroundColor="#b4c3d8"
                  shadowColor="#5b6a80"
                  shadowX="4px"
                  shadowY="4px"
                  rounded="pill"
                  class="buttonClose"
                  @click="close"
                  ><span class="buttonClose__inner"
                    >とじる</span
                  ></AnimationPushToShadow
                >
              </template>
              <template v-else-if="isConfirm">
                <AnimationPushToShadow
                  tag="button"
                  backgroundColor="#fdd400"
                  shadowColor="#ff7600"
                  shadowX="8px"
                  shadowY="8px"
                  rounded="pill"
                  class="button"
                  @click="agree"
                  ><span
                    class="button__inner"
                    :class="{ 'is-confirm-yes': isConfirm }"
                    >はい</span
                  ></AnimationPushToShadow
                >
                <AnimationPushToShadow
                  tag="button"
                  backgroundColor="#b4c3d8"
                  shadowColor="#5b6a80"
                  shadowX="8px"
                  shadowY="8px"
                  rounded="pill"
                  class="button"
                  @click="cancel"
                  ><span
                    class="button__inner"
                    :class="{ 'is-confirm-no': isConfirm }"
                    >いいえ</span
                  ></AnimationPushToShadow
                >
              </template>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
/**
 * SlotDialog.
 */

import { defineProps, withDefaults, defineEmits, computed } from 'vue'

import AnimationPushToShadow from '@/components/AnimationPushToShadow/AnimationPushToShadow.vue'
import AppImage from '@/components/AppImage/AppImage.vue'

interface Props {
  type: 'message' | 'error' | 'confirm'
  title?: string
  messages?: string[]
  disableAgreeButton?: boolean
  size?: 'normal' | 'wide'
}

const props = withDefaults(defineProps<Props>(), {
  type: 'message',
  disableAgreeButton: false,
})

const emit = defineEmits(['agree', 'cancel', 'close'])

// 表示タイプ
const isMessage = computed(() => props.type === 'message')
const isError = computed(() => props.type === 'error')
const isConfirm = computed(() => props.type === 'confirm')

const close = () => {
  emit('close')
}

const cancel = () => {
  emit('cancel')
}

const agree = () => {
  if (props.disableAgreeButton) return
  emit('agree')
}
</script>

<style lang="scss" scoped>
@import 'SlotDialog';
</style>
