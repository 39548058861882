<template>
  <div class="openBook__img">
    <img :src="require('@/assets/image/gacha/img_gacha_book1.svg')" alt="" />
  </div>
  <div class="openBook__img">
    <img :src="require('@/assets/image/gacha/img_gacha_book2.svg')" alt="" />
  </div>
  <div class="openBook__img">
    <img :src="require('@/assets/image/gacha/img_gacha_book3.svg')" alt="" />
  </div>
  <div class="openBook__img">
    <img :src="require('@/assets/image/gacha/img_gacha_book4.svg')" alt="" />
  </div>
  <div class="openBook__img">
    <img :src="require('@/assets/image/gacha/img_gacha_book5.svg')" alt="" />
  </div>
  <div class="openBook__img">
    <img :src="require('@/assets/image/gacha/img_gacha_book6.svg')" alt="" />
  </div>
  <div class="openBook__img">
    <img :src="require('@/assets/image/gacha/img_gacha_book7.svg')" alt="" />
  </div>
</template>
