import { createApp } from 'vue'
import VueGtagPlugin, { VueGtag } from 'vue-gtag'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { createPinia } from 'pinia'
import Amplify from 'aws-amplify'
import config from './config/config'
import router from './router'

import fallbackImage from '@/directives/fallbackImage'

import DbEnum from './enums/DbEnum'
import ValidationHelper from './helpers/ValidationHelper'
import EnumHelper from './helpers/EnumHelper'
import DateHelper from './helpers/DateHelper'
import StoreHelper from './helpers/StoreHelper'
import GaEventNameEnum from '@/enums/GaEventNameEnum'

import '@/assets/fonts/fonts.scss'
import { injectionKeys } from './constants/injectionKeys'

loadFonts()

Amplify.configure(config.amplifyConfig)

const app = createApp(App)
export type VueApp = typeof app

// プラグイン
app.use(router)
app.use(vuetify)
app.use(createPinia())
app.use(
  VueGtagPlugin,
  {
    config: { id: config.analyticsTrackingId },
  },
  router
)

app.provide(injectionKeys.$config, config)
app.provide(injectionKeys.$store, StoreHelper)
app.provide(injectionKeys.$validation, ValidationHelper.getInstance())
app.provide(injectionKeys.$enum, EnumHelper.getInstance())
app.provide(injectionKeys.$date, DateHelper.getInstance())
app.provide(injectionKeys.$dbEnum, DbEnum)
app.provide(injectionKeys.$gaEvNameEnum, GaEventNameEnum)

// カスタムディレクティブ
fallbackImage(app)

app.mount('#app')
